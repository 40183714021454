import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "home-time-to--component"
}
const _hoisted_2 = { class: "home-time-to--container" }
const _hoisted_3 = { class: "home-time-to--title" }

import { ref, onMounted, onUnmounted, computed } from 'vue'
  import { useIntervalFn } from '@vueuse/core'
  import { rand } from '@vueuse/shared'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'time-to',
  setup(__props: any) {

  const timeTo = [
    'do new things in new ways.',
    'use AI where it counts.',
    're-define your industry.'
  ]
  const word = ref('_')
  const interval = ref(2000)
  const index = ref(0)

  const { pause, resume, isActive } = useIntervalFn(() => {
    word.value = timeTo[ index.value ];
    index.value = index.value + 1 < timeTo.length ? index.value + 1 : 0;
  }, interval)

  const props = __props

  onMounted( ()  => {
    word.value = timeTo[0]
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "home-time-to--subtitle" }, [
        _createElementVNode("h3", null, "It's time to")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(word.value), 1)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "home-time-to--btn" }, [
        _createElementVNode("a", {
          class: "btn-md",
          href: "https://hakkoda.io/services"
        }, "Explore Data Services")
      ], -1))
    ])
  ], 512))
}
}

})