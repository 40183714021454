import { inject, ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'

export const useCounterStore = defineStore('counter', () => {
  const count = ref(0)
  const name = ref('Eduardo')
  const doubleCount =  computed (() => count.value * 2)
  function increment() {
    count.value++
  }

  return { count, name, doubleCount, increment }
})

export interface FormInfo {
  formId: string
  submitedEmail: string
}

export const useFormStore = defineStore('formStore', () => {
  const formList = ref<FormInfo[] | []>([])  
  const submitedForm = ref<FormInfo | null>(null)

  const getSubmitedForm = computed(() => submitedForm )

  function setSubmited( submitedValue: FormInfo ) {
    submitedForm.value = submitedValue
    formList.value = [...formList.value, submitedValue]
    console.log( getSubmitedForm )
  }

  return { getSubmitedForm, setSubmited }
})
