import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "image-slider--component"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "image-slider--container slider slick-slider" }
const _hoisted_5 = { class: "image-slider--item" }
const _hoisted_6 = { class: "image-slider--logo" }
const _hoisted_7 = ["onClick", "src"]

import { ref, onMounted, onUnmounted, watch, useTemplateRef, nextTick } from 'vue'
  import Story from '../../models/story.model'
  import Flicking from "@egjs/vue3-flicking";
  import { AutoPlay } from "@egjs/flicking-plugins";

  interface Props {
    stories?: Story[]
    active?: Story
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'image-slider',
  props: {
    stories: {},
    active: {}
  },
  emits: ["onClick"],
  setup(__props: any, { emit: __emit }) {

  const props = __props
    const emit = __emit

  const active = ref<Story>(null)
  const images = ref(null)
  const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
  const renderComponent = ref(true);

  watch( () => props.stories,  (data: Story[], prev) => {
    if( data.length ) {
      images.value = data.map( e => e?.logo )
      forceRender()
    }
  })

  watch( () => props.active,  (data , prev) => {
    if (data != active.value) {
      active.value = data
    }
  })

  onMounted( ()  => {

    if( props.stories.length ) {
      images.value = props.stories.map( (e) => e?.logo || null)
      forceRender()
    }
  })

  const forceRender = async () => {
    renderComponent.value = false;
    await nextTick();
    renderComponent.value = true;
    await nextTick();

    window.jQuery('.image-slider--container').slick({
      mobileFirst: true,
      dots: false,
      infinite: true,
      variableWidth: true,
      autoplay: true,
      arrows: false,
      swipeToSlide: false,
      touchMove: true,
      useTransform: false,
      autoplaySpeed: 1,
      speed: 5000
    });
  }

  function onClick(src) {
    emit('onClick', src)
  }


return (_ctx: any,_cache: any) => {
  return (renderComponent.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        false
          ? (_openBlock(), _createBlock(_unref(Flicking), {
              key: 0,
              plugins: plugins,
              options: { 
        changeOnHold: true, moveType: 'strict', circular: true, horizontal: true, bound: true, align: 'prev', autoInit: true, panelsPerView: -1 }
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (src, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    onClick: ($event: any) => (onClick(src))
                  }, [
                    _createElementVNode("img", {
                      class: _normalizeClass({
              'active': !active.value?.logo || active.value?.logo == src
          }),
                      src: `${ src }`
                    }, null, 10, _hoisted_3)
                  ], 8, _hoisted_2))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (src, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "slider-item"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    class: _normalizeClass({
                    'active': !active.value?.logo || active.value?.logo == src
                }),
                    onClick: ($event: any) => (onClick(src)),
                    src: `${ src }`
                  }, null, 10, _hoisted_7)
                ])
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})