import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wistia-video-player--component" }
const _hoisted_2 = { class: "wistia_responsive_wrapper" }

import { ref, onMounted, onUnmounted, watch } from 'vue'
  import { loadScript } from  '../../tools/index'

  interface Props {
    videoId: String,
    autoPlay?: Boolean,
    replay?: Boolean,
    mute?: Boolean,
    hideControls?: Boolean,
    hideVideo?: Boolean,
    isBgVideo?: Boolean,
    playVideo?: Boolean,
    pauseVideo?: Boolean,
    fullScreen?: true,
    preload?: Boolean
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'wistia-video-player',
  props: {
    videoId: {},
    autoPlay: {},
    replay: {},
    mute: {},
    hideControls: {},
    hideVideo: {},
    isBgVideo: {},
    playVideo: {},
    pauseVideo: {},
    fullScreen: { type: Boolean },
    preload: {}
  },
  emits: ["secondchange", "end", "paused", "closed", "cancelfullscreen"],
  setup(__props: any, { emit: __emit }) {

  const props = __props
  const emit = __emit

  watch( () => props.mute,  (val, prev) => {
      window._wq.push({
        id: props.videoId, 
        onReady: function(video) {
          if( !val ) {
            video.volume(1)
            video.unmute() 
            window.jQuery(`.wistia_async_${props.videoId} video`).removeAttr('muted') // remove unmute from video safari
          } else {
            video.mute()
          }
        }
      });
  })

  watch( () => props.playVideo,  (val, prev) => {
      window._wq.push({
        id: props.videoId, 
        onReady: function(video) {

          if( val ) {
            video.play()
          }
        }
      });
  })

  watch( () => props.pauseVideo,  (val, prev) => {
      window._wq.push({
        id: props.videoId, 
        onReady: function(video) {

          if( val ) {
            video.pause()
            emit('paused', video)
          }
        }
      });
  })

  const loadWistiaVideo = (conf: Props) => {
    loadScript('https://fast.wistia.com/assets/external/E-v1.js', () => {
      window._wq.push({
        id: conf.videoId, 
        options: {
          plugin: {
            "captions-v1": {
              onByDefault: false
            }
          }
        },
        onReady: function(video) {
          // console.log("wistia-video-player", video, conf)

          video.volume(1)

          if(!!conf.preload){
            
          }

          if(!!conf.autoPlay){
            video.play()
          }

          if(!!conf.mute){
            video.mute()
          }

          if(!!conf.fullScreen){
            video.requestFullscreen()
          }

          video.bind("timechange", function() {
            //console.log("timechange", video.time())
            emit('secondchange', video.time())
          });

          video.bind("cancelfullscreen", function() {
            emit('cancelfullscreen', video.time())
          });

          video.bind("end", function(t) {
            if(!!conf.replay){
              video.time(0)
              video.play()
            }
            emit('end', t)
          })
        }
      });
    })
  }

  onMounted( ()  => {
    // console.log('wistia', props)

    loadWistiaVideo(props)
  })

  onUnmounted( () => {

  })

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(`wistia_embed wistia_async_${ _ctx.videoId } 
            seo=${ true } 
            videoFoam=${ false }
            autoPlay=${ _ctx.autoPlay || false } 
            controlsVisibleOnLoad=${ _ctx.hideControls ? false : true } 
            fullscreen=${ _ctx.fullScreen || false } 
            fullscreenButton=${ _ctx.hideControls ? false : true } 
            playbackRateControl=${ _ctx.hideControls ? false : true } 
            playbar=${ _ctx.hideControls ? false : true } 
            playButton=${ _ctx.hideControls ? false : true } 
            settingsControl=${ _ctx.hideControls ? false : true } 
            volumeControl=${ _ctx.hideControls ? false : true } 
            captionsVisible=${ false } 
            muted=${ false } 
            wmode=${ 'transparent' } 
            ${ _ctx.hideControls ? 'hide-ctrls' : '' } 
            ${ _ctx.isBgVideo ? 'wistia-bg-video' : 'wistia-inline-video' } 
            ${ _ctx.preload ? 'preload=auto' : '' }`)
      }, null, 2)
    ])
  ], 512)), [
    [_vShow, !_ctx.hideVideo]
  ])
}
}

})