import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "home-solutions--component-container"
}
const _hoisted_2 = { class: "home-solutions--component" }
const _hoisted_3 = { class: "home-solutions--container" }
const _hoisted_4 = ["onMouseover"]
const _hoisted_5 = { class: "home-solutions--card-title-container" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "home-solutions--card-title" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "home-solutions--overlay" }
const _hoisted_10 = { class: "home-solutions--overlay-container" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "home-solutions--card-description" }
const _hoisted_14 = { class: "home-solutions--cloud-container" }
const _hoisted_15 = { class: "home-solutions--cloud" }
const _hoisted_16 = { class: "home-solutions--card-paragraph" }

import { ref, onMounted, onUnmounted, computed } from 'vue'
    import { useMediaQuery } from '@vueuse/core'
  import { Swiper, SwiperSlide } from 'swiper/vue';

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'solutions',
  setup(__props: any) {

  const props = __props
  const conf = ref([
    {
      solutionName: 'Sigma BI Rationalization & Migration',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/mamba.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/12/sigma-solutions-4.png',
      sector: 'Business Intelligence',
      wistiaId: '',
      description: 'Streamline report rationalization and supercharge your migration to Sigma’s modern BI analytics environment with a suite of AI-driven business intelligence solutions.',
      targer: 'https://hakkoda.io/3-phase-bi-rationalization-migration-with-hakkoda-ai/',
      class: 'gradient-4'
    },
    {
      solutionName: 'Mamba',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/mamba.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/mamba-solution-screen.png',
      sector: 'Business Analytics',
      wistiaId: 'kw2tn9fj5p',
      description: 'Accelerate speed to insight with realtime access to all your trades and assets in a single version of truth',
      targer: 'https://hakkoda.io/resources/mamba/',
      class: 'gradient-2'
    },
    {
      solutionName: 'Intelligent Agreements',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/intelligent_agreements.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Intelligent-agreements-screen.png',
      sector: 'Document Management',
      wistiaId: 'xib90it0te',
      description: 'Transform documents and other resources into conversational interfaces to streamline knowledge sharing and unlock organizational efficiencies.',
      targer: 'https://hakkoda.io/resources/intelligent-agreements/',
      class: 'gradient-3'
    },
    {
      solutionName: 'Snowflake Native MDM',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/snowflake.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Snowflake-Native-MDM-screen.png',
      sector: 'Data Governance',
      wistiaId: 'fsj2z9ao4k',
      description: 'Modularly enable data profiling, rule management, and exploration management within a Snowflake environment by capitalizing on Snowpark’s machine learning capabilities.',
      targer: 'https://hakkoda.io/resources/snowflake-native-mdm/',
      class: 'gradient-1'
    },
    {
      solutionName: 'Automated Query Performance Tester',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/automated_query_performance_tester.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Automated-Query-Performance-tester-screen.png',
      sector: 'Accelerated Migration',
      wistiaId: 'r3snjbo5dl',
      description: 'Run and execute large query batches automatically within Snowflake while generating insights on compile times, cluster size, and execution time.',
      targer: 'https://hakkoda.io/resources/automated-query-performance-tester/',
      class: 'gradient-4'
    },
    {
      solutionName: 'Price Transparency Dashboards',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/Hakkoda-Icons-1.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Price-Transparency-Dashboards-screen.png',
      sector: 'Healthcare',
      wistiaId: 'r3snjbo5dl',
      description: 'Quickly and easily navigate price transparency files in Snowflake with a collection of descriptive analytics dashboards including inventory and supply information, services, and charges.',
      targer: 'https://hakkoda.io/resources/automated-query-performance-tester/',
      class: 'gradient-4'
    }
  ])
  const isLargeScreen = useMediaQuery('(min-width: 992px)')
  const active = ref(null)
  const swiperRef = ref(null)

  function onSwiper(swiperInstance) {
    swiperRef.value = swiperInstance
  }

  function next () {
    swiperRef.value.slideNext()
  }

  function prev () {
    swiperRef.value.slidePrev() 
  }

  function mouseOver(val) {
    active.value = val
  }

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { class: "home-solutions--container" }, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"home-solutions--heading\" data-v-5882e8b6><div class=\"home-solutions--subtitle\" data-v-5882e8b6><p data-v-5882e8b6>Solutions &amp; Accelerators</p></div><div class=\"home-solutions--title\" data-v-5882e8b6><h2 data-v-5882e8b6>We Solve Problems Once. Forever.</h2></div><div class=\"home-solutions--description\" data-v-5882e8b6><p class=\"home-solutions--description-paragraph\" data-v-5882e8b6>When we see our clients regularly come up against similar challenges, we follow that pattern to the source—building Solutions and Accelerators that let you migrate, modernize, and monetize your data faster than ever before.</p></div></div>", 1)),
        _createElementVNode("div", { class: "home-solutions--arrows" }, [
          _createElementVNode("img", {
            onClick: prev,
            class: "home-solutions--arrows-image svg-white",
            src: "https://hakkoda.io/wp-content/uploads/2024/11/solution-left-arrow.svg",
            alt: ""
          }),
          _createElementVNode("img", {
            onClick: next,
            class: "home-solutions--arrows-image svg-white",
            src: "https://hakkoda.io/wp-content/uploads/2024/11/solution-rigth-arrow.svg",
            alt: ""
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Swiper), {
          slidesPerView: 'auto',
          spaceBetween: 20,
          loop: true,
          onSwiper: onSwiper,
          class: "solutionsSwiper"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.value, (solution, index) => {
              return (_openBlock(), _createBlock(_unref(SwiperSlide), null, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "home-solutions--item",
                    onMouseover: ($event: any) => (mouseOver(solution))
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("img", {
                        class: "home-solutions--logo",
                        src: `${ solution.logo }`,
                        alt: `${ solution.solutionName }`
                      }, null, 8, _hoisted_6),
                      _createElementVNode("h3", _hoisted_7, _toDisplayString(solution.solutionName), 1)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(`home-solutions--poster-container ${ solution.class }`)
                    }, [
                      _createElementVNode("img", {
                        class: _normalizeClass(["home-solutions--poster", {
                    'active': solution?.solutionName == active.value?.solutionName
                  }]),
                        src: `${ solution.posterImage }`,
                        alt: `${ solution.solutionName }`
                      }, null, 10, _hoisted_8)
                    ], 2),
                    _withDirectives(_createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("a", {
                          class: "underline-decoration",
                          href: `${ solution.targer }`
                        }, "LEARN MORE", 8, _hoisted_11),
                        _createElementVNode("a", {
                          href: `${ solution.targer }`
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("img", {
                            class: "home-solutions--overlay-arrow",
                            src: "https://hakkoda.io/wp-content/uploads/2024/11/arrow-up-right.svg",
                            alt: "Watch the full story"
                          }, null, -1)
                        ]), 8, _hoisted_12)
                      ])
                    ], 512), [
                      [_vShow, solution?.solutionName == active.value?.solutionName || !_unref(isLargeScreen)]
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(solution.sector), 1)
                      ]),
                      _createElementVNode("p", _hoisted_16, _toDisplayString(solution.description), 1)
                    ])
                  ], 40, _hoisted_4)
                ]),
                _: 2
              }, 1024))
            }), 256))
          ]),
          _: 1
        })
      ])
    ])
  ], 512))
}
}

})