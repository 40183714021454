<template>
  <div ref="component" class="home-time-to--component">

    <div class="home-time-to--container">
      <div class="home-time-to--subtitle"><h3>It's time to</h3></div>
      <div class="home-time-to--title"><h2>{{ word }}</h2></div>
      <div class="home-time-to--btn">
          <a 
            class="btn-md" 
            href="https://hakkoda.io/services"
          >Explore Data Services</a>
        </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue'
  import { useIntervalFn } from '@vueuse/core'
  import { rand } from '@vueuse/shared'

  const timeTo = [
    'do new things in new ways.',
    'use AI where it counts.',
    're-define your industry.'
  ]
  const word = ref('_')
  const interval = ref(2000)
  const index = ref(0)

  const { pause, resume, isActive } = useIntervalFn(() => {
    word.value = timeTo[ index.value ];
    index.value = index.value + 1 < timeTo.length ? index.value + 1 : 0;
  }, interval)

  interface Props {
  }

  const props = defineProps<Props>()

  onMounted( ()  => {
    word.value = timeTo[0]
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .home-time-to {

    &--component {
      display: flex;
      width: 100%;
      background: #17191C;
      flex-direction: column;

      @media (min-width: 991px) {
      }
    }

    &--container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      min-height: 470px;
      justify-content: center;

      @media (min-width: 991px) {
        padding: 20px 40px;
      }
    }

    &--title {
      min-height: 140px;

      h2 {
        color: #00D5FF;
        font-size: 48px;
        line-height: 45px; 
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.48px;


        @media (min-width: 991px) {
          font-size: 70px;
          line-height: 95px; 
          letter-spacing: -0.7px;
        }
      }
    }

    &--subtitle {
      h3 {
        color: #FFF;
        font-size: 28px;
        line-height: 36px;
        font-style: normal;
        font-weight: 500;
        

        @media (min-width: 991px) {
          font-size: 48px;
          line-height: 36px;
          font-weight: 400;
        }
      }
    }

    &--btn {
      padding-top: 25px;

      a {
        background: #00D5FF;
        color: #000;

        &:hover {
          background: #555357;
          color: #fff;
        }
      }
    }
  }

</style>