import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "customer-stories--teaser-wrapper" }
const _hoisted_2 = { class: "customer-stories--content" }
const _hoisted_3 = {
  key: 0,
  class: "customer-stories--heading"
}
const _hoisted_4 = {
  key: 1,
  class: "customer-stories--dropdown"
}
const _hoisted_5 = {
  key: 0,
  class: "icon-caret-down-container"
}
const _hoisted_6 = {
  key: 1,
  class: "icon-caret-up-container"
}
const _hoisted_7 = { class: "customer-stories--dropdown-item-container" }
const _hoisted_8 = { class: "customer-stories--dropdown-item" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 2,
  class: "customer-stories--dropdown-logo"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "customer-stories--quote" }
const _hoisted_13 = { class: "customer-stories--author" }
const _hoisted_14 = { class: "customer-stories--author-position" }
const _hoisted_15 = {
  key: 0,
  class: "customer-stories--btn"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 1,
  class: "customer-stories--logos"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["id"]
const _hoisted_21 = ["data-bs-target"]
const _hoisted_22 = ["src", "data-bs-target"]
const _hoisted_23 = ["id"]
const _hoisted_24 = ["id"]
const _hoisted_25 = {
  key: 0,
  class: "modal-dialog modal-dialog-centered modal-xl"
}
const _hoisted_26 = { class: "modal-content" }
const _hoisted_27 = { class: "modal-body customer-stories--video" }

import { ref, useTemplateRef, onMounted, onUnmounted, watch, nextTick } from 'vue'
  import { useWindowSize, usePointerSwipe, useMediaQuery, onClickOutside, useTimeout } from '@vueuse/core'
  import Story from '../../models/story.model'
  import WistiaVideoPlayer from '../wistia-video-player/wistia-video-player.vue'
  import type { UseSwipeDirection } from '@vueuse/core'

  interface Props {
    storiesEncoded?: String, // Encoded Json
    storiesConf?: Story[],
    active: Story,
    reverse?: Boolean,
    showDropdown?: Boolean,
    sector?: String,
    isEmbed?: Boolean,
    isDarkTheme?: Boolean,
    isFullHeight?: Boolean,
    useSwipe?: Boolean
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'customer-stories',
  props: {
    storiesEncoded: {},
    storiesConf: {},
    active: {},
    reverse: {},
    showDropdown: {},
    sector: {},
    isEmbed: {},
    isDarkTheme: {},
    isFullHeight: {},
    useSwipe: {}
  },
  emits: ["active"],
  setup(__props: any, { emit: __emit }) {

  const props = __props
    const emit = __emit

  const isLargeScreen = useMediaQuery('(min-width: 768px)')
  const width = ref(useWindowSize().width)
  const height = ref(useWindowSize().height)
  const activeStory = ref(props.active || null)
  const stories = ref(JSON.parse(props.storiesEncoded || null) || props.storiesConf || null)
  const reverseLayout = ref(props.reverse || false)
  const showInDropdown = ref(props.showDropdown || false)
  const checkedDropdown = ref(false)
  const isDarkTheme = ref(props.isDarkTheme || false)
  const isFullHeight = ref(props.isFullHeight || false)
  const renderComponent = ref(true);
  const tabContentRef = ref<HTMLElement | null>(null)
  const { distanceX, distanceY, isSwiping } = usePointerSwipe(tabContentRef, {
    onSwipeEnd(e: PointerEvent, direction: UseSwipeDirection) {
      console.log('usePointerSwipe', props.useSwipe , e, direction )

      if ( !props.useSwipe ) { 
        return 
      } 

      const index = stories.value.findIndex( (story: Story) => story.quote == activeStory.value.quote );

      console.log('usePointerSwipe', index, e, direction )

      if( direction == 'left' && index < stories.value.length - 1 ) {
        const next = index + 1
        activeStory.value = stories.value[ next ]
      }

      if( direction == 'left' && index == stories.value.length - 1 ) {
        const next = 0 // first
        activeStory.value = stories.value[ next ]
      }

      if( direction == 'right' && index == 0 ) {
        const prev = stories.value.length - 1 // last
        activeStory.value = stories.value[ prev ]
      }

      if( direction == 'right' && index > 0 && index < stories.value.length ) {
        const prev = index - 1
        activeStory.value = stories.value[ prev ]
      }

      if( direction == 'up') {
        console.log();

        window.scroll({
          top: window.top.scrollY + Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      if( direction == 'down') {
        window.scroll({
          top: window.top.scrollY - Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }
    }
  })
  const dropdownRef = ref(null)
  const { ready, start } = useTimeout(300, { controls: true })

  onClickOutside(
    dropdownRef,
    (event) => {
      if(checkedDropdown.value && ready.value) {
        start()
        checkedDropdown.value = false
      }
    }
  )

  watch( () => props.storiesConf,  (data: Story[], prev) => {
    if(
      data.every( (e: Story) => e.author != activeStory.value?.author)
    ) {
      //forceRender()
      stories.value = data
      activeStory.value = data[0]
    }
  })

  watch( () => props.active,  (data: Story, prev) => {
    if (data?.author != activeStory.value?.author) {
      //console.log("watch active",data)
      checkedDropdown.value = false
      activeStory.value = data
    }
  })

  watch( () => activeStory.value,  (data: Story, prev) => {
    forceRender();
  })

  onMounted( ()  => {
    if( !activeStory.value && stories.value?.length) {
      activeStory.value = stories.value[0]
    }
  })

  function onClick(story: Story) {
    // close dropdown
    checkedDropdown.value = false
    activeStory.value = story
    emit('active', story)
  }

  function onDropdownClick() {
    if(ready.value) {
      start()
      checkedDropdown.value = checkedDropdown.value ? false : true
    }
  }

  const forceRender = async () => {
    if(renderComponent?.value) {
      renderComponent.value = false;
      await nextTick();
      renderComponent.value = true;
    }
  }

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (renderComponent.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["customer-stories--component", {
      'dark-theme': isDarkTheme.value,
      'embed': _ctx.isEmbed
    }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stories.value, (story, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["customer-stories--container slide-top", {
        'active': activeStory.value?.author == story?.author,
        'reverse': reverseLayout.value,
        'full-height-component--content': isFullHeight.value,
        'full-h': isFullHeight.value && width.value > 1000 && height.value > 1000,
        'embed': _ctx.isEmbed
      }])
          }, [
            _createElementVNode("div", {
              ref_for: true,
              ref_key: "tabContentRef",
              ref: tabContentRef,
              class: "customer-stories--teaser"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (!showInDropdown.value)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Customer Stories"))
                    : _createCommentVNode("", true),
                  (showInDropdown.value && stories.value.length > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("label", {
                          class: _normalizeClass(["for-dropdown", {
                  'open': checkedDropdown.value,
                }]),
                          for: "dropdown",
                          onClick: onDropdownClick
                        }, [
                          _cache[2] || (_cache[2] = _createElementVNode("span", null, "more Success stories", -1)),
                          (!checkedDropdown.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[0] || (_cache[0] = [
                                _createElementVNode("i", { class: "icon-caret-down" }, null, -1)
                              ])))
                            : _createCommentVNode("", true),
                          (checkedDropdown.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                                _createElementVNode("i", { class: "icon-caret-up" }, null, -1)
                              ])))
                            : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                          ref_for: true,
                          ref_key: "dropdownRef",
                          ref: dropdownRef,
                          class: _normalizeClass(["section-dropdown", {
                  'open': checkedDropdown.value,
                }])
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stories.value, (dStory) => {
                            return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                (activeStory.value.author != dStory.author)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      href: "#",
                                      onClick: ($event: any) => (onClick(dStory))
                                    }, [
                                      _createTextVNode(_toDisplayString(dStory.clientName) + " ", 1),
                                      _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-solid fa-arrow-right" }, null, -1))
                                    ], 8, _hoisted_9))
                                  : _createCommentVNode("", true)
                              ])
                            ]))
                          }), 256))
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
                  (showInDropdown.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("img", {
                          src: `${ story.logo }`,
                          class: _normalizeClass(`${ story.customClass }`)
                        }, null, 10, _hoisted_11)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(story.quote), 1),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(story.author), 1),
                  _createElementVNode("p", _hoisted_14, _toDisplayString(story.authorPosition), 1)
                ]),
                (story?.target)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("a", {
                        class: "btn-md",
                        href: `${ story.target }`
                      }, "Watch the full story", 8, _hoisted_16)
                    ]))
                  : _createCommentVNode("", true),
                (!showInDropdown.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stories.value, (cta, ctaIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "customer-stories--logos-wrap",
                          onClick: ($event: any) => (onClick(cta))
                        }, [
                          (cta?.logo)
                            ? (_openBlock(), _createElementBlock("img", {
                                src: `${ cta.logo }`,
                                key: `${ ctaIndex }`,
                                class: _normalizeClass({
                  'active': activeStory.value?.author == cta?.author
                })
                              }, null, 10, _hoisted_19))
                            : _createCommentVNode("", true),
                          (!cta?.logo)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(["customer-stories--dot", {
                  'active': activeStory.value?.author == cta?.author
                }])
                              }, null, 2))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_18))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 512),
            (story?.poster || story?.video)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "customer-stories--media",
                  id: `${ story?.video || index }`
                }, [
                  (story?.poster)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["customer-stories--poster", {
            'play-story': story?.video ? true : false
          }]),
                        "data-bs-target": `#customer-stories--modal-${ story?.video || index }`,
                        "data-bs-toggle": "modal"
                      }, [
                        _createElementVNode("img", {
                          src: `${ story.poster }`,
                          "data-bs-target": `#customer-stories--modal-${ story?.video || index }`,
                          "data-bs-toggle": "modal",
                          class: _normalizeClass(["customer-stories--image", {
               'mobile': !_unref(isLargeScreen),
               'desktop': _unref(isLargeScreen)
            }])
                        }, null, 10, _hoisted_22)
                      ], 10, _hoisted_21))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_20))
              : _createCommentVNode("", true)
          ], 2))
        }), 256)),
        (activeStory.value?.poster || activeStory.value?.video)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "customer-stories--modal-container",
              id: `${ activeStory.value?.video }`
            }, [
              (activeStory.value?.video)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "customer-stories--modal modal fade",
                    id: `customer-stories--modal-${ activeStory.value.video }`,
                    "aria-hidden": "true",
                    tabindex: "-1"
                  }, [
                    (renderComponent.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "modal-header" }, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn-close",
                                "data-bs-dismiss": "modal",
                                "aria-label": "Close"
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_27, [
                              (renderComponent.value)
                                ? (_openBlock(), _createBlock(WistiaVideoPlayer, {
                                    key: 0,
                                    "video-id": activeStory.value.video,
                                    "auto-play": true
                                  }, null, 8, ["video-id"]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_24))
                : _createCommentVNode("", true)
            ], 8, _hoisted_23))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})