import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home-stories--sectors" }
const _hoisted_2 = ["onClick"]

import { ref, onMounted, onUnmounted, computed } from 'vue'
  import Story from '../../../models/story.model'
  import CustomerStories from '../../../components/customer-stories/customer-stories.vue'
  import ImageSlider from '../../../components/image-slider/image-slider.vue'
  import Flicking from "@egjs/vue3-flicking"
  import type { UseSwipeDirection } from '@vueuse/core'
  import { useWindowSize, usePointerSwipe, useMediaQuery } from '@vueuse/core'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'stories',
  setup(__props: any) {

  const props = __props
  const customerStore = useCustomerStore()

  const isLargeScreen = useMediaQuery('(min-width: 768px)')
  const activeStory = ref<Story>(null)
  const stories = ref<Story[]>([
    {
      clientName: 'Blue Yonder',
      sector: 'Supply Chain',
      quote: '“Working with Hakkoda has been such a great experience. Their responsive consulting and expert engineering were integral to the process of developing our native app. Our data platform is more efficient and accessible than ever, and we think the results speak for themselves.”',
      author: 'Indumathi Srimushnam',
      authorPosition: 'Blue Yonder Senior Director of Product Development',
      target: 'https://hakkoda.io/customers/blue-yonder/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/09/BY_testimonial-transformed.jpeg',
      video: 'h4m3ehka1u'
    },
    {
      clientName: 'Century',
      sector: 'Supply Chain',
      quote: '“They are a consulting firm where, not only do we lay out what we need and describe how we want to do it, they challenge us on how we think about what we’ve been doing.”',
      author: 'Alex Baronian',
      authorPosition: ' Data Warehouse Architect at Century Distribution Systems',
      target: 'https://hakkoda.io/customers/shipping-data-products-faster-with-century-distribution-systems/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/century-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/03/alex_b.webp',
      video: 'dkc9yk3fod'
    },
    {
      clientName: 'Sterling Capital',
      sector: 'Financial Services & Insurance',
      quote: '“Hakkoda’s expertise has given me the confidence that we’re partnering with a consultant and advisory firm that can provide us with not only feedback, but guidance on best practices as well as any missteps that they’ve seen along the way working with other firms similar to Sterling.”',
      author: 'Tom Gisondi',
      authorPosition: 'Sterling Capital Executive Director and Head of IT',
      target: '',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/Sterling-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/02/Trans_head_2.webp',
      video: 't42lk0w5zw'
    },
    {
      clientName: 'VGS',
      sector: 'Financial Services & Insurance',
      quote: '“Not only are we able to use Hakkoda to build some of our IP, we’re also working to figure out joint solutions we can take to Hakkoda’s customers.”',
      author: 'Nitin Bose',
      authorPosition: 'VGS VP of Product and Customer Success',
      target: 'https://hakkoda.io/customers/how-vgs-built-a-streamlit-app-to-perform-tokenization-in-snowflake/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/VGS-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2023/12/VGS_headshot-jpg.webp',
      video: '2ym3229q8k'
    },
    {
      clientName: 'CoreLogic',
      sector: 'Financial Services & Insurance',
      quote: '“Hakkoda ensured speed to market, quality, and supportable apps. It’s an absolute game changer for CoreLogic and their clients.”',
      author: 'John Rogers',
      authorPosition: 'CoreLogic Chief Innovation Officer',
      target: 'https://hakkoda.io/customers/corelogic/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/Corelogic-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2023/12/Corelogic_headshot-jpg.webp',
      video: 'm0fp0d62df'
    },
    {
      clientName: 'US Foods',
      sector: 'Supply Chain',
      quote: '“Hakkoda was able to align all stakeholders in the analytics processes behind the scenes and introduce functionality in Snowflake that we weren’t aware of.”',
      author: 'David Falck',
      authorPosition: 'US Foods VP of Insights and Analytics',
      target: 'https://hakkoda.io/customers/us-foods/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/USFOODS-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2023/12/US_Foods_headshot-jpg.webp',
      video: '9ry3laedip'
    },
    {
      clientName: 'PHM',
      sector: 'Healthcare & Life Sciences',
      quote: '“Healthcare information is notoriously messy and difficult to get into one format so we can analyze and create insight-backed decisions, and that’s really what Hakkoda has helped us drive.”',
      author: 'Matt Savacool',
      authorPosition: 'PHM Data Architect',
      target: 'https://hakkoda.io/customers/how-private-health-management-predicts-cancer/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/PHM-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2023/12/PHM_headshot-jpg.webp',
      video: 'epvkrlqrsu'
    },
    {
      clientName: 'MarViva',
      sector: 'Public Sector',
      quote: '“We saw a team thinking outside the box. To us, that was a very nice surprise and without a doubt it opened a lot of opportunities for how we approach our day to day challenges.”',
      author: 'Juan Posada',
      authorPosition: 'MarViva Science Manager',
      target: 'https://hakkoda.io/customers/marviva/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/Marvviva-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2023/12/MarViva_headshot_V002-jpg.webp',
      video: 'aqe4rlfdel'
    },
    {
      clientName: 'MHC',
      sector: 'Retail & CPG',
      quote: '“Hakkoda is highly flexible. Just in my first year, we’ve been able to adjust our contract, change our needs—they adapted really quickly. We really look at Hakkoda as more of a strategic partner, so we want to bring them into meetings where we have other executives who are new to Snowflake.”',
      author: 'Jules Parera',
      authorPosition: 'My Community Homes EVP of Technology',
      target: '',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/10/MCH-2.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/02/MCH_headshot_2.webp',
      video: 't42lk0w5zw'
    }
  ])
  const sectors = ref<String[]>(
    stories.value.map<String>( e => e.sector ).filter((value, index, self) => self.indexOf(value) === index)
  )
  const storiesBysector = ref<Story[]>(null)
  //
  const component = ref<HTMLElement | null>(null)
  const tabs = ref<HTMLElement | null>(null)
  const sectorTabs = ref<Flicking>(null)
  const swipeCustomerStories = ref<HTMLElement | null>(null)
  const logosSlider = ref<HTMLElement | null>(null)
  /*const { distanceX, distanceY, isSwiping } = usePointerSwipe(swipeCustomerStories, {
    onSwipeEnd(e: PointerEvent, direction: UseSwipeDirection) {
      const index = sectors.value.findIndex( s => s == activeStory.value.sector );

      //console.log('usePointerSwipe', e, direction , distanceX.value, distanceY.value );

      if( direction == 'left' && index < sectors.value.length - 1 ) {
        const next = index + 1
        onSectorClick(sectors.value[ next ])
        sectorTabs.value.moveTo( next )
      }

      if( direction == 'left' && index == sectors.value.length - 1 ) {
        const next = 0 // first
        onSectorClick(sectors.value[ next ])
        sectorTabs.value.moveTo( next )
      }

      if( direction == 'right' && index == 0 ) {
        const prev = sectors.value.length - 1 // last
        onSectorClick(sectors.value[ prev ])
        sectorTabs.value.moveTo( prev )
      }

      if( direction == 'right' && index > 0 && index < sectors.value.length ) {
        const prev = index - 1
        onSectorClick(sectors.value[ prev ])
        sectorTabs.value.moveTo( prev )
      }

      if( direction == 'up') {
        console.log();

        window.scroll({
          top: window.top.scrollY + Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      if( direction == 'down') {
        window.scroll({
          top: window.top.scrollY - Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }
    }
  })*/

  onMounted( ()  => {
    onActive(stories.value[0])

    customerStore.$subscribe( (mutation, state) => {
      const active: Story = state.activeStory
      console.log('state', active)
      if( active?.clientName ) {
        const story: Story = stories.value.find((e) => e.clientName == active.clientName)
        onActive(story)
        scrollToComponent()
      }
    })
  })

  onUnmounted( () => {
  })

  function onActive(val: Story) {
    activeStory.value = val
    storiesBysector.value = stories.value.filter((story) => story.sector == activeStory.value.sector)

  }

  function onSectorClick(val) {
    const story: Story = stories.value.find((e) => e.sector == val)
    onActive(story)
  }

  function onLogoClick(src) {
    const story: Story = stories.value.find((e) => e.logo == src)
    onActive(story)
  }

  function scrollToComponent() {

    console.log('scrollToComponent',isLargeScreen.value, tabs.value.offsetTop)

    if( isLargeScreen.value ) {
      window.scroll({
        top: tabs.value.offsetTop - 80,
        left: 0,
        behavior: "smooth",
     })
    } else {
      window.scroll({
        top: tabs.value.offsetTop,
        left: 0,
        behavior: "smooth",
     })
    }
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "component",
    ref: component,
    class: "home-stories--component full-height-component"
  }, [
    _createElementVNode("div", {
      ref_key: "tabs",
      ref: tabs,
      class: "home-stories--tabs"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Flicking), {
          ref_key: "sectorTabs",
          ref: sectorTabs,
          class: "flicking",
          options: { moveType: 'freeScroll', horizontal: true, circular: false, bound: true, align: 'prev', autoInit: true }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sectors.value, (sector, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([{
              active: activeStory.value?.sector == sector
            }, "home-stories--sector"]),
                key: index
              }, [
                _createElementVNode("p", {
                  onClick: ($event: any) => (onSectorClick(sector))
                }, _toDisplayString(sector), 9, _hoisted_2)
              ], 2))
            }), 128))
          ]),
          _: 1
        }, 512)
      ])
    ], 512),
    _createElementVNode("div", {
      ref_key: "swipeCustomerStories",
      ref: swipeCustomerStories
    }, [
      _createVNode(CustomerStories, {
        "stories-conf": storiesBysector.value,
        "is-dark-theme": true,
        "is-embed": true,
        "is-full-height": true,
        reverse: true,
        showDropdown: true,
        active: activeStory.value,
        onActive: onActive
      }, null, 8, ["stories-conf", "active"])
    ], 512),
    false
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref_key: "logosSlider",
          ref: logosSlider
        }, [
          _createVNode(ImageSlider, {
            stories: stories.value,
            onOnClick: onLogoClick
          }, null, 8, ["stories"])
        ], 512))
      : _createCommentVNode("", true)
  ], 512))
}
}

})