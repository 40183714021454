<template>
  <div class="hkkd-home-page">
    <HomePageHero></HomePageHero>
    <Stories></Stories>
    <Services></Services>
    <Industries></Industries>
    <Solutions></Solutions>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue'

  import HomePageHero from './components/hero.vue'
  import Stories from './components/stories.vue'
  import Services from './components/services.vue'
  import Industries from './components/industries_v2.vue'
  import Solutions from './components/solutions.vue'

  interface Props {
  }

  const props = defineProps<Props>()
  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>