<template>
  <h2 
    v-if="!hideForm"
    class="h3 form-title"
  >{{ title || 'Register Now' }}</h2>

  <hubspot-form
    v-if="!hideForm"
    :portal-id = "portalId"
    :form-id = "formId"
  ></hubspot-form>

  <h2 
    v-if="hideForm"
    class="h3 form-title"
  >Thank you for registering!</h2>

  <div class="policy-text">
    When you share your details here, we’ll use them to stay in touch. We may contact you from time to time to
    tell you about our services. You can opt out at any time by clicking ‘unsubscribe’ in our email. Read our
    <a href="https://hakkoda.io/privacy-policy/" target="_blank">Privacy Policy</a> to learn more.
  </div>

</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import { FormInfo, useFormStore } from '../store/forms'
  import HubspotForm from '../components/hubspot-form/hubspot-form.vue'

  interface Props {
    title: String
    portalId: String
    formId: String
    videoId: String
  }

  const props = defineProps<Props>()
  const store = useFormStore()
  const title = ref(props.title)
  const formId = ref(props.formId)
  const hideForm = ref(false) 

  onMounted( ()  => {
    const isFormSubmited = localStorage.getItem(formId.value);

    if(isFormSubmited) {
      hideForm.value = true;
    }

    if (typeof props?.videoId === 'string' && props?.videoId.length > 0) {
      title.value = 'Watch Now';
    }

    store.$subscribe( (mutation, state) => {
      const submitedForm: FormInfo = state?.submitedForm

      if(submitedForm?.submitedEmail && submitedForm.formId == props.formId) {
        hideForm.value = true;
      }
    })
  })
</script>