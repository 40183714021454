import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "home-services--component-container"
}
const _hoisted_2 = { class: "home-services--component" }
const _hoisted_3 = { class: "home-services--container" }
const _hoisted_4 = { class: "home-services--video" }

import { ref, onMounted, onUnmounted, computed } from 'vue'
    import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'services',
  setup(__props: any) {

  const props = __props

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(WistiaVideoPlayer, {
            "video-id": '446vs1v7n7',
            "auto-play": true,
            mute: true,
            replay: true,
            "hide-controls": true,
            preload: true
          })
        ])
      ]),
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"home-services--container content\" data-v-3efc2694><div class=\"home-services--content\" data-v-3efc2694></div><div class=\"home-services--subtitle\" data-v-3efc2694><p data-v-3efc2694>Services</p></div><div class=\"home-services--title\" data-v-3efc2694><h2 data-v-3efc2694>Innovative Results for the Real World.</h2></div><div class=\"home-services--description\" data-v-3efc2694><p class=\"home-services--description-paragraph\" data-v-3efc2694>Discover modern data services built to help you harness cloud platforms &amp; cutting-edge AI. Connect with global teams of the world’s best data talent for in-timezone support, end-to-end expertise in the modern data stack, and accelerated capabilities. Transform your next data project into high-impact outcomes.</p></div><div class=\"home-services--btn\" data-v-3efc2694><a class=\"btn-md\" href=\"https://hakkoda.io/services\" data-v-3efc2694>Explore Data Services</a></div></div>", 1))
    ])
  ], 512))
}
}

})