<template>
  <div ref="component" class="home-industries--component full-height-component">

    <div class="home-industries--container padding-1">
      <div class="home-industries--subtitle"><p>Industries</p></div>
      <div class="home-industries--title"><h2>Custom Solutions for Complex Industries</h2></div>
      <div class="home-industries--list">
        <div 
          class="home-industries--panel"
          v-for="(industryVal, index) in conf"
          :class="{
            'active': activeIndustry?.name == industryVal?.name
          }"
          :key="index"
          @click="setActivePanel(industryVal)"
        >
        <div class="home-industries--accordion">
          <div class="home-industries--accordion-headline">
            <i class="fa-solid fa-square"></i>
            <h3>{{ industryVal?.name }}</h3>
          </div>
        </div>
        <p>{{ industryVal?.description }}</p>
        </div>
      </div>
    </div>
    <div class="home-industries--container">
      <div class="home-industries--container-circle">
        <div class="hero-circle">
         <div class="home-industries--sun">
            <img src="https://hakkoda.io/wp-content/uploads/2024/11/Hkkd.svg" alt=""/>
         </div>
          <div class="hero-rotate" id="circle">
            <div 
              v-for="(industryVal, index) in conf" 
              class="planet"
              :class="{
                'active': activeIndustry?.name == industryVal?.name
              }"
            >
              <img 
                :src="`${ industryVal.poster }`"
                :alt="`${ industryVal.name }`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue'
  import Industry from '../../../models/industry.model'

  interface Props {
  }

  const props = defineProps<Props>()
  const conf = ref<Industry[]>([
    {
      name: 'Healthcare & Life Sciences',
      description: 'Quality healthcare requires quality data, serviced by expert teams of data scientists, engineers, and technologists with hands-on experience at the world’s largest healthcare organizations.',
      target: 'https://hakkoda.io/healthcare/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/HLS_image.webp'
    },
    {
      name: 'Financial Services',
      description: 'Our experienced financial services technologists can help you maximize your data to streamline operations, improve your customer experience, and tap into new revenue sources.',
      target: 'https://hakkoda.io/financial-services/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/FSI_image.webp'
    },
    {
      name: 'Supply Chain & Logistics',
      description: 'Effective supply chain management relies on high-quality data to streamline operations, reduce costs, and improve responsiveness to supply and demand volatility. The right blend of data tooling and talent is crucial for ensuring data accuracy, managing risk, and delivering actionable insights that drive efficiency and agility in complex logistics networks.',
      target: 'https://hakkoda.io/supply-chain-logistics/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/09/logistics.jpg'
    },
    {
      name: 'Public Sector',
      description: 'Securely deploy and assetize your organization’s data with tools & capabilities embraced by the commercial sector, backed by our teams of public sector data leaders.',
      target: 'https://hakkoda.io/public-sector/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/PS_image_v3.webp'
    },
    {
      name: 'Retail & CPG',
      description: 'Hone your sales and marketing strategies with powerful CDP platforms that unify data across the customer lifecycle with ERP analytics and other critical sources. Empower end-to-end enterprise analytics to enrich demand forecasting, optimize inventory management, deliver bespoke ad campaigns, and respond to market changes faster than the competition.',
      target: 'https://hakkoda.io/retail-cpg/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/10/Retail-CPG-2-1024x1024.jpg'
    }
  ])
  const activeIndustry = ref<Industry>(conf.value[0])

  function setActivePanel(val: Industry ){
    activeIndustry.value = val
  }

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .home-industries {

    &--component {
      display: flex;
      width: 100%;
      background-color: #fff;
      flex-direction: column-reverse;

      .padding-1 {
        padding: 20px;
      }

      @media (min-width: 991px) {
        flex-direction: row;

        .padding-1 {
          padding: 20px;
        }
      }
    }

    &--container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      &-circle {
        display: grid;
        width: 100vw;
        height:30vh;
        place-items: center;
        overflow: hidden;
        background-color: #fff;
        --circleDiameter: 50vh;
        --planets: calc(var(--circleDiameter) / 6);

        @media (min-width: 991px) {
          width: 50vw;
          height:100vh;
          --circleDiameter: 60vh;
        }
      }
    }

    &--title {

      h2 {
        color: #000;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
      }
    }

    &--subtitle {
      p {
        color: #21B0E2;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }
    }

    &--panel {
      display: flex;
      padding: 0px 40px;
      flex-direction: column;
      justify-content: center;
      gap: 7px;
      align-self: stretch;

      &.active {
        border-bottom: 1px solid #96989B;
        background: var(--Primary-Palette-White, #FFF);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);

        p {
          display: flex;
        }
      }

      i {
        color: #00D5FF;
        font-size: 12px;
        margin-right: 40px;
      }

      h3 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        padding: 10px 0;
      }

      p {
        display: none;
        color: #424957;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
      }
    }

    &--accordion {

      &-headline {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &--sun {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      border-radius: 50%;
      padding: 40px;
      background: #FFFFFF99;
      backdrop-filter: blur(3px);
      box-shadow: 3px 3px 30px 10px rgba(0,0,0,0.05);
    }
  }

  .hero-circle {
      position:relative;
      width: var(--circleDiameter);
      height: var(--circleDiameter);
      left: 5%;
  }

  .hero-rotate {
      position: relative;
      top: -7px;
      left:-7px;
      width: 100%;
      height: 100%;
      border: 2px solid #E3DFE5;
      border-radius: 100%;
      //animation: rotate 30s linear infinite;
  }

  .planet {
    position: absolute;
    width: var(--planets);
    aspect-ratio: 1;
    border-radius: 100%;
    //animation: maintain 30s linear infinite;

    &.active {
      width: calc(var(--planets) + 60px);
    }
  }

  .planet:nth-child(1) {
    top: calc(var(--circleDiameter)/ 2 - var(--planets) / 2);
    left: calc(-1 * var(--planets) / 2);
  }

  .planet:nth-child(2) {
    top: calc(-1 * var(--planets) / 2);
    left: calc(var(--circleDiameter)/ 2 - var(--planets) / 2);
  }

  .planet:nth-child(3) {
    top: calc(var(--circleDiameter)/ 2 - var(--planets) / 2);
    right: calc(-1 * var(--planets) / 2);
  }

  .planet:nth-child(4) {
    left: calc(var(--circleDiameter)/ 2 - var(--planets) / 2 + 30%);
    bottom: calc(-1 * var(--planets) / 2 + 10%);
  }

  .planet:nth-child(5) {
    left: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 25%);
    bottom: calc(var(--planets)* -1 / 2 + 8%);
  };

  /*.planet:nth-child(1) {
    top: calc(-1 * var(--planets) / 2);
    left: calc(var(--circleDiameter)/ 2 - var(--planets) / 2 - 5px)
  }

  .planet:nth-child(2) {
    top: calc(var(--circleDiameter)/ 2 - var(--planets) / 2 - 5px);
    right: calc(-1 * var(--planets) / 2);
  }

  .planet:nth-child(3) {
    left: calc(var(--circleDiameter)/ 2 - var(--planets) / 2 - 5px);
    bottom: calc(-1 * var(--planets) / 2);
  }

  .planet:nth-child(4) {
    top: calc(var(--circleDiameter)/ 2 - var(--planets) / 2 - 5px);
    left: calc(-1 * var(--planets) / 2);
  }

  .planet:nth-child(5) {
    left: calc(var(--circleDiameter)/ 2 - var(--planets) / 2 - 25px);
    bottom: calc(-1 * var(--planets) / 2);
  }; */

  .planet>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 2px solid #E3DFE5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      //animation: scale 1s linear infinite alternate;
  } 

  @keyframes rotate {
      0% {
          transform: rotate(0)
      }

      100% {
          transform: rotate(360deg);
      }
  }

  @keyframes maintain {
      0% {
          transform: rotate(0);
      }

      100% {
          transform: rotate(-360deg);
      }
  }

  @keyframes scale {
      0% {
          transform: scale(1.2);
      }

      100% {
          transform: scale(1);
      }
  }

</style>