import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, onMounted, onUnmounted, nextTick } from 'vue'
  import { mapActions, mapState } from 'pinia'
  import { FormInfo, useFormStore } from '../../store/forms'
  import WistiaVideoPlayer from './wistia-video-player.vue'

  interface Props {
    videoId: String,
    hubspotFormId: String
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'gated-video-player',
  props: {
    videoId: {},
    hubspotFormId: {}
  },
  setup(__props: any) {

  const props = __props
  const store = useFormStore()
  const hideVideo = ref(true)
  const formId = ref(props.hubspotFormId)

  onMounted( ()  => {
    const isFormSubmited = localStorage.getItem(formId.value);
    
    if(isFormSubmited) {
      hideVideo.value = false;
    }

    store.$subscribe( (mutation, state) => {
      const submitedForm: FormInfo = state?.submitedForm

      if(submitedForm?.submitedEmail && submitedForm.formId == formId.value) {
        hideVideo.value = false;
        localStorage.setItem(submitedForm.formId, true)
        setTimeout(() => {
          const element = document.querySelector('.wistia-video-player--component')
          element.scrollIntoView({ behavior: "smooth", block: "end" })
        }, 500);
      }
    })
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(WistiaVideoPlayer, {
    "video-id": _ctx.videoId,
    "auto-play": true,
    hideVideo: hideVideo.value
  }, null, 8, ["video-id", "hideVideo"]))
}
}

})