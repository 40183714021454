<template>
  <div class="home-page--hero">
    <div v-if="!isLargeScreen" class="home-hero-mobile-container">
      <HeroMobile></HeroMobile>
    </div>
    <div v-if="isLargeScreen" class="home-hero-desktop-container">
      <HeroV3></HeroV3>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import { useMediaQuery } from '@vueuse/core'
  import HeroV3 from './hero_v3.vue'
  import HeroMobile from './hero_mobile.vue'

  interface Props {
  }

  const props = defineProps<Props>()
  const isLargeScreen = useMediaQuery('(min-width: 992px)')

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>