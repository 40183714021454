<template>
  <div ref="component" class="ia-differentiators--component">
    <div class="ia-differentiators--tabs d-none">
      <div 
        class="ia-differentiators--tabs-container"
      >
        <Flicking
          ref="tabLabelRef"
          class="flicking" 
          :options="{ moveType: 'freeScroll', horizontal: true, circular: false, bound: true, align: 'prev', autoInit: true }"
        >
          <div
            v-for="(label, index) in tabsLabel"
            class="ia-differentiators--tabs-label"
            :class="{
              'active': activeTab?.label == label
            }"
            :key="index"
            @click="onTabClick(label)"
          >
            <p>{{ label }}</p>
          </div>
        </Flicking>
      </div>
    </div>
    <div ref="tabContentRef" id="ia-differentiators--carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="ia-differentiators--dots-container">
          <div
            v-for="(label, index) in tabsLabel"
            class="ia-differentiators--dots"
            :class="{
              'active': activeTab?.label == label
            }"
            :key="index"
            @click="onTabClick(label)"
          >
          <span class="ia-differentiators--dots-icon"></span>
          </div>
      </div>
      <div class="carousel-inner">
        <div 
          class="ia-differentiators--content carousel-item"
          data-bs-interval="5000"
          data-bs-touch="false"
          v-for="(tab, tabIndex) in conf"
          :class="{
            'active': activeTab?.label == tab.label
          }"
        >
          <div class="ia-differentiators--image d-flex justify-content-center">
            <img 
                  :src="`${ tab.image }`"
                  :key="`${ tabIndex }`"
                >
          </div>
          <div class="ia-differentiators--content-container">
            <div class="ia-differentiators--title">
              <h3>{{ tab.title }}</h3>
            </div>
            <div class="ia-differentiators--description">
              <p>{{ tab.description }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import Flicking from "@egjs/vue3-flicking"
  import type { UseSwipeDirection } from '@vueuse/core'
  import { usePointerSwipe } from '@vueuse/core'

  interface Props {
  }

  interface tabConf {
    label: String,
    image: String,
    title: String,
    description: String
  }

  const props = defineProps<Props>()
  const activeTab = ref<tabConf>(null)
  const conf = ref([
    {
      label: 'Experience that Scales',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Experience-that-Scales.png',
      title: 'Experience that Scales',
      description: 'Migrations are complicated, multi-stakeholder events. Align your team with the aid of experts with decades of experience in your industry who understand your goals and pain points because they’ve been in your shoes. Scale up and accelerate your migration with AI copilots designed to speed up the process without sacrificing quality control.'
    },
    {
      label: 'Say Goodbye to Manual Slow Down',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Say-Goodbye-to-Manual-Slow-Down.png',
      title: 'Say Goodbye to Manual Slow Down',
      description: 'Leverage AI-powered solutions that eliminate costly manpower and the risk of human error, as well as custom accelerators built with your industry’s top challenges in mind.'
    },
    {
      label: 'Test & Review All in One place',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Test-Review-All-in-One-place.png',
      title: 'Test & Review All in One place',
      description: 'Execute queries, test, iterate, improve, and compile metrics. All in one place, with automation for support.'
    },
    {
      label: 'Govern & Grow with Your Org',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Govern-Grow-with-Your-Org.png',
      title: 'Govern & Grow with Your Org',
      description: 'Create access, role-based controls, and the framework you need to enable users across every department in your organization. Leverage AI-powered accelerators for smarter, more scalable data governance and master data management.'
    }
  ])
  const tabsLabel = ref<String[]>(
    conf.value.map<String>( e => e.label ).filter((value, index, self) => self.indexOf(value) === index)
  )
  //
  const tabLabelRef = ref<HTMLElement | null>(null)
  const tabContentRef = ref<HTMLElement | null>(null)
  const { distanceX, distanceY, isSwiping } = usePointerSwipe(tabContentRef, {
    onSwipeEnd(e: PointerEvent, direction: UseSwipeDirection) {
      const index = tabsLabel.value.findIndex( label => label == activeTab.value.label );

      // console.log('usePointerSwipe',index)
      isSwiped.value = true

      if( direction == 'left' && index < tabsLabel.value.length - 1 ) {
        const next = index + 1
        tabLabelRef.value.moveTo( next )
        activeTab.value = conf.value[ next ]
      }

      if( direction == 'left' && index == tabsLabel.value.length - 1 ) {
        const next = 0 // first
        activeTab.value = conf.value[ next ]
        tabLabelRef.value.moveTo( next )
      }

      if( direction == 'right' && index == 0 ) {
        const prev = tabsLabel.value.length - 1 // last
        activeTab.value = conf.value[ prev ]
        tabLabelRef.value.moveTo( prev )
      }

      if( direction == 'right' && index > 0 && index < tabsLabel.value.length ) {
        const prev = index - 1
        activeTab.value = conf.value[ prev ]
        tabLabelRef.value.moveTo( prev )
      }

      if( direction == 'up') {
        window.scroll({
          top: window.top.scrollY + Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      if( direction == 'down') {
        window.scroll({
          top: window.top.scrollY - Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      
      setTimeout(() => {
        isSwiped.value = false
      }, 100)
    }
  })
  const isSwiped = ref(false)

  onMounted( ()  => {
    activeTab.value = conf.value[0]

    const myCarousel = document.getElementById('ia-differentiators--carousel')
    myCarousel.addEventListener('slide.bs.carousel', event => {
      if ( !isSwiped.value ) {
        activeTab.value = conf.value[event?.to || 0]
      }
    })
  })

  function onTabClick(val) {
    const tab: tabConf = conf.value.find((e) => e.label == val)
    activeTab.value = tab
  }
  onUnmounted( () => {

  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .ia-differentiators {

    &--component {
      display: block;
      width: 100%;
      background-color: #000;
    }

    &--tabs {
      display: flex;
      flex-flow: row;
      width: 100%;
      height: 120px;

      &.active {
      }

      @media (min-width: 992px) {
        height: 120px;
      }
    }

    &--tabs-container {
      display: flex;
      border-bottom: 1px solid #555357;
      width: 100%;
      justify-content: center;
      margin-bottom: 33px;

      .flicking {
        display: flex;
        padding: 40px 0;
      }
    }

    &--tabs-label {
      color: #fff;
      margin-left: 20px;

      p {
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.3px;
        padding: 10px 10px 8px;
      }

      &.active {
        

        p {
          border-bottom: 4px solid #FF8A66;
          margin: 0;
        }
      }

      @media (min-width: 992px) {
      }
    }

    &--dots {
      padding: 5px;

      &-container {
        display: flex;
        justify-content: center;
        padding: 20px;
      }

      &-icon {
        display: block;
        height: 10px;
        width: 10px;
        background-color: #303135;
        border-radius: 50%;
      }

      &.active span {
        background-color: #FF8A66;
      }
    }

    &--content {
      display: none;
      flex-direction: column;
      width: 100%;
      min-height: 40vh;
      padding: 20px;

      &.active {
        display: flex;
        align-items: center;
      }

      &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        padding: 40px;

        &-container {
          width: 70%;
        }
      }

      @media (min-width: 1200px) {
        padding: 60px;
      }
    }

    &--image {
      padding: 20px;
    }

    &--title {
      display: flex;
      color: #FF8A66;
      padding: 10px 20px;

      @media (min-width: 1400px) {
        h3 {
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: 50px; 
        }
      }
    }

    &--description {
      display: flex;
      flex: 1;
      color: #fff;
      padding: 10px 20px 40px;
      min-height: 200px;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        letter-spacing: 0.32px;
      }
    }
  }
</style>