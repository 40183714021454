<template>
  <article class="resource-card--wrapper">
      <a
        class="resource-card--thumbnail"
        :href="`${ permalink }`"
      >
        <img
          loading="lazy" 
          :src="`${ thumbnail }`"
          class="resource-card--image" 
          alt="Thumbnail">
      </a>
      <div class="resource-card--info">
        <div class="resource-card--header">
          <div class="resource-card--meta">
            <a 
              class="resource-card--category" 
              :href="`${ categoryLink || '' }`"
            >
              {{ categoryName || '' }}
            </a>
            <time class="resource-card--date">
              {{ date }}
            </time>
          </div>
            <h3 class="resource-card--title">
              <a 
                :href="`${ permalink }`"
                class="resource-card--title-link"
              >
                {{ title }}
              </a>
            </h3>
            <div class="resource-card--excerpt">
              {{ excerpt }}
            </div>
          </div>
          <div class="resource-card--footer">
            <div class="resource-card--tag">
              <a
                v-for="tag in tags"
                :href="`${ tag?.link || '' }`"
                class="resource-card--tag-link"
              >
                {{ tag?.name || '' }}
              </a>
            </div>
            <a 
              :href="`${ permalink }`"
              class="resource-card--link" 
              :title="`${ title }`"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="8" 
                height="13" 
                viewBox="0 0 8 13" 
                fill="none">
                  <path 
                    d="M1 12.3536L7 6.35361L1 0.353607"
                    stroke="black"
                    stroke-miterlimit="10"
                  ></path>
              </svg>
            </a>
          </div>
      </div>
  </article>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'

  interface Props {
    title: String,
    permalink: String,
    thumbnail: String,
    date: String,
    excerpt: String,
    categoryName: String,
    categoryLink: String,
    tagsEncoded: String
  }

  const props = defineProps<Props>()
  const tags = ref(JSON.parse(props.tagsEncoded) || null)

  onMounted( ()  => {
  })

  onUnmounted( () => {

  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .resource-card {

    &--wrapper {
      padding: 40px;
      display: flex;
      flex-direction: column;
    }

    &--image {
      display: block;
      width: 100%;
      height: auto;
    }
    
    &---info {
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex: 1;
    }

    &--meta {
      margin-top: 20px;
      display: flex;
      align-content: space-between;
      flex-flow: row wrap;
    }

    &--category {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.16;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &--date {
      margin-left: 24px;
      color: #000;
      opacity: 0.4;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.16;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    
    &--title {
      margin-top: 20px;
      margin-bottom: 12px;
    }

    &--title-link {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.33;
    }

    &--excerpt {
      font-size: 18px;
    }
    
    &--footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
    }
    
    &--tag {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      max-width: 90%;
    }

    &--tag-link {
      display: flex;
      padding: 4px 8px;
      align-items: flex-start;
      border-radius: 10px;
      background: #e3dfe5;
      color: #000;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.2;
      text-transform: uppercase;
    }

    &--link {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      margin-right: -10px;
      
      svg {
        height: 13px;
        width: auto;
        display: block;
        object-fit: contain;
      }
    }
  }

  .theme--purple {
    $primary-font-color: #000;
    $primary-border-color: #e3dfe5;
    $primary-background-color: #dbc3ff;
    $accent-font-color: #fff;
    $accent-background-color: #47397c;

    .resource-card {

      &--image {
        background-color: $primary-background-color;
      }

      &--category {
        color: $primary-font-color;
      }
 

      &--date {
        color: $primary-font-color;
      }
      
      &--title-link {
        color: $primary-font-color;

        &:hover {
          color: $accent-background-color;
        }
      }

      &--tag-link {
        background: $primary-background-color;
        color: $primary-font-color;

        &:hover {
          color: $accent-font-color;
          background: $accent-background-color;
        }
      }
    }
  }

  .theme--green {
    $primary-font-color: #000;
    $primary-border-color: #e3dfe5;
    $primary-background-color: #91fffb;
    $accent-font-color: #fff;
    $accent-background-color: #006664;

    .resource-card {

      &--image {
        background-color: $primary-background-color;
      }

      &--category {
        color: $primary-font-color;
      }
 

      &--date {
        color: $primary-font-color;
      }
      
      &--title-link {
        color: $primary-font-color;

        &:hover {
          color: $accent-background-color;
        }
      }

      &--tag-link {
        background: $primary-background-color;
        color: $primary-font-color;

        &:hover {
          color: $accent-font-color;
          background: $accent-background-color;
        }
      }
    }
  }

  .theme--blue {
    $primary-font-color: #000;
    $primary-border-color: #e3dfe5;
    $primary-background-color: #91fffb;
    $accent-font-color: #fff;
    $accent-background-color: #294369;

    .resource-card {

      &--image {
        background-color: $primary-background-color;
      }

      &--category {
        color: $primary-font-color;
      }
 

      &--date {
        color: $primary-font-color;
      }

      &--title-link {
        color: $primary-font-color;

        &:hover {
          color: $accent-background-color;
        }
      }

      &--tag-link {
        background: $primary-background-color;
        color: $primary-font-color;

        &:hover {
          color: $accent-font-color;
          background: $accent-background-color;
        }
      }
    }
  }

  .theme--retail {
    $primary-font-color: #000;
    $primary-border-color: #e3dfe5;
    $primary-background-color: #dbc3ff;
    $accent-font-color: #fff;
    $accent-background-color: #594aa2;

    .resource-card {

      &--image {
        background-color: $primary-background-color;
      }

      &--category {
        color: $primary-font-color;
      }
 

      &--date {
        color: $primary-font-color;
      }

      &--title-link {
        color: $primary-font-color;

        &:hover {
          color: $accent-background-color;
        }
      }

      &--tag-link {
        background: $primary-background-color;
        color: $primary-font-color;

        &:hover {
          color: $accent-font-color;
          background: $accent-background-color;
        }
      }
    }
  }

  @media (min-width: 991px) {
    .resource-card {
      flex: 1;
      padding: 64px;
    }
  }
</style>