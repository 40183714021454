import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "animation_container",
  class: "adobe-animation--component"
}

import { ref, onMounted, onUnmounted, reactive, watch } from 'vue'
  import { useElementBounding} from '@vueuse/core'
  import _ from 'lodash';
  import { loadScript } from  '../../tools/index';

  interface Props {

  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'animation',
  setup(__props: any) {

  const props = __props
  const inicialRef = ref(null)

  let canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;
 
  const loadCreatejs = () => {
    loadScript('https://code.createjs.com/1.0.0/createjs.min.js', () => {
      loadScript('http://localhost:8888/wp-content/themes/hakkoda/assets/movies/industries.js', () => {

        canvas = document.getElementById("canvas");
        anim_container = document.getElementById("animation_container");
        //dom_overlay_container = document.getElementById("dom_overlay_container");
        var comp = AdobeAn.getComposition("7B950D05957E4082B981347D2C70B033");
        var lib = comp.getLibrary();
        handleComplete({},comp);
      })
    })
  }

  function handleComplete(evt,comp) {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    var lib = comp.getLibrary();
    var ss = comp.getSpriteSheet();
    exportRoot = new lib.Industries_V006_split();
    stage = new lib.Stage(canvas);	
    //Registers the "tick" event listener.
    fnStartAnimation = function() {
      stage.addChild(exportRoot);
      createjs.Ticker.framerate = lib.properties.fps;
      createjs.Ticker.addEventListener("tick", stage);
    }	    
    //Code to support hidpi screens and responsive scaling.
    AdobeAn.makeResponsive(stage, true,'both',true,1,[canvas,anim_container]);	
    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
  }

  onMounted( ()  => {
    loadCreatejs()
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("canvas", {
      id: "canvas",
      class: "adobe-animation--canvas"
    }, null, -1)
  ])))
}
}

})