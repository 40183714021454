<template>
  <div ref="inicialRef"></div>
  <div 
    ref="component" 
    class="hubspot--component"
    :class="{
      'fixed-md': isFixed
    }"
  >
    <div
      v-if="title" 
      class="hubspot--title"
      :class="{
        'theme-ai': theme == themes.IA
      }"
    >
      <h3>{{ title }}</h3>
    </div>
    <div class="hubspot--form">
      <div class="hubspot--form-container"></div>
      <div
        v-if="disclaimer" 
        class="hubspot--disclaimer"
        :class="{
          'theme-ai': theme == themes.IA
        }"
      >
        <p>{{ disclaimer }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, reactive, watch } from 'vue'
  import { useElementBounding} from '@vueuse/core'
  import _ from 'lodash';
  import { FormInfo, useFormStore } from '../../store/forms'
  import { loadScript } from  '../../tools/index'

  enum themes {
    IA = 'theme-ia'
  }

  interface Props {
    portalId: String,
    formId: String,
    title?: String,
    disclaimer?: String,
    isFixedAfterView?: Boolean,
    theme?: themes
  }

  const props = defineProps<Props>()
  const store = useFormStore()
  const inicialRef = ref(null)
 
  const inicialRect = reactive(useElementBounding(inicialRef))
  const isFixed = ref(false)

  const loadHubspotForm = ({ portalId, formId}: Props) => {
    loadScript('https://js.hsforms.net/forms/embed/v2.js', () => {
      window.hbspt.forms.create({
            target: '.hubspot--form-container',
            region: 'na1',
            portalId: portalId,
            formId: formId,
            css: ''
        })
    })
  }

  const handleScroll = ( event ) => {
    console.log('rect', inicialRect.top )

    if (inicialRect.top < 95 && !isFixed.value ) {
      isFixed.value = true
    }
    if (inicialRect.top > 95 && isFixed.value ) {
      isFixed.value = false
    }
  }

  onMounted( ()  => {
    loadHubspotForm(props)
    if ( props.isFixedAfterView ) {
      window?.addEventListener('scroll', _.throttle(handleScroll, 200))
    }

    window?.addEventListener('message',  ( event ) => {
      if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        const submitedData = event.data?.data?.submissionValues || null
        const formData: FormInfo = {
          formId: event.data?.id,
          submitedEmail: submitedData?.email || ''
        }
        store.setSubmited(formData)
        store.$patch({
          submitedForm: formData
        })
      }
    })
  })

  onUnmounted( () => {
    if ( props.isFixedAfterView ) {
      window.removeEventListener('scroll', handleScroll);
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .hubspot {

    &--component {

      &.fixed-md {

        @media (min-width: 992px) {
          position: fixed;
          top: 100px;
          height: 100vh;
          padding-right: 25px;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none;  /* Internet Explorer 10+ */

          &::-webkit-scrollbar { /* WebKit */
              width: 0;
              height: 0;
          }

          .hubspot--title {
            padding-top: 20px;
          }
        }
      }
    }

    &--title {
      color: #fff;
      padding-top: 100px;

      &.theme-ai {
        color: #fff;
      }
    }

    &--disclaimer {
      color: #fff;
      min-height: 300px;
      padding: 20px 0;

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 1px;
      }

      &.theme-ai {
        color: #fff;
      }
    }

  }
</style>