<template>
  <div ref="component" class="home-solutions--component-container">
    <div class="home-solutions--component">
      <div class="home-solutions--container">
        <div class="home-solutions--heading">
          <div class="home-solutions--subtitle">
            <p>Solutions & Accelerators</p>
          </div>
          <div class="home-solutions--title">
            <h2>We Solve Problems Once. Forever.</h2>
          </div>
          <div class="home-solutions--description">
            <p class="home-solutions--description-paragraph">When we see our clients regularly come up against similar challenges, we follow that pattern to the source—building Solutions and Accelerators that let you migrate, modernize, and monetize your data faster than ever before.</p>
          </div>
        </div>
        <div class="home-solutions--arrows">
          <img @click="prev" class="home-solutions--arrows-image svg-white" src="https://hakkoda.io/wp-content/uploads/2024/11/solution-left-arrow.svg" alt=""/>
          <img @click="next" class="home-solutions--arrows-image svg-white" src="https://hakkoda.io/wp-content/uploads/2024/11/solution-rigth-arrow.svg" alt=""/>
        </div>
      </div>
      <div class="home-solutions--container">
        <swiper
          :slidesPerView="'auto'"
          :spaceBetween="20"
          :loop="true"
          @swiper="onSwiper"
          class="solutionsSwiper"
        >
          <swiper-slide
            v-for="(solution, index) in conf" 
          >
            <div 
              class="home-solutions--item" 
              @mouseover="mouseOver(solution)"
            >
              <div class="home-solutions--card-title-container">
                <img
                  class="home-solutions--logo"
                  :src="`${ solution.logo }`"
                  :alt="`${ solution.solutionName }`"
                />
                <h3 class="home-solutions--card-title">{{ solution.solutionName }}</h3>
              </div>
              <div :class="`home-solutions--poster-container ${ solution.class }`">
                <img
                  class="home-solutions--poster"
                  :class="{
                    'active': solution?.solutionName == active?.solutionName
                  }"
                  :src="`${ solution.posterImage }`"
                  :alt="`${ solution.solutionName }`"
                />
              </div>
              <div class="home-solutions--overlay"
                v-show="solution?.solutionName == active?.solutionName || !isLargeScreen"
              >
                  <div class="home-solutions--overlay-container">
                    <a
                      class="underline-decoration"
                      :href="`${ solution.targer }`"
                    >LEARN MORE</a>
                    <a 
                      :href="`${ solution.targer }`"
                    >
                    <img class="home-solutions--overlay-arrow" src="https://hakkoda.io/wp-content/uploads/2024/11/arrow-up-right.svg" alt="Watch the full story"/>
                    </a>
                  </div>
              </div>
              <div class="home-solutions--card-description">
                <div class="home-solutions--cloud-container">
                  <span class="home-solutions--cloud">{{ solution.sector }}</span>
                </div>
                <p class="home-solutions--card-paragraph">{{ solution.description }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue'
    import { useMediaQuery } from '@vueuse/core'
  import { Swiper, SwiperSlide } from 'swiper/vue';

  interface Props {
  }

  const props = defineProps<Props>()
  const conf = ref([
    {
      solutionName: 'Sigma BI Rationalization & Migration',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/mamba.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/12/sigma-solutions-4.png',
      sector: 'Business Intelligence',
      wistiaId: '',
      description: 'Streamline report rationalization and supercharge your migration to Sigma’s modern BI analytics environment with a suite of AI-driven business intelligence solutions.',
      targer: 'https://hakkoda.io/3-phase-bi-rationalization-migration-with-hakkoda-ai/',
      class: 'gradient-4'
    },
    {
      solutionName: 'Mamba',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/mamba.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/mamba-solution-screen.png',
      sector: 'Business Analytics',
      wistiaId: 'kw2tn9fj5p',
      description: 'Accelerate speed to insight with realtime access to all your trades and assets in a single version of truth',
      targer: 'https://hakkoda.io/resources/mamba/',
      class: 'gradient-2'
    },
    {
      solutionName: 'Intelligent Agreements',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/intelligent_agreements.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Intelligent-agreements-screen.png',
      sector: 'Document Management',
      wistiaId: 'xib90it0te',
      description: 'Transform documents and other resources into conversational interfaces to streamline knowledge sharing and unlock organizational efficiencies.',
      targer: 'https://hakkoda.io/resources/intelligent-agreements/',
      class: 'gradient-3'
    },
    {
      solutionName: 'Snowflake Native MDM',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/snowflake.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Snowflake-Native-MDM-screen.png',
      sector: 'Data Governance',
      wistiaId: 'fsj2z9ao4k',
      description: 'Modularly enable data profiling, rule management, and exploration management within a Snowflake environment by capitalizing on Snowpark’s machine learning capabilities.',
      targer: 'https://hakkoda.io/resources/snowflake-native-mdm/',
      class: 'gradient-1'
    },
    {
      solutionName: 'Automated Query Performance Tester',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/automated_query_performance_tester.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Automated-Query-Performance-tester-screen.png',
      sector: 'Accelerated Migration',
      wistiaId: 'r3snjbo5dl',
      description: 'Run and execute large query batches automatically within Snowflake while generating insights on compile times, cluster size, and execution time.',
      targer: 'https://hakkoda.io/resources/automated-query-performance-tester/',
      class: 'gradient-4'
    },
    {
      solutionName: 'Price Transparency Dashboards',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/Hakkoda-Icons-1.svg',
      posterImage: 'https://hakkoda.io/wp-content/uploads/2024/11/Price-Transparency-Dashboards-screen.png',
      sector: 'Healthcare',
      wistiaId: 'r3snjbo5dl',
      description: 'Quickly and easily navigate price transparency files in Snowflake with a collection of descriptive analytics dashboards including inventory and supply information, services, and charges.',
      targer: 'https://hakkoda.io/resources/automated-query-performance-tester/',
      class: 'gradient-4'
    }
  ])
  const isLargeScreen = useMediaQuery('(min-width: 992px)')
  const active = ref(null)
  const swiperRef = ref(null)

  function onSwiper(swiperInstance) {
    swiperRef.value = swiperInstance
  }

  function next () {
    swiperRef.value.slideNext()
  }

  function prev () {
    swiperRef.value.slidePrev() 
  }

  function mouseOver(val) {
    active.value = val
  }

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .home-solutions {

    &--component {
      display: flex;
      width: 100%;
      flex-direction: column;
      max-width: 1440px;

      &-container {
        width: 100%;
        min-height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        padding: 20px;
      }

      .swiper-slide {
        width: 100%;
        max-width: 375px;

        @media (min-width: 768px) {
          max-width: 400px;
        }
      }

      .gradient-1 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #000 27.75%, #6A89B7 100%);
      }

      .gradient-2 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #000 27.75%, #F37121 100%);
      }

      .gradient-3 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #000 27.75%, #80EAFF 100%);
      }

      .gradient-4 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #000 27.75%, #6A89B7 100%);
      }

      @media (min-width: 992px) {

        &-container {
          padding: 20px 40px;
        }
      }
    }

    &--container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow-x: hidden;

      @media (min-width: 992px) {
        flex-direction: row;
      }
    }

    &--item {
      cursor: pointer;
    }

    &--heading {
      padding-top: 40px;
    }

    &--logo {
      height: 28px;
      object-fit: contain;
      width: 28px;
    }

    &--title {
      padding: 10px 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 576px) {
        flex-direction: row;
      }

      h2 {
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
      }
    }

    &--description {
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      h3 {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 180% */
      }

      &-paragraph {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        max-width: 100%;
      }

      @media (min-width: 768px) {
        &-paragraph {
          max-width: 50%;
        }
      }
    }

    &--card {

      &-title {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        padding: 10px 0;

        &-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          padding: 20px;
          position: absolute;
          top: 0;
        }
      }

      &-description {
        display: flex;
        flex-direction: column;
        min-height: 240px;
        padding: 20px;
        gap: 30px;

        h3 {
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px; /* 180% */
        }
      }

      &-paragraph {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
      }
    }

    &--subtitle {
      p {
        color: #21B0E2;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }
    }

    &--arrows {
      display: flex;
      gap: 40px;
      font-size: 30px;

      @media (min-width: 992px) {
        margin-right: 60px;
      }

      &-image {
        width: 36px;
        height: auto;
      }
    }

    &--poster {
      height: 369px;
      width: auto;
      object-fit: cover;
      position: relative;
      transition: transform .3s ease-out;
      bottom: -10px;
      width: 100%;

      &.active {
        transform: translateY(-10px);
      }

      &-container {
        overflow: hidden;
        margin-top: 90px;
      }
    }

    &--overlay {
      position: absolute;
      width: 100%;
      height: 150px;
      bottom: 239px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);

      &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 150px;
        align-items: end;
        padding: 40px 20px;

        a, i {
          color: #fff;
        }

        a {
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; 
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        img {
          width: 24px;
        }
      }
    }

    &--cloud {
      border-radius: 10px;
      background: #80EAFF;
      color: #000;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      text-transform: uppercase;
      padding: 4px 10px;
    }
  }
</style>