import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resource-card--wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "resource-card--info" }
const _hoisted_5 = { class: "resource-card--header" }
const _hoisted_6 = { class: "resource-card--meta" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "resource-card--date" }
const _hoisted_9 = { class: "resource-card--title" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "resource-card--excerpt" }
const _hoisted_12 = { class: "resource-card--footer" }
const _hoisted_13 = { class: "resource-card--tag" }
const _hoisted_14 = ["href"]
const _hoisted_15 = ["href", "title"]

import { ref, onMounted, onUnmounted } from 'vue'

  interface Props {
    title: String,
    permalink: String,
    thumbnail: String,
    date: String,
    excerpt: String,
    categoryName: String,
    categoryLink: String,
    tagsEncoded: String
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'resource-card',
  props: {
    title: {},
    permalink: {},
    thumbnail: {},
    date: {},
    excerpt: {},
    categoryName: {},
    categoryLink: {},
    tagsEncoded: {}
  },
  setup(__props: any) {

  const props = __props
  const tags = ref(JSON.parse(props.tagsEncoded) || null)

  onMounted( ()  => {
  })

  onUnmounted( () => {

  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("a", {
      class: "resource-card--thumbnail",
      href: `${ _ctx.permalink }`
    }, [
      _createElementVNode("img", {
        loading: "lazy",
        src: `${ _ctx.thumbnail }`,
        class: "resource-card--image",
        alt: "Thumbnail"
      }, null, 8, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", {
            class: "resource-card--category",
            href: `${ _ctx.categoryLink || '' }`
          }, _toDisplayString(_ctx.categoryName || ''), 9, _hoisted_7),
          _createElementVNode("time", _hoisted_8, _toDisplayString(_ctx.date), 1)
        ]),
        _createElementVNode("h3", _hoisted_9, [
          _createElementVNode("a", {
            href: `${ _ctx.permalink }`,
            class: "resource-card--title-link"
          }, _toDisplayString(_ctx.title), 9, _hoisted_10)
        ]),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.excerpt), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags.value, (tag) => {
            return (_openBlock(), _createElementBlock("a", {
              href: `${ tag?.link || '' }`,
              class: "resource-card--tag-link"
            }, _toDisplayString(tag?.name || ''), 9, _hoisted_14))
          }), 256))
        ]),
        _createElementVNode("a", {
          href: `${ _ctx.permalink }`,
          class: "resource-card--link",
          title: `${ _ctx.title }`
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "8",
            height: "13",
            viewBox: "0 0 8 13",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M1 12.3536L7 6.35361L1 0.353607",
              stroke: "black",
              "stroke-miterlimit": "10"
            })
          ], -1)
        ]), 8, _hoisted_15)
      ])
    ])
  ]))
}
}

})