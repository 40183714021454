<template>
  <div class="hkkd-hero">
    <div class="hero--container">
      <div id="homePagehero" class="carousel slide carousel-fade">
        <div class="carousel-indicators">
          <div class="carousel-indicators--ctrl">
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="0" class="carousel-indicators--btn active" aria-current="true" aria-label="Slide 0">
              <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/09/House.svg" class="carousel-indicators--btn-icon" alt="">
            </button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="1" class="carousel-indicators--btn" aria-current="true" aria-label="Migrate">Migrate</button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="2" class="carousel-indicators--btn" aria-label="Modernize">Modernize</button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="3" class="carousel-indicators--btn" aria-label="Monetize">Monetize</button>
          </div>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">

            <img 
              v-if="second <= 0 && !isVideoEnd"
              loading="lazy" 
              decoding="async" 
              src="https://hakkoda.io/wp-content/uploads/2024/10/Homepage_intro_v2.jpg" 
              class="hero--video-intro-image" 
              alt=""
            >

            <img 
              v-if="isVideoEnd"
              loading="lazy" 
              decoding="async" 
              src="https://hakkoda.io/wp-content/uploads/2024/10/video_backup.jpg" 
              class="hero--video-intro-image" 
              alt=""
            >

            <WistiaVideoPlayer
              v-if="!isVideoEnd"
              :video-id="'snn46mmm3v'" 
              :is-bg-video="true" 
              :auto-play="true" 
              :mute="isMute" 
              :replay="true" 
              :hide-controls="true" 
              @secondchange="onSecondChange"
              @end="onEnd"
            />

            <div  v-if="second > 0" class="hero--video-ctrl">
              <button v-if="!isMute" @click="muteVideo(true)">
                <i  class="fa-solid fa-volume-high"></i>
              </button>
              <button v-if="isMute" @click="muteVideo(false)">
                <i class="fa-solid fa-volume-xmark"></i>
              </button>
            </div>

            <div class="hero--animation">
              <div class="hero--animation-wrapper">
                <div class="d-flex">
                  <h2 
                    v-if="second >= 0.1 || isVideoEnd"
                    class="hero--heading p-l slide-top"
                    :class="{
                      'scale-down-center': second >= 28.7
                    }"
                  >We</h2>
                  <h2
                    v-if="second >= 0.1 || isVideoEnd"
                    class="hero--heading p-r slide-top"
                    :class="{
                      'scale-down-center': second >= 28.7
                    }"
                  >Power</h2>
                </div>
                <div class="d-flex">
                  <h2 
                    v-if="second >= 0.6 || isVideoEnd"
                    class="hero--heading p-l slide-top"
                    :class="{
                      'scale-down-center': second >= 28.7
                    }"
                  >Data</h2>
                  <h2
                    v-if="second >= 0.9 || isVideoEnd"
                    class="hero--heading p-r slide-top"
                    :class="{
                      'scale-down-center': second >= 28.7
                    }"
                  >Innovation</h2>
                </div>
                <div class="flex-column">
                  <h2
                    v-if="(second >= 2.3 && second <= 4.1) || isVideoEnd"
                    class="hero--heading p-f hero--black-bg slide-top" 
                  >So You Can Build The</h2>
                  <h2
                    v-if="(second >= 2.4 && second <= 4.1) || isVideoEnd"
                    class="hero--heading p-f hero--black-bg hero--accent slide-top" 
                  >Next Big Thing</h2>
                </div>
                <div 
                  v-if="second >= 4.2" 
                  class="hero--heading-container d-flex slide-top"
                  :class="{
                    'scale-down-center': second >= 28.7
                  }"
                >
                  <h2
                    class="hero--heading p-l hero--black-bg"
                  >With</h2>
                  <h2
                    v-if="second >= 4.3 && second <= 9.7"
                    class="hero--heading hero--black-bg hero--accent slide-rotate animated" 
                    >scalable</h2>
                  <h2
                    v-if="second >= 4.4 && second <= 9.7"
                    class="hero--heading p-r hero--black-bg hero--accent slide-rotate animated" 
                    >teams</h2>
                  <h2
                    v-if="second >= 9.8 && second <= 16"
                    class="hero--heading hero--black-bg hero--data-accent slide-rotate animated"
                  >data</h2>
                  <h2
                    v-if="second >= 9.9 && second <= 16"
                    class="hero--heading p-r hero--black-bg hero--data-accent slide-rotate animated"
                  >analytics</h2>
                  <h2
                    v-if="second >= 16.1 && second <= 22.1"
                    class="hero--heading hero--black-bg hero--ia-accent slide-rotate animated" 
                    >AI</h2>
                  <h2
                    v-if="second >= 16.2 && second <= 22.1"
                    class="hero--heading p-r hero--black-bg hero--ia-accent slide-rotate animated" 
                    >strategies</h2>
                  <h2
                    v-if="second >= 22.2"
                    class="hero--heading p-r hero--black-bg hero--purple-accent slide-rotate animated" 
                    >the modern</h2>
                  <h2
                    v-if="second >= 22.3"
                    class="hero--heading p-f hero--black-bg hero--purple-accent slide-rotate"
                    >data stack</h2>
                </div>
              </div>
              <div class="hero--ctas">
                <div 
                  v-if="second >= 4.3 || isVideoEnd"
                  class="hero--btn slide-top"
                  :class="{
                    'scale-down-center': second >= 28.7
                  }"
                >
                  <a class="btn-md btn-dark " href="https://hakkoda.io/services/" target="_self">Learn More</a>
                </div>
              </div>

            </div>
          </div>
          <div class="carousel-item">
            <img src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Migrate">
            <img src="https://hakkoda.io/wp-content/uploads/2024/01/Migrate_V004.webp" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Migrate">
            <div class="hero--caption">
              <h3>Seamless Data Migration:</h3>
              <h2>Elevate Your Business to New Heights</h2>
              <p>Hakkoda’s Data Migration service is more than just a one-time transfer to the cloud; it's a strategic intervention that allows you to rethink your business from the ground up by accelerating integration, streamlining operations, and transforming your data into your most powerful asset.</p>
              <div class="hero--ctas">
                <div class="hero--btn hero--btn-default" @click="onCustomerLogoClick('US Foods')">
                  <div class="hero--logo">
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/usfoods_logo.png" class="us-foods-logo" alt="">
                  </div>
                  <span class="btn-md">See the Story</span>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Modernize">
            <img src="https://hakkoda.io/wp-content/uploads/2024/01/Modernize_V004.webp" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Modernize">
            <div class="hero--caption">
              <h3>Empower Your Business with</h3>
              <h2>Data Modernization Excellence</h2>
              <p>Hakkoda’s Data Modernization solutions are crafted to empower your business in the digital age. We specialize in building and implementing future-proofed data strategies that cater to the dynamic needs of your business, freeing up your resources to focus on the next big thing.</p>
              <div class="hero--ctas">
                <div class="hero--btn hero--btn-default" @click="onCustomerLogoClick('Century')">
                  <div class="hero--logo">
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/century-1.png" class="century-logo" alt="">
                  </div>
                  <span class="btn-md">See the story</span>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Monetize">
            <img src="https://hakkoda.io/wp-content/uploads/2024/01/Monetize_V004.webp" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Monetize">
            <div class="hero--caption">
              <h3>Unleash the Potential:</h3>
              <h2>Data Monetization for Intelligent Growth</h2>
              <p>In today's data-driven world, your information is more than just a collection of numbers - it's a goldmine of opportunities waiting to be explored. Hakkoda's Data Monetization services empower you to securely create, share, and monetize feature rich applications on the Snowflake Data Cloud.</p>
              <div class="hero--ctas">
                <div class="hero--btn hero--btn-default" @click="onCustomerLogoClick('Blue Yonder')">
                  <div class="hero--logo">
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-1.png" class="blueyonder-logo" alt="">
                  </div>
                  <span class="btn-md">See the Story</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  const props = defineProps<Props>()
  const customerStore = useCustomerStore()

  const second = ref(0);
  const isMute = ref(true);
  const isReset = ref(false);
  const isVideoEnd = ref(false);

  function onSecondChange(s) {
    // console.log("secondChange", s)
    second.value = s;
  }

  function onEnd() {
    window.jQuery('#homePagehero').carousel('next')
    isVideoEnd.value = true
  }

  function muteVideo(val: boolean) {
    console.log('muteVideo', val);
    isMute.value = val;
  }

  function onCustomerLogoClick(val) {
    customerStore.$patch({
      'activeStory': { 'clientName' : val }
    })
  }

  onMounted( ()  => {
    const myCarousel = document.getElementById('homePagehero')
    myCarousel.addEventListener('slide.bs.carousel', event => {

    })
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  $component-height--mobile: calc(100vh - 120px);
  $component-height--desktop: calc(100vh - 80px);

  .carousel-item {
    height: $component-height--mobile;
    background: #000;

    &--bg {
      height: $component-height--mobile;
      object-fit: contain;
      object-position: right bottom;
    }
  }

  .carousel-indicators {
    top: 0;
    bottom: auto;
    margin-top: 20px;

    &--ctrl {
      display: flex;
      padding: 4px;
      background: #FFFFFF99;
      border-radius: 45px;
      gap: 8px;
      backdrop-filter: blur(3px);
      box-shadow: 3px 3px 30px 10px rgba(0,0,0,0.05);
    }

    &--btn {
      padding: 0 12px;
      cursor: pointer;
      width: auto;
      min-width: 40px;
      height: 45px;
      text-indent: 0;
      color: #000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      border-radius: 45px;
      background: transparent;
      border: 0;
      text-transform: capitalize;
      margin: 0;
      letter-spacing: 0;
      opacity: 1;

      &.active {
        background: #fff;
        transition: all 0.2s ease-in;
      }

      &:hover {
        transition: all 0.2s ease-in;
      }

    }

    &--icon {
      font-size: 18px;
    }
  }

  .hero {

    &--container {
      background: #000;
      height: $component-height--mobile;
    }

    &--video {

      &-intro-image {
        position: absolute;
        width: 100%;
        height: calc(100vh - 80px);
        object-fit: cover;
        object-position: center bottom;
        margin-top: -1px;
      }

      &-ctrl {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        
        button {
          padding: 0;
          border: 0;
          background-color: transparent;
          
          :hover,
          :focus
           {
            background-color: transparent;
            border: 0;
          }
        }

        i {
          width: 45px;
          width: 45px;
          padding: 13px 10px;
          font-size: 20px;
          background: hsla(0, 0%, 100%, .6);
          border-radius: 50%;
          backdrop-filter: blur(3px);
          box-shadow: 3px 3px 30px 10px rgba(0, 0, 0, .05);
          color: #555357;
        }
      }
    }

    &--caption {
      position: absolute;
      left: 20px;
      color: #fff;
      top: 60px;
      max-width: 495px;

      h2 {
        color: #00D5FF;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 20px;
      }

      h3 {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
      }

      p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &--animation {
      position: absolute;
      left: 20px;
      color: #fff;
      top: calc(10% + 20px);

      &-wrapper {
        min-height: 200px;
      }
    }

    &--heading {
      font-size: 28px;
      line-height: 32px;
      color: #000;
      background-color: rgb(255 255 255 / 90%);
      padding: 8px;
      width: fit-content;
      margin: 0;
      height: 48px;
      transition: all 0.2s ease-in allow-discrete;

      &-container {
        max-width: 400px;
        flex-wrap: wrap;
        
        h2 {
          padding: 8px 4px;
        }
      }

      &.animated {
        margin-top: 10px;
      }

      &.p-f {
        padding: 8px 12px;
      }

      &.p-l {
        padding: 8px 4px 8px 12px;
      }

      &.p-r {
        padding: 8px 12px 8px 4px;
      }
    }

    &--black-bg {
      color: #fff;
      background-color: rgb(0 0 0 / 90%);
    }

    &--accent {
      color: #00D5FF;
    }

    &--data-accent {
      color: #00e2da;
    }

    &--ia-accent {
      color: #ff774d;
    }

    &--purple-accent {
      color: #dbc3ff;
    }

    &--ctas {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
      min-height: 50px;
    }

    &--btn {
      margin-top: 20px;
      display: flex;
      align-items: center;
      background: #807C82;
      border-radius: 50px;

      .btn-md {
        border-radius: 50px;
        padding: 0 20px;
      }
    }

    &--btn-default {
      .btn-md {
        background: #00d5ff;
        color: #000;

        &:hover {
          background: #807C82;
          color: #fff;
        }
      }
    }

    &--logo {
      padding: 0 10px;

      .btn-md {
        display: block;
        text-align: left;
        height: auto;
      }

      img {
        width: auto;
        height: 15px;

        &.us-foods-logo {
          height: 25px;
          padding: 0 15px;
        }

        &.century-logo {
          //height: 30px;
          padding: 0 10px;
        }

        &.blueyonder-logo { 
          //height: 30px;
        }
      } 
    } 
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .carousel-item {
      height: $component-height--desktop;

      &--bg {
        height: $component-height--desktop;
      }
    }

    .carousel-indicators {

      &--ctrl {
        
      }

      &--btn {
        font-size: 16px;
        padding: 4px 40px;
      }
    }

    .hero {
      &--container {
        height: $component-height--desktop;
      }

      &--caption {
        top: 20%;
        left: 10%;

        h2 {
          font-size: 46px;
        }

        h3 {
          font-size: 28px;
        }

        p {
          font-size: 16px;
          min-height: 130px;
        }
      }
      
      &--animation {
        left: 40px;
        top: calc(15% + 100px);

        &-wrapper {
          min-height: 300px;
        }
      }

      &--heading {
        font-size: 48px;
        line-height: 54px;
      }

      &--heading {
        font-size: 48px;
        line-height: 54px;
        height: 70px;

        &-container {
          max-width: 600px;
        }
      }

      &--ctas {
      }

      &--btn {
      }

      &--logo {
      }
    }
  }

  /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      filter: opacity(1);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      filter: opacity(1);
    }
  }

  .slide-top {
    -webkit-animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }


    /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  @-webkit-keyframes slide-rotate {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      filter: opacity(1);
    }
  }

  @keyframes slide-rotate {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      filter: opacity(1);
    }
  }

  .slide-rotate {
    -webkit-animation: slide-rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
  * Generated by Animista on 2024-10-29 10:57:41
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation scale-down-center
  * ----------------------------------------
  */
  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(1);
    }
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0.5);
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(1);
    }
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0.5);
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0);
    }
  }

  .scale-down-center {
    -webkit-animation: scale-down-center 0.4s ease-out both;
    animation: scale-down-center 0.4s ease-out both;
  }


  /* ----------------------------------------------
 * Generated by Animista on 2024-11-22 0:2:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  10% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.slide-left {
	//-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	//animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  -webkit-animation: 3s infinite alternate slide-left;
  animation: 3s infinite alternate slide-left;
}
</style>