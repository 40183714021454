<template>
  <div
    v-if="renderComponent"
    class="customer-stories--component"
    :class="{
      'dark-theme': isDarkTheme,
      'embed': isEmbed
    }"
  >
    <div
      v-for="(story, index) in stories"
      class="customer-stories--container slide-top"
      :class="{
        'active': activeStory?.author == story?.author,
        'reverse': reverseLayout,
        'full-height-component--content': isFullHeight,
        'full-h': isFullHeight && width > 1000 && height > 1000,
        'embed': isEmbed
      }"
    >
      <div ref="tabContentRef" class="customer-stories--teaser">
        <div class="customer-stories--teaser-wrapper">
          <div class="customer-stories--content ">
            <h2
              v-if="!showInDropdown"
              class="customer-stories--heading"
            >Customer Stories</h2>
            <!-- dropdown -->
            <div
              v-if="showInDropdown && stories.length > 1"
              class="customer-stories--dropdown"
            >
              <label
                class="for-dropdown" 
                for="dropdown"
                :class="{
                  'open': checkedDropdown,
                }"
                @click="onDropdownClick"
              >
                <span>more Success stories</span>

                <!--<i 
                  v-if="!checkedDropdown"
                  class="fa-solid fa-square-caret-down"
                ></i>
                <i 
                  v-if="checkedDropdown"
                  class="fa-solid fa-square-caret-up"
                ></i>-->
                <div v-if="!checkedDropdown"  class="icon-caret-down-container">
                  <i class="icon-caret-down"></i>
                </div>
                <div v-if="checkedDropdown"  class="icon-caret-up-container">
                  <i class="icon-caret-up"></i>
                </div>
              </label>
              <div
                ref="dropdownRef"
                class="section-dropdown"
                :class="{
                  'open': checkedDropdown,
                }"
              >
                <div  v-for="(dStory) in stories" class="customer-stories--dropdown-item-container">
                  <div class="customer-stories--dropdown-item">
                    <a
                      v-if="activeStory.author != dStory.author"
                      href="#"
                      @click="onClick(dStory)"
                    >{{ dStory.clientName }} <i class="fa-solid fa-arrow-right"></i></a>
                  </div>
                </div>
                <!-- 
                <input class="dropdown-sub" type="checkbox" id="dropdown-sub" name="dropdown-sub"/>
                <label class="for-dropdown-sub" for="dropdown-sub">Healthcare <i class="fa-solid fa-plus"></i></label>
                <div class="section-dropdown-sub"> 
                  <a href="#">Story  <i class="fa-solid fa-arrow-right"></i></a>
                  <a href="#">Story  <i class="fa-solid fa-arrow-right"></i></a>
                </div> 
                -->
              </div>
            </div>
            <div 
              v-if="showInDropdown"
              class="customer-stories--dropdown-logo"
            >
              <img :src="`${ story.logo }`" :class="`${ story.customClass }`">
            </div>
            <!-- dropdown -->
            <p class="customer-stories--quote">{{ story.quote }}</p>
            <p class="customer-stories--author">{{ story.author }}</p>
            <p class="customer-stories--author-position">{{ story.authorPosition }}</p>
          </div>
          <div  v-if="story?.target" class="customer-stories--btn">
              <a 
                class="btn-md" 
                :href="`${ story.target }`"
              >Watch the full story</a>
          </div>
          <div 
            v-if="!showInDropdown"
            class="customer-stories--logos"
          >
            <div
              v-for="(cta, ctaIndex) in stories"
              class="customer-stories--logos-wrap"
              @click="onClick(cta)"
            >
              <img
                v-if="cta?.logo"
                :src="`${ cta.logo }`"
                :key="`${ ctaIndex }`"
                :class="{
                  'active': activeStory?.author == cta?.author
                }"
              >
              <span
                v-if="!cta?.logo"
                class="customer-stories--dot"
                :class="{
                  'active': activeStory?.author == cta?.author
                }"
              ></span>
            </div>

          </div>
        </div>
      </div>
      <div
        v-if="story?.poster || story?.video"
        class="customer-stories--media" 
        :id="`${ story?.video || index }`"
      >
        <div
          v-if="story?.poster"
          class="customer-stories--poster"
          :data-bs-target="`#customer-stories--modal-${ story?.video || index }`"
          data-bs-toggle="modal"
          :class="{
            'play-story': story?.video ? true : false
          }"
        >
          <img
            :src="`${ story.poster }`"
            :data-bs-target="`#customer-stories--modal-${ story?.video || index }`"
            data-bs-toggle="modal"
            class="customer-stories--image"
            :class="{
               'mobile': !isLargeScreen,
               'desktop': isLargeScreen
            }"
          >
        </div>
      </div>
    </div>
    <div
        v-if="activeStory?.poster || activeStory?.video"
        class="customer-stories--modal-container" 
        :id="`${ activeStory?.video }`"
      >
        <div
          v-if="activeStory?.video"
          class="customer-stories--modal modal fade" 
          :id="`customer-stories--modal-${ activeStory.video }`" 
          aria-hidden="true" 
          tabindex="-1"
        >
          <div
            v-if="renderComponent" 
            class="modal-dialog modal-dialog-centered modal-xl"
          >
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body customer-stories--video" >

                <WistiaVideoPlayer 
                  v-if="renderComponent" 
                  :video-id="activeStory.video" 
                  :auto-play="true"
                />

              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, useTemplateRef, onMounted, onUnmounted, watch, nextTick } from 'vue'
  import { useWindowSize, usePointerSwipe, useMediaQuery, onClickOutside, useTimeout } from '@vueuse/core'
  import Story from '../../models/story.model'
  import WistiaVideoPlayer from '../wistia-video-player/wistia-video-player.vue'
  import type { UseSwipeDirection } from '@vueuse/core'

  interface Props {
    storiesEncoded?: String, // Encoded Json
    storiesConf?: Story[],
    active: Story,
    reverse?: Boolean,
    showDropdown?: Boolean,
    sector?: String,
    isEmbed?: Boolean,
    isDarkTheme?: Boolean,
    isFullHeight?: Boolean,
    useSwipe?: Boolean
  }

  const props = defineProps<Props>()
    const emit = defineEmits<{
    (e: 'active', value: Story): void
  }>()

  const isLargeScreen = useMediaQuery('(min-width: 768px)')
  const width = ref(useWindowSize().width)
  const height = ref(useWindowSize().height)
  const activeStory = ref(props.active || null)
  const stories = ref(JSON.parse(props.storiesEncoded || null) || props.storiesConf || null)
  const reverseLayout = ref(props.reverse || false)
  const showInDropdown = ref(props.showDropdown || false)
  const checkedDropdown = ref(false)
  const isDarkTheme = ref(props.isDarkTheme || false)
  const isFullHeight = ref(props.isFullHeight || false)
  const renderComponent = ref(true);
  const tabContentRef = ref<HTMLElement | null>(null)
  const { distanceX, distanceY, isSwiping } = usePointerSwipe(tabContentRef, {
    onSwipeEnd(e: PointerEvent, direction: UseSwipeDirection) {
      console.log('usePointerSwipe', props.useSwipe , e, direction )

      if ( !props.useSwipe ) { 
        return 
      } 

      const index = stories.value.findIndex( (story: Story) => story.quote == activeStory.value.quote );

      console.log('usePointerSwipe', index, e, direction )

      if( direction == 'left' && index < stories.value.length - 1 ) {
        const next = index + 1
        activeStory.value = stories.value[ next ]
      }

      if( direction == 'left' && index == stories.value.length - 1 ) {
        const next = 0 // first
        activeStory.value = stories.value[ next ]
      }

      if( direction == 'right' && index == 0 ) {
        const prev = stories.value.length - 1 // last
        activeStory.value = stories.value[ prev ]
      }

      if( direction == 'right' && index > 0 && index < stories.value.length ) {
        const prev = index - 1
        activeStory.value = stories.value[ prev ]
      }

      if( direction == 'up') {
        console.log();

        window.scroll({
          top: window.top.scrollY + Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      if( direction == 'down') {
        window.scroll({
          top: window.top.scrollY - Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }
    }
  })
  const dropdownRef = ref(null)
  const { ready, start } = useTimeout(300, { controls: true })

  onClickOutside(
    dropdownRef,
    (event) => {
      if(checkedDropdown.value && ready.value) {
        start()
        checkedDropdown.value = false
      }
    }
  )

  watch( () => props.storiesConf,  (data: Story[], prev) => {
    if(
      data.every( (e: Story) => e.author != activeStory.value?.author)
    ) {
      //forceRender()
      stories.value = data
      activeStory.value = data[0]
    }
  })

  watch( () => props.active,  (data: Story, prev) => {
    if (data?.author != activeStory.value?.author) {
      //console.log("watch active",data)
      checkedDropdown.value = false
      activeStory.value = data
    }
  })

  watch( () => activeStory.value,  (data: Story, prev) => {
    forceRender();
  })

  onMounted( ()  => {
    if( !activeStory.value && stories.value?.length) {
      activeStory.value = stories.value[0]
    }
  })

  function onClick(story: Story) {
    // close dropdown
    checkedDropdown.value = false
    activeStory.value = story
    emit('active', story)
  }

  function onDropdownClick() {
    if(ready.value) {
      start()
      checkedDropdown.value = checkedDropdown.value ? false : true
    }
  }

  const forceRender = async () => {
    if(renderComponent?.value) {
      renderComponent.value = false;
      await nextTick();
      renderComponent.value = true;
    }
  }

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  $primary-font-color:  #FF9AC7;

  .customer-stories {

    &--component {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #362757;

      &.dark-theme {
        background-color: transparent;

       .customer-stories--author {
        color: #00D5FF;
       }

        .customer-stories--btn a {
          background: #00D5FF;
          color: #000;

          &:hover {
            background: #555357;
            color: #fff;
          }
        }

        .customer-stories--container {
          background: #000;
          border: 1px solid #555357;
        }

        .customer-stories--poster.play-story:after {
          background: url('https://hakkoda.io/wp-content/uploads/2024/10/play-icon-default.svg');
          background-color: hsla(0, 0%, 100%, .5);
        }
      }

      &.embed {
        padding: 20px;

        @media (min-width: 768px) {
          padding: 20px 40px;
        }
      }
    }

    &--container {
      flex-flow: column;
      width: 100%;
      display: none;

      &.active {
        display: flex;

      }

      &.reverse {
        flex-direction: column-reverse;

        @media (min-width: 768px) {
          flex-direction: row-reverse;
        }
      }

      &.embed {
        max-width: 1400px;

        img.mobile {
        }

        img.desktop {
        }
      }

      @media (min-width: 992px) {
        flex-flow: row;
      }
    }

    &--content {
      min-height: 370px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (min-width: 992px) { 
        min-height: 475px;
      }
    }

    &--teaser,
    &--media {
      flex: 1;
    }

    &--teaser {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &-wrapper {
        flex-direction: column;
        padding: 40px 20px;
      }

      @media (min-width: 1200px) {
        &-wrapper {
          padding: 40px 40px;
        }
      }
    }

    &--heading {
      color: #FF9AC7;
      font-size: 24px;
      text-align: left;
      width: 100%;
    }

    &--dropdown {
      position: relative;
      width: 100%;
      padding: 10px 0 20px;

      &-logo {
        display: flex;
        width: 100%;

        img {
          width: auto;
          height: 85px;
          padding: 10px 0;
          filter: invert(1);

          &.bigger {
            height: 70px;
          }
        }
      }

      &-item {

        &-container {
          border-bottom: 1px solid #807C82;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .icon-caret-down {

        &-container {
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #00D5FF;
        }

        &:before{
          content:"▼";
          font-family:'arial';
          speak:none;
          font-style:normal;
          font-weight:normal;
          font-variant:normal;
          text-transform:none;
          line-height:1;
          -webkit-font-smoothing:antialiased;
          vertical-align: baseline;
          background-position: 0 0;
          background-repeat: repeat;
          margin-top: 0;
          color: #555357;
          width: 10px;
          height: 9px;
        }
      }

      .icon-caret-up {

        &-container {
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #00D5FF;
        }

        &:before{
          content:"▲";
          font-family:'arial';
          speak:none;
          font-style:normal;
          font-weight:normal;
          font-variant:normal;
          text-transform:none;
          line-height:1;
          -webkit-font-smoothing:antialiased;
          vertical-align: baseline;
          background-position: 0 0;
          background-repeat: repeat;
          margin-top: 0;
          color: #555357;
          width: 10px;
          height: 9px;
        }
      }

      [type="checkbox"]:checked,
      [type="checkbox"]:not(:checked){
        position: absolute;
        opacity: 0;
      }

      label {
        cursor: pointer;
        color: #00D5FF;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #00D5FF;
        letter-spacing: 1px;
        text-transform: uppercase;
        align-items: center;
        
        .fa-solid {
          transition: all 200ms linear;
          color: #00D5FF;
          font-size: 24px;
        }
      }

      .section-dropdown.open {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        padding: 0;
      }

      .section-dropdown {
        position: absolute;
        padding: 5px;
        background-color: #242424;
        top: 48px;
        left: 0;
        width: 100%;
        border-radius: 4px;
        display: block;
        box-shadow: 0 14px 35px 0 #444444;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transform: translateY(20px);
        transition: all 100ms linear;

        /*&:after {
          position: absolute;
          top: -7px;
          left: 30px;
          width: 0; 
          height: 0; 
          border-left: 8px solid transparent;
          border-right: 8px solid transparent; 
          border-bottom: 8px solid #242424;
          content: '';
          display: block;
          z-index: 2;
          transition: all 200ms linear;
        }*/
      }

      a {
        display: flex;
        justify-content: space-between;
        color: #00D5FF;
        transition: all 200ms linear;
        border-radius: 2px;
        padding: 16px 10px;
        text-align: left;
        text-decoration: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;

        &:hover {
          color: #000;
          background-color: #00D5FF;
        }
      }
    }

    &--quote {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #fff;
      padding: 15px 0;
      text-align: left;
      width: 100%;

      @media (min-width: 1200px) { 
        font-size: 20px;
        line-height: 28px;
      }
    }

    &--author {
      font-size: 16px;
      font-weight: 500;
      line-height: 0;
      color: #fff;
      text-align: left;
      width: 100%;
      font-family: Space Grotesk;
      text-transform: uppercase;
      margin-top: 20px;

      &-position {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #fff;
        text-align: left;
        width: 100%;
        font-family: Space Grotesk;
        text-transform: uppercase;
      }
    }

    &--btn {
      padding: 20px 0;
      width: 100%;

      a {
        background: linear-gradient(45deg, #d62e79, #b887ff);
        color: #fff;

        &:hover {
          background: linear-gradient(45deg,  #b887ff, #d62e79);
        }
      }
    }

    &--logos {
      display: flex;
      flex-flow: row wrap;
      margin-top: 30px;

      &-wrap {
        //border-radius: 10px;
        padding: 5px;

        img {
          box-shadow: none;
          opacity: 0.3;
          height: 50px;
          width: auto;
        
          &.active,
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &--dot {
      display: block;
      padding: 5px;
      height: 12px;
      width: 12px;
      background: #000;
      border-radius: 50%;

      &.active,
      &:hover {
        background: #FFB199;
      }
    }

    &--media {
      video {
        width: 100%;
      }
    }

    &--modal {
      // overflow: hidden;
    }

    &--poster {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;

      &.play-story:after {
        background: url('https://hakkoda.io/wp-content/uploads/2024/10/play-icon-black.svg');
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
        width: 95px;
        height: 95px;
        background-size: cover;
        content: '';
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        padding: 20px 25px;
        backdrop-filter: blur(3px);
      }

      &:hover {
        &.play-story:after {
          background-color: hsla(0, 0%, 100%, 1);
        }
      }
    }

    &--image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &--video {
    }
  }

  .modal-header {
    padding: 15px;
    border: 0;
  }

  .modal-body {
    padding: 0 20px 30px 20px;
  }

  .play-icon-none {
    position: relative;
    bottom: 50%;
    left: 50%;
    font-size: 50px;
  }


 /**
  /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  @-webkit-keyframes slide-top {
    0% {
      filter: opacity(0);
    }
    100% {
      filter: opacity(1);
    }
  }

  @keyframes slide-top {
    0% {

      filter: opacity(0);
    }
    100% {
      filter: opacity(1);
    }
  }

  .slide-top {
    -webkit-animation: slide-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

</style>