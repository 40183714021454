<template>
  <div
    v-show="!hideVideo"
    class="wistia-video-player--component"
  >

      <script :src="`https://fast.wistia.com/embed/medias/${ videoId }.jsonp`"  async></script>
      <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      <div 
        class="wistia_responsive_wrapper" 
      >
        <div 
          :class="`wistia_embed wistia_async_${ videoId } 
            seo=${ true } 
            videoFoam=${ false }
            autoPlay=${ autoPlay || false } 
            controlsVisibleOnLoad=${ hideControls ? false : true } 
            fullscreen=${ fullScreen || false } 
            fullscreenButton=${ hideControls ? false : true } 
            playbackRateControl=${ hideControls ? false : true } 
            playbar=${ hideControls ? false : true } 
            playButton=${ hideControls ? false : true } 
            settingsControl=${ hideControls ? false : true } 
            volumeControl=${ hideControls ? false : true } 
            captionsVisible=${ false } 
            muted=${ false } 
            wmode=${ 'transparent' } 
            ${ hideControls ? 'hide-ctrls' : '' } 
            ${ isBgVideo ? 'wistia-bg-video' : 'wistia-inline-video' } 
            ${ preload ? 'preload=auto' : '' }`"
        ></div>
      </div>

  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, watch } from 'vue'
  import { loadScript } from  '../../tools/index'

  interface Props {
    videoId: String,
    autoPlay?: Boolean,
    replay?: Boolean,
    mute?: Boolean,
    hideControls?: Boolean,
    hideVideo?: Boolean,
    isBgVideo?: Boolean,
    playVideo?: Boolean,
    pauseVideo?: Boolean,
    fullScreen?: true,
    preload?: Boolean
  }

  const props = defineProps<Props>()
  const emit = defineEmits<{
    (e: 'secondchange', value: number): void
    (e: 'end', value: any): void
    (e: 'paused', value: any): void
    (e: 'closed', value: any): void
    (e: 'cancelfullscreen', value: any): void
  }>()

  watch( () => props.mute,  (val, prev) => {
      window._wq.push({
        id: props.videoId, 
        onReady: function(video) {
          if( !val ) {
            video.volume(1)
            video.unmute() 
            window.jQuery(`.wistia_async_${props.videoId} video`).removeAttr('muted') // remove unmute from video safari
          } else {
            video.mute()
          }
        }
      });
  })

  watch( () => props.playVideo,  (val, prev) => {
      window._wq.push({
        id: props.videoId, 
        onReady: function(video) {

          if( val ) {
            video.play()
          }
        }
      });
  })

  watch( () => props.pauseVideo,  (val, prev) => {
      window._wq.push({
        id: props.videoId, 
        onReady: function(video) {

          if( val ) {
            video.pause()
            emit('paused', video)
          }
        }
      });
  })

  const loadWistiaVideo = (conf: Props) => {
    loadScript('https://fast.wistia.com/assets/external/E-v1.js', () => {
      window._wq.push({
        id: conf.videoId, 
        options: {
          plugin: {
            "captions-v1": {
              onByDefault: false
            }
          }
        },
        onReady: function(video) {
          // console.log("wistia-video-player", video, conf)

          video.volume(1)

          if(!!conf.preload){
            
          }

          if(!!conf.autoPlay){
            video.play()
          }

          if(!!conf.mute){
            video.mute()
          }

          if(!!conf.fullScreen){
            video.requestFullscreen()
          }

          video.bind("timechange", function() {
            //console.log("timechange", video.time())
            emit('secondchange', video.time())
          });

          video.bind("cancelfullscreen", function() {
            emit('cancelfullscreen', video.time())
          });

          video.bind("end", function(t) {
            if(!!conf.replay){
              video.time(0)
              video.play()
            }
            emit('end', t)
          })
        }
      });
    })
  }

  onMounted( ()  => {
    // console.log('wistia', props)

    loadWistiaVideo(props)
  })

  onUnmounted( () => {

  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wistia-video-player--component {


}
</style>