import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "ia-differentiators--component"
}
const _hoisted_2 = { class: "ia-differentiators--tabs d-none" }
const _hoisted_3 = { class: "ia-differentiators--tabs-container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "ia-differentiators--dots-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "carousel-inner" }
const _hoisted_8 = { class: "ia-differentiators--image d-flex justify-content-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "ia-differentiators--content-container" }
const _hoisted_11 = { class: "ia-differentiators--title" }
const _hoisted_12 = { class: "ia-differentiators--description" }

import { ref, onMounted, onUnmounted } from 'vue'
  import Flicking from "@egjs/vue3-flicking"
  import type { UseSwipeDirection } from '@vueuse/core'
  import { usePointerSwipe } from '@vueuse/core'

  interface Props {
  }

  interface tabConf {
    label: String,
    image: String,
    title: String,
    description: String
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'differentiators-tabs',
  setup(__props: any) {

  const props = __props
  const activeTab = ref<tabConf>(null)
  const conf = ref([
    {
      label: 'Experience that Scales',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Experience-that-Scales.png',
      title: 'Experience that Scales',
      description: 'Migrations are complicated, multi-stakeholder events. Align your team with the aid of experts with decades of experience in your industry who understand your goals and pain points because they’ve been in your shoes. Scale up and accelerate your migration with AI copilots designed to speed up the process without sacrificing quality control.'
    },
    {
      label: 'Say Goodbye to Manual Slow Down',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Say-Goodbye-to-Manual-Slow-Down.png',
      title: 'Say Goodbye to Manual Slow Down',
      description: 'Leverage AI-powered solutions that eliminate costly manpower and the risk of human error, as well as custom accelerators built with your industry’s top challenges in mind.'
    },
    {
      label: 'Test & Review All in One place',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Test-Review-All-in-One-place.png',
      title: 'Test & Review All in One place',
      description: 'Execute queries, test, iterate, improve, and compile metrics. All in one place, with automation for support.'
    },
    {
      label: 'Govern & Grow with Your Org',
      image: 'https://hakkoda.io/wp-content/uploads/2024/10/Govern-Grow-with-Your-Org.png',
      title: 'Govern & Grow with Your Org',
      description: 'Create access, role-based controls, and the framework you need to enable users across every department in your organization. Leverage AI-powered accelerators for smarter, more scalable data governance and master data management.'
    }
  ])
  const tabsLabel = ref<String[]>(
    conf.value.map<String>( e => e.label ).filter((value, index, self) => self.indexOf(value) === index)
  )
  //
  const tabLabelRef = ref<HTMLElement | null>(null)
  const tabContentRef = ref<HTMLElement | null>(null)
  const { distanceX, distanceY, isSwiping } = usePointerSwipe(tabContentRef, {
    onSwipeEnd(e: PointerEvent, direction: UseSwipeDirection) {
      const index = tabsLabel.value.findIndex( label => label == activeTab.value.label );

      // console.log('usePointerSwipe',index)
      isSwiped.value = true

      if( direction == 'left' && index < tabsLabel.value.length - 1 ) {
        const next = index + 1
        tabLabelRef.value.moveTo( next )
        activeTab.value = conf.value[ next ]
      }

      if( direction == 'left' && index == tabsLabel.value.length - 1 ) {
        const next = 0 // first
        activeTab.value = conf.value[ next ]
        tabLabelRef.value.moveTo( next )
      }

      if( direction == 'right' && index == 0 ) {
        const prev = tabsLabel.value.length - 1 // last
        activeTab.value = conf.value[ prev ]
        tabLabelRef.value.moveTo( prev )
      }

      if( direction == 'right' && index > 0 && index < tabsLabel.value.length ) {
        const prev = index - 1
        activeTab.value = conf.value[ prev ]
        tabLabelRef.value.moveTo( prev )
      }

      if( direction == 'up') {
        window.scroll({
          top: window.top.scrollY + Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      if( direction == 'down') {
        window.scroll({
          top: window.top.scrollY - Math.abs(distanceY.value),
          left: 0,
          behavior: "smooth",
        });
      }

      
      setTimeout(() => {
        isSwiped.value = false
      }, 100)
    }
  })
  const isSwiped = ref(false)

  onMounted( ()  => {
    activeTab.value = conf.value[0]

    const myCarousel = document.getElementById('ia-differentiators--carousel')
    myCarousel.addEventListener('slide.bs.carousel', event => {
      if ( !isSwiped.value ) {
        activeTab.value = conf.value[event?.to || 0]
      }
    })
  })

  function onTabClick(val) {
    const tab: tabConf = conf.value.find((e) => e.label == val)
    activeTab.value = tab
  }
  onUnmounted( () => {

  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Flicking), {
          ref_key: "tabLabelRef",
          ref: tabLabelRef,
          class: "flicking",
          options: { moveType: 'freeScroll', horizontal: true, circular: false, bound: true, align: 'prev', autoInit: true }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsLabel.value, (label, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["ia-differentiators--tabs-label", {
              'active': activeTab.value?.label == label
            }]),
                key: index,
                onClick: ($event: any) => (onTabClick(label))
              }, [
                _createElementVNode("p", null, _toDisplayString(label), 1)
              ], 10, _hoisted_4))
            }), 128))
          ]),
          _: 1
        }, 512)
      ])
    ]),
    _createElementVNode("div", {
      ref_key: "tabContentRef",
      ref: tabContentRef,
      id: "ia-differentiators--carousel",
      class: "carousel slide carousel-fade",
      "data-bs-ride": "carousel"
    }, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsLabel.value, (label, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["ia-differentiators--dots", {
              'active': activeTab.value?.label == label
            }]),
            key: index,
            onClick: ($event: any) => (onTabClick(label))
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "ia-differentiators--dots-icon" }, null, -1)
          ]), 10, _hoisted_6))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.value, (tab, tabIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["ia-differentiators--content carousel-item", {
            'active': activeTab.value?.label == tab.label
          }]),
            "data-bs-interval": "5000",
            "data-bs-touch": "false"
          }, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(), _createElementBlock("img", {
                src: `${ tab.image }`,
                key: `${ tabIndex }`
              }, null, 8, _hoisted_9))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("h3", null, _toDisplayString(tab.title), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", null, _toDisplayString(tab.description), 1)
              ])
            ])
          ], 2))
        }), 256))
      ])
    ], 512)
  ], 512))
}
}

})