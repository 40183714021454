import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h3 form-title"
}
const _hoisted_2 = {
  key: 2,
  class: "h3 form-title"
}

import { ref, onMounted, onUnmounted } from 'vue'
  import { FormInfo, useFormStore } from '../store/forms'
  import HubspotForm from '../components/hubspot-form/hubspot-form.vue'

  interface Props {
    title: String
    portalId: String
    formId: String
    videoId: String
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'webinar-form',
  props: {
    title: {},
    portalId: {},
    formId: {},
    videoId: {}
  },
  setup(__props: any) {

  const props = __props
  const store = useFormStore()
  const title = ref(props.title)
  const formId = ref(props.formId)
  const hideForm = ref(false) 

  onMounted( ()  => {
    const isFormSubmited = localStorage.getItem(formId.value);

    if(isFormSubmited) {
      hideForm.value = true;
    }

    if (typeof props?.videoId === 'string' && props?.videoId.length > 0) {
      title.value = 'Watch Now';
    }

    store.$subscribe( (mutation, state) => {
      const submitedForm: FormInfo = state?.submitedForm

      if(submitedForm?.submitedEmail && submitedForm.formId == props.formId) {
        hideForm.value = true;
      }
    })
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!hideForm.value)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(title.value || 'Register Now'), 1))
      : _createCommentVNode("", true),
    (!hideForm.value)
      ? (_openBlock(), _createBlock(HubspotForm, {
          key: 1,
          "portal-id": _ctx.portalId,
          "form-id": formId.value
        }, null, 8, ["portal-id", "form-id"]))
      : _createCommentVNode("", true),
    (hideForm.value)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Thank you for registering!"))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "policy-text" }, [
      _createTextVNode(" When you share your details here, we’ll use them to stay in touch. We may contact you from time to time to tell you about our services. You can opt out at any time by clicking ‘unsubscribe’ in our email. Read our "),
      _createElementVNode("a", {
        href: "https://hakkoda.io/privacy-policy/",
        target: "_blank"
      }, "Privacy Policy"),
      _createTextVNode(" to learn more. ")
    ], -1))
  ], 64))
}
}

})