import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "home-industries--component-container"
}
const _hoisted_2 = { class: "home-industries--component" }
const _hoisted_3 = { class: "home-industries--sector-container" }
const _hoisted_4 = { class: "home-industries--list" }
const _hoisted_5 = { class: "home-industries--panel" }
const _hoisted_6 = ["onMouseover"]
const _hoisted_7 = { class: "home-industries--sector-headline" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "home-industries--video-container"
}

import { ref, onMounted, onUnmounted, computed } from 'vue'
  import { useMediaQuery } from '@vueuse/core'
  import Industry from '../../../models/industry.model'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'industries_v3',
  setup(__props: any) {

  const props = __props
    const isLargeScreen = useMediaQuery('(min-width: 992px)')
  const conf = ref<Industry[]>([
    {
      name: 'Healthcare & Life Sciences',
      description: 'Quality healthcare requires quality data, serviced by expert teams of data scientists, engineers, and technologists with hands-on experience at the world’s largest healthcare organizations.',
      target: 'https://hakkoda.io/healthcare/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/healthcare.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/HLS_image.webp'
    },
    {
      name: 'Financial Services',
      description: 'Our experienced financial services technologists can help you maximize your data to streamline operations, improve your customer experience, and tap into new revenue sources.',
      target: 'https://hakkoda.io/financial-services/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/financial.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/FSI_image.webp'
    },
    {
      name: 'Public Sector',
      description: 'Securely deploy and assetize your organization’s data with tools & capabilities embraced by the commercial sector, backed by our teams of public sector data leaders.',
      target: 'https://hakkoda.io/public-sector/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/public-sector.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/PS_image_v3.webp'
    },
    {
      name: 'Supply Chain & Logistics',
      description: 'Effective supply chain management relies on high-quality data to streamline operations, reduce costs, and improve responsiveness to supply and demand volatility. The right blend of data tooling and talent is crucial for ensuring data accuracy, managing risk, and delivering actionable insights that drive efficiency and agility in complex logistics networks.',
      target: 'https://hakkoda.io/supply-chain-logistics/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/supply-chain.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/09/logistics.jpg'
    },
    {
      name: 'Retail & CPG',
      description: 'Hone your sales and marketing strategies with powerful CDP platforms that unify data across the customer lifecycle with ERP analytics and other critical sources. Empower end-to-end enterprise analytics to enrich demand forecasting, optimize inventory management, deliver bespoke ad campaigns, and respond to market changes faster than the competition.',
      target: 'https://hakkoda.io/retail-cpg/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/retail.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/10/Retail-CPG-2-1024x1024.jpg'
    }
  ])
  const activeIndustry = ref<Industry>(conf.value[0])

  function setActiveSector(val: Industry ){
    activeIndustry.value = val
  }

  function onSecondChange(s) {
    if(s >= 0 && s <= 2.5 && activeIndustry.value != conf.value[0]) {
      activeIndustry.value = conf.value[0]
    }
    if(s >= 2.6 && s <= 5.5 && activeIndustry.value != conf.value[1]) {
      activeIndustry.value = conf.value[1]
    }
    if(s >= 5.6 && s <= 7.5 && activeIndustry.value != conf.value[2]) {
      activeIndustry.value = conf.value[2]
    }
    if(s >= 7.6 && s <= 10.5 && activeIndustry.value != conf.value[3]) {
      activeIndustry.value = conf.value[3]
    }
    if(s >= 10.6 && s <= 14.5 && activeIndustry.value != conf.value[4]) {
      activeIndustry.value = conf.value[4]
    }
  }

  function onLogoClick(src) {
    const story: Story = stories.value.find((e) => e.logo == src)
    onActive(story)
  }

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "home-industries--subtitle" }, [
          _createElementVNode("p", null, "Industries")
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "home-industries--title" }, [
          _createElementVNode("h2", null, "We’ve Been in Your Chair."),
          _createElementVNode("h2", null, "Now, We’re in Your Corner.")
        ], -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "home-industries--description" }, [
          _createElementVNode("p", { class: "home-industries--description-paragraph" }, "Modern data solutions aren’t built on technical expertise alone. Our industry teams understand your business and come to the table with decades of experience driving practical results in complex and highly regulated industries.")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.value, (sectorVal, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["home-industries--sector", {
              'active': activeIndustry.value?.name == sectorVal?.name
            }]),
                key: index,
                onMouseover: ($event: any) => (setActiveSector(sectorVal))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("a", {
                    href: `${ sectorVal.target }`
                  }, [
                    _withDirectives((_openBlock(), _createElementBlock("img", {
                      class: "svg-white",
                      src: `${ sectorVal.logo }`,
                      key: `${ index }`
                    }, null, 8, _hoisted_9)), [
                      [_vShow, activeIndustry.value?.name != sectorVal?.name]
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("img", {
                      class: "svg-white",
                      src: "https://hakkoda.io/wp-content/uploads/2024/11/solution-rigth-arrow.svg",
                      key: `${ index }`
                    })), [
                      [_vShow, activeIndustry.value?.name == sectorVal?.name]
                    ]),
                    _createElementVNode("h3", {
                      class: _normalizeClass(["home-industries--sector-name", {
                    'active': activeIndustry.value?.name == sectorVal?.name
                  }])
                    }, _toDisplayString(sectorVal?.name), 3)
                  ], 8, _hoisted_8)
                ])
              ], 42, _hoisted_6))
            }), 128))
          ])
        ])
      ]),
      (_unref(isLargeScreen))
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(WistiaVideoPlayer, {
              "video-id": '6wffy792pl',
              "auto-play": true,
              mute: true,
              replay: true,
              "hide-controls": true,
              "preload:": "true"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}
}

})