import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "home-industries--component full-height-component"
}
const _hoisted_2 = { class: "home-industries--container padding-1" }
const _hoisted_3 = { class: "home-industries--list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "home-industries--accordion" }
const _hoisted_6 = { class: "home-industries--accordion-headline" }
const _hoisted_7 = { class: "home-industries--container" }
const _hoisted_8 = { class: "home-industries--container-circle" }
const _hoisted_9 = { class: "hero-circle" }
const _hoisted_10 = {
  class: "hero-rotate",
  id: "circle"
}
const _hoisted_11 = ["src", "alt"]

import { ref, onMounted, onUnmounted, computed } from 'vue'
  import Industry from '../../../models/industry.model'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'industries_v1',
  setup(__props: any) {

  const props = __props
  const conf = ref<Industry[]>([
    {
      name: 'Healthcare & Life Sciences',
      description: 'Quality healthcare requires quality data, serviced by expert teams of data scientists, engineers, and technologists with hands-on experience at the world’s largest healthcare organizations.',
      target: 'https://hakkoda.io/healthcare/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/HLS_image.webp'
    },
    {
      name: 'Financial Services',
      description: 'Our experienced financial services technologists can help you maximize your data to streamline operations, improve your customer experience, and tap into new revenue sources.',
      target: 'https://hakkoda.io/financial-services/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/FSI_image.webp'
    },
    {
      name: 'Supply Chain & Logistics',
      description: 'Effective supply chain management relies on high-quality data to streamline operations, reduce costs, and improve responsiveness to supply and demand volatility. The right blend of data tooling and talent is crucial for ensuring data accuracy, managing risk, and delivering actionable insights that drive efficiency and agility in complex logistics networks.',
      target: 'https://hakkoda.io/supply-chain-logistics/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/09/logistics.jpg'
    },
    {
      name: 'Public Sector',
      description: 'Securely deploy and assetize your organization’s data with tools & capabilities embraced by the commercial sector, backed by our teams of public sector data leaders.',
      target: 'https://hakkoda.io/public-sector/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/PS_image_v3.webp'
    },
    {
      name: 'Retail & CPG',
      description: 'Hone your sales and marketing strategies with powerful CDP platforms that unify data across the customer lifecycle with ERP analytics and other critical sources. Empower end-to-end enterprise analytics to enrich demand forecasting, optimize inventory management, deliver bespoke ad campaigns, and respond to market changes faster than the competition.',
      target: 'https://hakkoda.io/retail-cpg/',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/10/Retail-CPG-2-1024x1024.jpg'
    }
  ])
  const activeIndustry = ref<Industry>(conf.value[0])

  function setActivePanel(val: Industry ){
    activeIndustry.value = val
  }

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "home-industries--subtitle" }, [
        _createElementVNode("p", null, "Industries")
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "home-industries--title" }, [
        _createElementVNode("h2", null, "Custom Solutions for Complex Industries")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.value, (industryVal, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["home-industries--panel", {
            'active': activeIndustry.value?.name == industryVal?.name
          }]),
            key: index,
            onClick: ($event: any) => (setActivePanel(industryVal))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-solid fa-square" }, null, -1)),
                _createElementVNode("h3", null, _toDisplayString(industryVal?.name), 1)
              ])
            ]),
            _createElementVNode("p", null, _toDisplayString(industryVal?.description), 1)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "home-industries--sun" }, [
            _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/11/Hkkd.svg",
              alt: ""
            })
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conf.value, (industryVal, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["planet", {
                'active': activeIndustry.value?.name == industryVal?.name
              }])
              }, [
                _createElementVNode("img", {
                  src: `${ industryVal.poster }`,
                  alt: `${ industryVal.name }`
                }, null, 8, _hoisted_11)
              ], 2))
            }), 256))
          ])
        ])
      ])
    ])
  ], 512))
}
}

})