<template>
  <wistia-video-player 
    :video-id="videoId"
    :auto-play="true"
    :hideVideo="hideVideo"
  />
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, nextTick } from 'vue'
  import { mapActions, mapState } from 'pinia'
  import { FormInfo, useFormStore } from '../../store/forms'
  import WistiaVideoPlayer from './wistia-video-player.vue'

  interface Props {
    videoId: String,
    hubspotFormId: String
  }

  const props = defineProps<Props>()
  const store = useFormStore()
  const hideVideo = ref(true)
  const formId = ref(props.hubspotFormId)

  onMounted( ()  => {
    const isFormSubmited = localStorage.getItem(formId.value);
    
    if(isFormSubmited) {
      hideVideo.value = false;
    }

    store.$subscribe( (mutation, state) => {
      const submitedForm: FormInfo = state?.submitedForm

      if(submitedForm?.submitedEmail && submitedForm.formId == formId.value) {
        hideVideo.value = false;
        localStorage.setItem(submitedForm.formId, true)
        setTimeout(() => {
          const element = document.querySelector('.wistia-video-player--component')
          element.scrollIntoView({ behavior: "smooth", block: "end" })
        }, 500);
      }
    })
  })
</script>