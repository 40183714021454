<template>
  <div ref="component" class="home-services--component-container">
    <div class="home-services--component">

      <div class="home-services--container">
        <!-- <img class="home-services--world" src="https://hakkoda.io/wp-content/uploads/2024/11/world-1.png" alt=""> -->
        <div class="home-services--video">
            <WistiaVideoPlayer
              :video-id="'446vs1v7n7'" 
              :auto-play="true" 
              :mute="true" 
              :replay="true" 
              :hide-controls="true" 
              :preload="true"
            />
        </div>
      </div>

      <div class="home-services--container content">
        <div class="home-services--content"></div>
        <div class="home-services--subtitle"><p>Services</p></div>
        <div class="home-services--title"><h2>Innovative Results for the Real World.</h2></div>
        <div class="home-services--description">
          <p class="home-services--description-paragraph">Discover modern data services built to help you harness cloud platforms & cutting-edge AI. Connect with global teams of the world’s best data talent for in-timezone support, end-to-end expertise in the modern data stack, and accelerated capabilities. Transform your next data project into high-impact outcomes.</p>
        </div>
        <div class="home-services--btn">
          <a 
            class="btn-md" 
            href="https://hakkoda.io/services"
          >Explore Data Services</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue'
    import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'

  interface Props {
  }

  const props = defineProps<Props>()

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .home-services {

    &--component {
      display: flex;
      width: 100%;
      flex-direction: column;
      max-width: 1440px;
      padding: 20px;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
      }

      @media (min-width: 991px) {
        flex-direction: row;
        padding: 20px 40px;
      }
    }

    &--container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      &.content {
        padding: 20px;
      }

      @media (min-width: 991px) {
        width: 50%;
      }
    }

    &--world {
      max-height: 20vh;
      object-fit: cover;
      object-position: center top;

      @media (min-width: 991px) {
        max-height: 100%;
        position: relative;
        left: -20%;
      }
    }

    &--title {

      h2 {
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
      }
    }

    &--subtitle {
      p {
        color: #21B0E2;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }
    }

    &--description {
      padding: 10px 0;

      &-paragraph {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
      }
    }

    &--btn {
      padding-top: 25px;

      a {
        background: #00D5FF;
        color: #000;

        &:hover {
          background: #555357;
          color: #fff;
        }
      }
    }
  }
</style>