import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "hkkd-hero" }
const _hoisted_2 = { class: "hero--container" }
const _hoisted_3 = {
  id: "homePagehero",
  class: "carousel slide carousel-fade"
}
const _hoisted_4 = { class: "carousel-inner" }
const _hoisted_5 = { class: "carousel-item active" }
const _hoisted_6 = {
  key: 0,
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/10/Homepage_intro_v2.jpg",
  class: "hero--video-intro-image",
  alt: ""
}
const _hoisted_7 = {
  key: 1,
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/10/video_backup.jpg",
  class: "hero--video-intro-image",
  alt: ""
}
const _hoisted_8 = {
  key: 3,
  class: "hero--video-ctrl"
}
const _hoisted_9 = { class: "hero--animation" }
const _hoisted_10 = { class: "hero--animation-wrapper" }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "d-flex" }
const _hoisted_13 = { class: "flex-column" }
const _hoisted_14 = {
  key: 0,
  class: "hero--heading p-f hero--black-bg slide-top"
}
const _hoisted_15 = {
  key: 1,
  class: "hero--heading p-f hero--black-bg hero--accent slide-top"
}
const _hoisted_16 = {
  key: 0,
  class: "hero--heading hero--black-bg hero--accent slide-rotate animated"
}
const _hoisted_17 = {
  key: 1,
  class: "hero--heading p-r hero--black-bg hero--accent slide-rotate animated"
}
const _hoisted_18 = {
  key: 2,
  class: "hero--heading hero--black-bg hero--data-accent slide-rotate animated"
}
const _hoisted_19 = {
  key: 3,
  class: "hero--heading p-r hero--black-bg hero--data-accent slide-rotate animated"
}
const _hoisted_20 = {
  key: 4,
  class: "hero--heading hero--black-bg hero--ia-accent slide-rotate animated"
}
const _hoisted_21 = {
  key: 5,
  class: "hero--heading p-r hero--black-bg hero--ia-accent slide-rotate animated"
}
const _hoisted_22 = {
  key: 6,
  class: "hero--heading p-r hero--black-bg hero--purple-accent slide-rotate animated"
}
const _hoisted_23 = {
  key: 7,
  class: "hero--heading p-f hero--black-bg hero--purple-accent slide-rotate"
}
const _hoisted_24 = { class: "hero--ctas" }
const _hoisted_25 = { class: "carousel-item" }
const _hoisted_26 = { class: "hero--caption" }
const _hoisted_27 = { class: "hero--ctas" }
const _hoisted_28 = { class: "carousel-item" }
const _hoisted_29 = { class: "hero--caption" }
const _hoisted_30 = { class: "hero--ctas" }
const _hoisted_31 = { class: "carousel-item" }
const _hoisted_32 = { class: "hero--caption" }
const _hoisted_33 = { class: "hero--ctas" }

import { ref, onMounted, onUnmounted } from 'vue'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'hero_v2',
  setup(__props: any) {

  const props = __props
  const customerStore = useCustomerStore()

  const second = ref(0);
  const isMute = ref(true);
  const isReset = ref(false);
  const isVideoEnd = ref(false);

  function onSecondChange(s) {
    // console.log("secondChange", s)
    second.value = s;
  }

  function onEnd() {
    window.jQuery('#homePagehero').carousel('next')
    isVideoEnd.value = true
  }

  function muteVideo(val: boolean) {
    console.log('muteVideo', val);
    isMute.value = val;
  }

  function onCustomerLogoClick(val) {
    customerStore.$patch({
      'activeStory': { 'clientName' : val }
    })
  }

  onMounted( ()  => {
    const myCarousel = document.getElementById('homePagehero')
    myCarousel.addEventListener('slide.bs.carousel', event => {

    })
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"carousel-indicators\" data-v-3c90d4b9><div class=\"carousel-indicators--ctrl\" data-v-3c90d4b9><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"0\" class=\"carousel-indicators--btn active\" aria-current=\"true\" aria-label=\"Slide 0\" data-v-3c90d4b9><img loading=\"lazy\" decoding=\"async\" src=\"https://hakkoda.io/wp-content/uploads/2024/09/House.svg\" class=\"carousel-indicators--btn-icon\" alt=\"\" data-v-3c90d4b9></button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"1\" class=\"carousel-indicators--btn\" aria-current=\"true\" aria-label=\"Migrate\" data-v-3c90d4b9>Migrate</button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"2\" class=\"carousel-indicators--btn\" aria-label=\"Modernize\" data-v-3c90d4b9>Modernize</button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"3\" class=\"carousel-indicators--btn\" aria-label=\"Monetize\" data-v-3c90d4b9>Monetize</button></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (second.value <= 0 && !isVideoEnd.value)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true),
            (isVideoEnd.value)
              ? (_openBlock(), _createElementBlock("img", _hoisted_7))
              : _createCommentVNode("", true),
            (!isVideoEnd.value)
              ? (_openBlock(), _createBlock(WistiaVideoPlayer, {
                  key: 2,
                  "video-id": 'snn46mmm3v',
                  "is-bg-video": true,
                  "auto-play": true,
                  mute: isMute.value,
                  replay: true,
                  "hide-controls": true,
                  onSecondchange: onSecondChange,
                  onEnd: onEnd
                }, null, 8, ["mute"]))
              : _createCommentVNode("", true),
            (second.value > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (!isMute.value)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (muteVideo(true)))
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("i", { class: "fa-solid fa-volume-high" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  (isMute.value)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (muteVideo(false)))
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("i", { class: "fa-solid fa-volume-xmark" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (second.value >= 0.1 || isVideoEnd.value)
                    ? (_openBlock(), _createElementBlock("h2", {
                        key: 0,
                        class: _normalizeClass(["hero--heading p-l slide-top", {
                      'scale-down-center': second.value >= 28.7
                    }])
                      }, "We", 2))
                    : _createCommentVNode("", true),
                  (second.value >= 0.1 || isVideoEnd.value)
                    ? (_openBlock(), _createElementBlock("h2", {
                        key: 1,
                        class: _normalizeClass(["hero--heading p-r slide-top", {
                      'scale-down-center': second.value >= 28.7
                    }])
                      }, "Power", 2))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (second.value >= 0.6 || isVideoEnd.value)
                    ? (_openBlock(), _createElementBlock("h2", {
                        key: 0,
                        class: _normalizeClass(["hero--heading p-l slide-top", {
                      'scale-down-center': second.value >= 28.7
                    }])
                      }, "Data", 2))
                    : _createCommentVNode("", true),
                  (second.value >= 0.9 || isVideoEnd.value)
                    ? (_openBlock(), _createElementBlock("h2", {
                        key: 1,
                        class: _normalizeClass(["hero--heading p-r slide-top", {
                      'scale-down-center': second.value >= 28.7
                    }])
                      }, "Innovation", 2))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  ((second.value >= 2.3 && second.value <= 4.1) || isVideoEnd.value)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_14, "So You Can Build The"))
                    : _createCommentVNode("", true),
                  ((second.value >= 2.4 && second.value <= 4.1) || isVideoEnd.value)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_15, "Next Big Thing"))
                    : _createCommentVNode("", true)
                ]),
                (second.value >= 4.2)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["hero--heading-container d-flex slide-top", {
                    'scale-down-center': second.value >= 28.7
                  }])
                    }, [
                      _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "hero--heading p-l hero--black-bg" }, "With", -1)),
                      (second.value >= 4.3 && second.value <= 9.7)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_16, "scalable"))
                        : _createCommentVNode("", true),
                      (second.value >= 4.4 && second.value <= 9.7)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_17, "teams"))
                        : _createCommentVNode("", true),
                      (second.value >= 9.8 && second.value <= 16)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_18, "data"))
                        : _createCommentVNode("", true),
                      (second.value >= 9.9 && second.value <= 16)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_19, "analytics"))
                        : _createCommentVNode("", true),
                      (second.value >= 16.1 && second.value <= 22.1)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_20, "AI"))
                        : _createCommentVNode("", true),
                      (second.value >= 16.2 && second.value <= 22.1)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_21, "strategies"))
                        : _createCommentVNode("", true),
                      (second.value >= 22.2)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_22, "the modern"))
                        : _createCommentVNode("", true),
                      (second.value >= 22.3)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_23, "data stack"))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_24, [
                (second.value >= 4.3 || isVideoEnd.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["hero--btn slide-top", {
                    'scale-down-center': second.value >= 28.7
                  }])
                    }, _cache[8] || (_cache[8] = [
                      _createElementVNode("a", {
                        class: "btn-md btn-dark",
                        href: "https://hakkoda.io/services/",
                        target: "_self"
                      }, "Learn More", -1)
                    ]), 2))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _cache[13] || (_cache[13] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-mobile-bg.jpg",
              class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
              alt: "Migrate"
            }, null, -1)),
            _cache[14] || (_cache[14] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/01/Migrate_V004.webp",
              class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
              alt: "Migrate"
            }, null, -1)),
            _createElementVNode("div", _hoisted_26, [
              _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Seamless Data Migration:", -1)),
              _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Elevate Your Business to New Heights", -1)),
              _cache[12] || (_cache[12] = _createElementVNode("p", null, "Hakkoda’s Data Migration service is more than just a one-time transfer to the cloud; it's a strategic intervention that allows you to rethink your business from the ground up by accelerating integration, streamlining operations, and transforming your data into your most powerful asset.", -1)),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", {
                  class: "hero--btn hero--btn-default",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (onCustomerLogoClick('US Foods')))
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("div", { class: "hero--logo" }, [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/usfoods_logo.png",
                      class: "us-foods-logo",
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("span", { class: "btn-md" }, "See the Story", -1)
                ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _cache[19] || (_cache[19] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-mobile-bg.jpg",
              class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
              alt: "Modernize"
            }, null, -1)),
            _cache[20] || (_cache[20] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/01/Modernize_V004.webp",
              class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
              alt: "Modernize"
            }, null, -1)),
            _createElementVNode("div", _hoisted_29, [
              _cache[16] || (_cache[16] = _createElementVNode("h3", null, "Empower Your Business with", -1)),
              _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Data Modernization Excellence", -1)),
              _cache[18] || (_cache[18] = _createElementVNode("p", null, "Hakkoda’s Data Modernization solutions are crafted to empower your business in the digital age. We specialize in building and implementing future-proofed data strategies that cater to the dynamic needs of your business, freeing up your resources to focus on the next big thing.", -1)),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", {
                  class: "hero--btn hero--btn-default",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (onCustomerLogoClick('Century')))
                }, _cache[15] || (_cache[15] = [
                  _createElementVNode("div", { class: "hero--logo" }, [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/century-1.png",
                      class: "century-logo",
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("span", { class: "btn-md" }, "See the story", -1)
                ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_31, [
            _cache[25] || (_cache[25] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-mobile-bg.jpg",
              class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
              alt: "Monetize"
            }, null, -1)),
            _cache[26] || (_cache[26] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/01/Monetize_V004.webp",
              class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
              alt: "Monetize"
            }, null, -1)),
            _createElementVNode("div", _hoisted_32, [
              _cache[22] || (_cache[22] = _createElementVNode("h3", null, "Unleash the Potential:", -1)),
              _cache[23] || (_cache[23] = _createElementVNode("h2", null, "Data Monetization for Intelligent Growth", -1)),
              _cache[24] || (_cache[24] = _createElementVNode("p", null, "In today's data-driven world, your information is more than just a collection of numbers - it's a goldmine of opportunities waiting to be explored. Hakkoda's Data Monetization services empower you to securely create, share, and monetize feature rich applications on the Snowflake Data Cloud.", -1)),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", {
                  class: "hero--btn hero--btn-default",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (onCustomerLogoClick('Blue Yonder')))
                }, _cache[21] || (_cache[21] = [
                  _createElementVNode("div", { class: "hero--logo" }, [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-1.png",
                      class: "blueyonder-logo",
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("span", { class: "btn-md" }, "See the Story", -1)
                ]))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})