<template>
  <div>
    <div class="hero-mobile--container">
      <div v-on:click.stop="()=> {}" class="hero--container-video">

          <img 
              v-show="second <= 0.1 && !isVideoEnd"
              loading="lazy" 
              decoding="async" 
              src="https://hakkoda.io/wp-content/uploads/2024/11/hero_mobile_image_intro.jpg" 
              class="hero--video-intro-image" 
              alt=""
            >

            <img 
              v-if="isVideoEnd"
              loading="lazy" 
              decoding="async" 
              src="https://hakkoda.io/wp-content/uploads/2024/10/video_backup_mobile.jpg" 
              class="hero--video-intro-image" 
              alt=""
            >

            <wistia-video-player
              v-if="!isVideoEnd"
              :video-id="'ka80rutrfd'" 
              :auto-play="true" 
              :mute="isMute" 
              :replay="false" 
              :hide-controls="true" 
              @secondchange="onSecondChange"
              @end="onEnd"
            />

            <div class="hero--video-ctrl">
              <button v-if="!isMute" @click="muteVideo(true)">
                <i  class="fa-solid fa-volume-high"></i>
              </button>
              <button v-if="isMute" @click="muteVideo(false)">
                <i class="fa-solid fa-volume-xmark"></i>
              </button>
            </div>

      </div>
      <div id="homePagehero" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <div class="carousel-indicators--ctrl">
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="0" class="carousel-indicators--btn active" aria-current="true" aria-label="Migrate">Migrate</button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="1" class="carousel-indicators--btn" aria-label="Modernize">Modernize</button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="2" class="carousel-indicators--btn" aria-label="Monetize">Monetize</button>
          </div>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <img v-if="false" src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Migrate">
            <img v-if="false" src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Migrate">
            
            <div class="hero--caption">
              <img src="https://hakkoda.io/wp-content/uploads/2024/10/migrate_icon.svg" class="hero--caption-badge" alt="Migrate">
              <h3>Seamless Data Migration:</h3>
              <h2>Elevate Your Business to New Heights</h2>
              <p>Hakkoda’s Data Migration service is more than just a one-time transfer to the cloud; it's a strategic intervention that allows you to rethink your business from the ground up by accelerating integration, streamlining operations, and transforming your data into your most powerful asset.</p>
              <div class="hero--ctas">
                <div class="hero--logo">
                  <a 
                    class="btn-md"
                    @click="onCustomerLogoClick('US Foods')"
                  >
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/usfoods_logo.png" class="us-foods-logo" alt="">
                  </a>
                </div>
                <div 
                  class="hero--btn hero--btn-default"
                >
                  <a 
                    class="btn-md"
                    @click="onCustomerLogoClick('US Foods')"
                  >Watch Our Us Foods Case</a>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="10000">
            <img v-if="false" src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Modernize">
            <img v-if="false" src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Modernize">

            <div class="hero--caption">
              <img src="https://hakkoda.io/wp-content/uploads/2024/10/mornize_icon.svg" class="hero--caption-badge" alt="Modernize">
              <h3>Empower Your Business with</h3>
              <h2>Data Modernization Excellence</h2>
              <p>Hakkoda’s Data Modernization solutions are crafted to empower your business in the digital age. We specialize in building and implementing future-proofed data strategies that cater to the dynamic needs of your business, freeing up your resources to focus on the next big thing.</p>
              <div class="hero--ctas">
                <div class="hero--logo">
                  <a 
                    class="btn-md"
                    @click="onCustomerLogoClick('Century')"
                  >
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/century-1.png" class="century-logo" alt="">
                  </a>
                </div>
                <div 
                  class="hero--btn hero--btn-default"
                >
                  <a
                    class="btn-md"
                    @click="onCustomerLogoClick('Century')"
                  >Watch Our Century Case</a>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item"  data-bs-interval="10000">
            <img v-if="false" src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Monetize" />
            <img v-if="false" src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Monetize" />

            <div class="hero--caption">
              <img src="https://hakkoda.io/wp-content/uploads/2024/10/monetize_icon.svg" class="hero--caption-badge" alt="Monetize">
              <h3>Unleash the Potential:</h3>
              <h2>Data Monetization for Intelligent Growth</h2>
              <p>In today's data-driven world, your information is more than just a collection of numbers - it's a goldmine of opportunities waiting to be explored. Hakkoda's Data Monetization services empower you to securely create, share, and monetize feature rich applications on the Snowflake Data Cloud.</p>
              <div class="hero--ctas">
                <div class="hero--logo">
                  <a 
                    class="btn-md" 
                    @click="onCustomerLogoClick('Blue Yonder')"
                  >
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-1.png" class="blueyonder-logo" alt="">
                  </a>
                </div>
                <div class="hero--btn hero--btn-default">
                  <a 
                    @click="onCustomerLogoClick('Blue Yonder')"
                    class="btn-md"
                  >Watch Our Blue Yonder Case</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  const props = defineProps<Props>()
  const customerStore = useCustomerStore()
  const second = ref(0);
  const isMute = ref(true);
  const isVideoEnd = ref(false);


  function onSecondChange(s) {
    // console.log("secondChange", s)
    second.value = s;
  }

  function muteVideo(val) {
    isMute.value = val;
  }

  function onEnd() {
    isVideoEnd.value = true
  }

  function onCustomerLogoClick(val) {
    customerStore.$patch({
      'activeStory': { 'clientName' : val }
    })
  }


  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .hero-mobile {

    &--container {
      background: #fff;

      &-video {
        position: relative;
        width: 100%;
        height: calc(100vw * 0.5); // aspect-ratio: 4/3;
      }
    }

    &--video {

      &-intro-image {
        position: absolute;
        width: 100%;
        height: calc(100vw * 0.5);
        object-fit: contain;
        object-position: center bottom;
        margin-top: -1px;
        transition: all 200ms linear;
      }

      &-ctrl {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;

        button {
          padding: 0;
          border: 0;
          background-color: transparent;
          
          :hover,
          :focus
           {
            background-color: transparent;
            border: 0;
          }
        }

        i {
          width: 35px;
          height: 35px;
          padding: 12px 10px;
          font-size: 12px;
          background: hsla(0, 0%, 100%, .6);
          border-radius: 50%;
          backdrop-filter: blur(3px);
          box-shadow: 3px 3px 30px 10px rgba(0, 0, 0, .05);
          color: #555357;
        }
      }
    }

    &--caption {
      margin: 120px 20px 60px;
      background: #000;
      border-radius: 16px;
      padding: 30px 25px;

      &-badge {
        padding: 8px 0;
      }

      h2 {
        color: #00D5FF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 20px;
      }

      h3 {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
      }

      p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        min-height: 200px;
      }
    }

    &--animation {
      position: absolute;
      left: 20px;
      color: #fff;
      top: calc(10% + 20px);

      &-wrapper {
        min-height: 200px;
      }
    }

    &--heading {
      font-size: 28px;
      line-height: 32px;
      color: #000;
      background-color: rgb(255 255 255 / 90%);
      padding: 8px;
      width: fit-content;
      margin: 0;
      height: 48px;

      &-container {
        max-width: 400px;
        flex-wrap: wrap;
        
        h2 {
          padding: 8px 4px;
        }
      }

      &.animated {
        margin-top: 10px;
      }

      &.p-f {
        padding: 8px 12px;
      }

      &.p-l {
        padding: 8px 4px 8px 12px;
      }

      &.p-r {
        padding: 8px 12px 8px 4px;
      }
    }

    &--black-bg {
      color: #fff;
      background-color: rgb(0 0 0 / 90%);
    }

    &--accent {
      color: #00D5FF;
    }

    &--data-accent {
      color: #00e2da;
    }

    &--ia-accent {
      color: #ff774d;
    }

    &--purple-accent {
      color: #dbc3ff;
    }

    &--ctas {
      display: flex;
      flex-flow: column;
      gap: 20px;
    }

    &--btn {
      margin-top: 10px;

      a {
        border-radius: 50px;
        padding: 0 20px;
      }
    }

    &--btn-default {
      a {
        background: #00d5ff;
        color: #000;

        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }

    &--logo {
      margin-top: 15px;
      height: 45px;

      a {
        display: block;
        text-align: left;
        height: auto;
      }

      img {
        width: auto;
        height: 30px;

        &.us-foods-logo {
          height: 40px;
        }

        &.century-logo {

        }

        &.blueyonder-logo { 
          height: 24px;
        }
      } 
    } 
  }

  .carousel-item {

    &--bg {
      object-fit: cover;
      object-position: center;
      object-position: 50% 0;
    }
  }

  .carousel-indicators {
    top: 0;
    bottom: auto;
    margin: 20px;

    &--ctrl {
      display: flex;
      width: 100%;
      padding: 4px;
      background: #FFFFFF99;
      border-radius: 13px;
      gap: 8px;
      backdrop-filter: blur(3px);
      box-shadow: 3px 3px 30px 10px rgba(0,0,0,0.05);
    }

    &--btn {
      padding: 0 12px;
      cursor: pointer;
      width: 100%;
      min-width: 40px;
      height: 64px;
      text-indent: 0;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border-radius: 13px;
      background: transparent;
      border: 0;
      text-transform: capitalize;
      margin: 0;
      letter-spacing: 0;
      opacity: 1;
      line-height: 37px; 
      letter-spacing: 0.32px;


      &.active {
        background: #00D5FF;
        transition: all 0.2s ease-in;
      }

      &:hover {
        transition: all 0.2s ease-in;
      }

    }

    &--icon {
      font-size: 18px;
    }
  }


  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .carousel-item {


      &--bg {
        object-position: center;
      }
    }

    .carousel-indicators {

      &--ctrl {
        
      }

      &--btn {
        font-size: 16px;
        padding: 4px 40px;
      }
    }

    .hero {
      &--container {
      }

      &--caption {
        top: 20%;
        left: 60px;

        h2 {
          font-size: 46px;
        }

        h3 {
          font-size: 28px;
        }

        p {
          font-size: 16px;
        }
      }
      
      &--animation {
        left: 40px;
        top: calc(15% + 100px);

        &-wrapper {
          min-height: 300px;
        }
      }

      &--heading {
        font-size: 48px;
        line-height: 54px;
      }

      &--heading {
        font-size: 48px;
        line-height: 54px;
        height: 70px;

        &-container {
          max-width: 600px;
        }
      }

      &--ctas {
      }

      &--btn {
        align-self: center;
        margin-top: 20px;
      }

      &--logo {
        margin-top: 20px;
      }
    }
  }

  /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  .slide-top {
    -webkit-animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      filter: opacity(1);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      filter: opacity(1);
    }
  }

    /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  .slide-rotate {
    -webkit-animation: slide-rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-rotate {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      filter: opacity(1);
    }
  }

  @keyframes slide-rotate {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      filter: opacity(1);
    }
  }
</style>