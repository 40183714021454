import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "hero-mobile--container" }
const _hoisted_2 = {
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/11/hero_mobile_image_intro.jpg",
  class: "hero--video-intro-image",
  alt: ""
}
const _hoisted_3 = {
  key: 0,
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/10/video_backup_mobile.jpg",
  class: "hero--video-intro-image",
  alt: ""
}
const _hoisted_4 = { class: "hero--video-ctrl" }
const _hoisted_5 = {
  id: "homePagehero",
  class: "carousel slide carousel-fade",
  "data-bs-ride": "carousel"
}
const _hoisted_6 = { class: "carousel-inner" }
const _hoisted_7 = {
  class: "carousel-item active",
  "data-bs-interval": "10000"
}
const _hoisted_8 = {
  key: 0,
  src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-mobile-bg.jpg",
  class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
  alt: "Migrate"
}
const _hoisted_9 = {
  key: 1,
  src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-bg.jpg",
  class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
  alt: "Migrate"
}
const _hoisted_10 = { class: "hero--caption" }
const _hoisted_11 = { class: "hero--ctas" }
const _hoisted_12 = { class: "hero--logo" }
const _hoisted_13 = { class: "hero--btn hero--btn-default" }
const _hoisted_14 = {
  class: "carousel-item",
  "data-bs-interval": "10000"
}
const _hoisted_15 = {
  key: 0,
  src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-mobile-bg.jpg",
  class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
  alt: "Modernize"
}
const _hoisted_16 = {
  key: 1,
  src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-bg.jpg",
  class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
  alt: "Modernize"
}
const _hoisted_17 = { class: "hero--caption" }
const _hoisted_18 = { class: "hero--ctas" }
const _hoisted_19 = { class: "hero--logo" }
const _hoisted_20 = { class: "hero--btn hero--btn-default" }
const _hoisted_21 = {
  class: "carousel-item",
  "data-bs-interval": "10000"
}
const _hoisted_22 = {
  key: 0,
  src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-mobile-bg.jpg",
  class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
  alt: "Monetize"
}
const _hoisted_23 = {
  key: 1,
  src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-bg.jpg",
  class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
  alt: "Monetize"
}
const _hoisted_24 = { class: "hero--caption" }
const _hoisted_25 = { class: "hero--ctas" }
const _hoisted_26 = { class: "hero--logo" }
const _hoisted_27 = { class: "hero--btn hero--btn-default" }

import { ref, onMounted, onUnmounted } from 'vue'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'hero_mobile',
  setup(__props: any) {

  const props = __props
  const customerStore = useCustomerStore()
  const second = ref(0);
  const isMute = ref(true);
  const isVideoEnd = ref(false);


  function onSecondChange(s) {
    // console.log("secondChange", s)
    second.value = s;
  }

  function muteVideo(val) {
    isMute.value = val;
  }

  function onEnd() {
    isVideoEnd.value = true
  }

  function onCustomerLogoClick(val) {
    customerStore.$patch({
      'activeStory': { 'clientName' : val }
    })
  }


  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _withModifiers(()=> {}, ["stop"]),
        class: "hero--container-video"
      }, [
        _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
          [_vShow, second.value <= 0.1 && !isVideoEnd.value]
        ]),
        (isVideoEnd.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : _createCommentVNode("", true),
        (!isVideoEnd.value)
          ? (_openBlock(), _createBlock(WistiaVideoPlayer, {
              key: 1,
              "video-id": 'ka80rutrfd',
              "auto-play": true,
              mute: isMute.value,
              replay: false,
              "hide-controls": true,
              onSecondchange: onSecondChange,
              onEnd: onEnd
            }, null, 8, ["mute"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (!isMute.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (muteVideo(true)))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("i", { class: "fa-solid fa-volume-high" }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (isMute.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (muteVideo(false)))
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("i", { class: "fa-solid fa-volume-xmark" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"carousel-indicators\" data-v-4d24b913><div class=\"carousel-indicators--ctrl\" data-v-4d24b913><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"0\" class=\"carousel-indicators--btn active\" aria-current=\"true\" aria-label=\"Migrate\" data-v-4d24b913>Migrate</button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"1\" class=\"carousel-indicators--btn\" aria-label=\"Modernize\" data-v-4d24b913>Modernize</button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"2\" class=\"carousel-indicators--btn\" aria-label=\"Monetize\" data-v-4d24b913>Monetize</button></div></div>", 1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            false
              ? (_openBlock(), _createElementBlock("img", _hoisted_8))
              : _createCommentVNode("", true),
            false
              ? (_openBlock(), _createElementBlock("img", _hoisted_9))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _cache[11] || (_cache[11] = _createElementVNode("img", {
                src: "https://hakkoda.io/wp-content/uploads/2024/10/migrate_icon.svg",
                class: "hero--caption-badge",
                alt: "Migrate"
              }, null, -1)),
              _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Seamless Data Migration:", -1)),
              _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Elevate Your Business to New Heights", -1)),
              _cache[14] || (_cache[14] = _createElementVNode("p", null, "Hakkoda’s Data Migration service is more than just a one-time transfer to the cloud; it's a strategic intervention that allows you to rethink your business from the ground up by accelerating integration, streamlining operations, and transforming your data into your most powerful asset.", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("a", {
                    class: "btn-md",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (onCustomerLogoClick('US Foods')))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/usfoods_logo.png",
                      class: "us-foods-logo",
                      alt: ""
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("a", {
                    class: "btn-md",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (onCustomerLogoClick('US Foods')))
                  }, "Watch Our Us Foods Case")
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            false
              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
              : _createCommentVNode("", true),
            false
              ? (_openBlock(), _createElementBlock("img", _hoisted_16))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_17, [
              _cache[16] || (_cache[16] = _createElementVNode("img", {
                src: "https://hakkoda.io/wp-content/uploads/2024/10/mornize_icon.svg",
                class: "hero--caption-badge",
                alt: "Modernize"
              }, null, -1)),
              _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Empower Your Business with", -1)),
              _cache[18] || (_cache[18] = _createElementVNode("h2", null, "Data Modernization Excellence", -1)),
              _cache[19] || (_cache[19] = _createElementVNode("p", null, "Hakkoda’s Data Modernization solutions are crafted to empower your business in the digital age. We specialize in building and implementing future-proofed data strategies that cater to the dynamic needs of your business, freeing up your resources to focus on the next big thing.", -1)),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("a", {
                    class: "btn-md",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (onCustomerLogoClick('Century')))
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/century-1.png",
                      class: "century-logo",
                      alt: ""
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("a", {
                    class: "btn-md",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (onCustomerLogoClick('Century')))
                  }, "Watch Our Century Case")
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            false
              ? (_openBlock(), _createElementBlock("img", _hoisted_22))
              : _createCommentVNode("", true),
            false
              ? (_openBlock(), _createElementBlock("img", _hoisted_23))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_24, [
              _cache[21] || (_cache[21] = _createElementVNode("img", {
                src: "https://hakkoda.io/wp-content/uploads/2024/10/monetize_icon.svg",
                class: "hero--caption-badge",
                alt: "Monetize"
              }, null, -1)),
              _cache[22] || (_cache[22] = _createElementVNode("h3", null, "Unleash the Potential:", -1)),
              _cache[23] || (_cache[23] = _createElementVNode("h2", null, "Data Monetization for Intelligent Growth", -1)),
              _cache[24] || (_cache[24] = _createElementVNode("p", null, "In today's data-driven world, your information is more than just a collection of numbers - it's a goldmine of opportunities waiting to be explored. Hakkoda's Data Monetization services empower you to securely create, share, and monetize feature rich applications on the Snowflake Data Cloud.", -1)),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("a", {
                    class: "btn-md",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (onCustomerLogoClick('Blue Yonder')))
                  }, _cache[20] || (_cache[20] = [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-1.png",
                      class: "blueyonder-logo",
                      alt: ""
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("a", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (onCustomerLogoClick('Blue Yonder'))),
                    class: "btn-md"
                  }, "Watch Our Blue Yonder Case")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})