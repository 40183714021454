import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home-page--hero" }
const _hoisted_2 = {
  key: 0,
  class: "home-hero-mobile-container"
}
const _hoisted_3 = {
  key: 1,
  class: "home-hero-desktop-container"
}

import { ref, onMounted, onUnmounted } from 'vue'
  import { useMediaQuery } from '@vueuse/core'
  import HeroV3 from './hero_v3.vue'
  import HeroMobile from './hero_mobile.vue'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'home-hero',
  setup(__props: any) {

  const props = __props
  const isLargeScreen = useMediaQuery('(min-width: 992px)')

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_unref(isLargeScreen))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(HeroMobile)
        ]))
      : _createCommentVNode("", true),
    (_unref(isLargeScreen))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(HeroV3)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})