import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hkkd-home-page" }

import { ref, onMounted, onUnmounted, computed } from 'vue'

  import HomePageHero from './components/hero.vue'
  import Stories from './components/stories.vue'
  import Services from './components/services.vue'
  import Industries from './components/industries_v2.vue'
  import Solutions from './components/solutions.vue'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'home-page',
  setup(__props: any) {

  const props = __props
  onMounted( ()  => {
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HomePageHero),
    _createVNode(Stories),
    _createVNode(Services),
    _createVNode(Industries),
    _createVNode(Solutions)
  ]))
}
}

})