<template>
  <div class="hero--component">
    <div class="hero--container">
      <div id="homePagehero" class="carousel slide carousel-fade">
        <div v-show="isTablet" class="carousel-indicators">
          <div class="carousel-indicators--ctrl">
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="0" class="carousel-indicators--btn active" aria-current="true" aria-label="Slide 0">
              <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/09/House.svg" class="carousel-indicators--btn-icon" alt="">
            </button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="1" class="carousel-indicators--btn" aria-current="true" aria-label="Migrate">Migrate</button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="2" class="carousel-indicators--btn" aria-label="Modernize">Modernize</button>
            <button type="button" data-bs-target="#homePagehero" data-bs-slide-to="3" class="carousel-indicators--btn" aria-label="Monetize">Monetize</button>
          </div>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">

            <div  class="hero--background-container">
              <img 
                v-show="second <= 0"
                loading="lazy" 
                decoding="async" 
                src="https://hakkoda.io/wp-content/uploads/2024/11/hero-v5-bg.jpg" 
                class="hero--wistia-intro-image" 
                alt=""
              >
              <img 
                v-show="second <= 0.1"
                loading="lazy" 
                decoding="async" 
                src="https://hakkoda.io/wp-content/uploads/2024/11/hero-v3-bg-overlay-1-1.png" 
                class="hero--image-moving-overlay-left slide-left" 
                alt=""
              >
              <img 
                v-show="second <= 0.1"
                loading="lazy" 
                decoding="async" 
                src="https://hakkoda.io/wp-content/uploads/2024/11/hero-v3-bg-overlay-2.png" 
                class="hero--image-moving-overlay-rigth slide-right" 
                alt=""
              >

              <!-- video play -->

              <div v-if="!isDesktop && isBgVideo" class="hero--mobile-video">
                <img 
                  v-if="isVideoEnd && false"
                  loading="lazy" 
                  decoding="async" 
                  src="https://hakkoda.io/wp-content/uploads/2024/10/video_backup_mobile.jpg" 
                  class="hero--video-intro-image" 
                  alt=""
                >

                <WistiaVideoPlayer
                  v-if="isVideoPlaying"
                  :video-id="'ka80rutrfd'" 
                  :auto-play="true" 
                  :mute="isMute" 
                  :replay="false" 
                  :hide-controls="true"
                  :full-screen="true"
                  :play-video="isVideoPlaying" 
                  @secondchange="onSecondChange"
                  @end="onCloseVideo"
                  @cancelfullscreen="onCloseVideo"
                />
              </div>

              <div v-if="isDesktop && isBgVideo" class="hero--bg-video">
                <WistiaVideoPlayer
                  v-if="isVideoPlaying"
                  :video-id="'ka80rutrfd'" 
                  :is-bg-video="true" 
                  :auto-play="true" 
                  :mute="isMute" 
                  :replay="true" 
                  :hide-controls="true" 
                  :play-video="isVideoPlaying" 
                  @secondchange="onSecondChange"
                  @pause="onCloseVideo"
                  @end="onEnd"
                />
              </div>

              <div 
                v-if="isVideoPlaying && isDesktop && isBgVideo"
                class="hero--video-go-back-container"
                @click="onCloseVideo"
              >
                <img
                  loading="lazy" 
                  decoding="async" 
                  class="hero--video-go-back"
                  src="https://hakkoda.io/wp-content/uploads/2022/04/Directional-Left.svg" 
                  alt=""
                >
                <p class="hero--video-go-back-text">Go Back</p>
              </div>

              <div v-if="second > 0 && isBgVideo" class="hero--wistia-ctrl">
                <button v-if="!isMute" @click="muteVideo(true)">
                  <i  class="fa-solid fa-volume-high"></i>
                </button>
                <button v-if="isMute" @click="muteVideo(false)">
                  <i class="fa-solid fa-volume-xmark"></i>
                </button>
              </div>
            </div>

            <div class="hero--section-container">
              <div class="hero--section">
                <!-- Frist Screen Animation overlay -->
                <div class="hero--animation hero--title" v-show="second <= 0">
                  <div class="hero--animation-wrapper">
                    <div class="hero--title-container">
                      <h2 
                        class="hero--heading p-f slide-top"
                        :class="{
                          'scale-down-center': false
                        }"
                      >Unleash <br> <b>Innovation.</b></h2>
                    </div>
                    <div class="hero--title-container">
                      <p
                        class="hero--paragraph p-f slide-top"
                        :class="{
                          'scale-down-center': false
                        }"
                      >Helping companies harness cloud platforms and AI capabilities.</p>
                    </div>
                  </div>
                </div>

                <!-- video Animation overlay -->
                <div class="hero--animation hero--video-description" v-if="second > 0 && isDesktop">
                  <div class="hero--animation-wrapper">
                    <div class="d-flex">
                      <h2 
                        v-if="second >= 0.1 || isVideoEnd"
                        class="hero--animation-heading hero--white-bg p-l slide-top"
                        :class="{
                          'scale-down-center': second >= 28.7
                        }"
                      >We</h2>
                      <h2
                        v-if="second >= 0.1 || isVideoEnd"
                        class="hero--animation-heading hero--white-bg p-r slide-top"
                        :class="{
                          'scale-down-center': second >= 28.7
                        }"
                      >Power</h2>
                    </div>
                    <div class="d-flex">
                      <h2 
                        v-if="second >= 0.6 || isVideoEnd"
                        class="hero--animation-heading hero--white-bg p-l slide-top"
                        :class="{
                          'scale-down-center': second >= 28.7
                        }"
                      >Data</h2>
                      <h2
                        v-if="second >= 0.9 || isVideoEnd"
                        class="hero--animation-heading hero--white-bg p-r slide-top"
                        :class="{
                          'scale-down-center': second >= 28.7
                        }"
                      >Innovation</h2>
                    </div>
                    <div class="flex-column">
                      <h2
                        v-if="(second >= 2.3 && second <= 4.1) || isVideoEnd"
                        class="hero--animation-heading p-f hero--black-bg slide-top" 
                      >So You Can Build The</h2>
                      <h2
                        v-if="(second >= 2.4 && second <= 4.1) || isVideoEnd"
                        class="hero--animation-heading p-f hero--black-bg hero--accent slide-top" 
                      >Next Big Thing</h2>
                    </div>
                    <div 
                      v-if="second >= 4.2" 
                      class="hero--animation-heading-container d-flex slide-top"
                      :class="{
                        'scale-down-center': second >= 28.7
                      }"
                    >
                      <h2
                        class="hero--animation-heading p-l hero--black-bg static-word"
                      >With</h2>
                      <h2
                        v-if="second >= 4.3 && second <= 9.7"
                        class="hero--animation-heading hero--black-bg hero--accent slide-rotate animated" 
                        >scalable</h2>
                      <h2
                        v-if="second >= 4.4 && second <= 9.7"
                        class="hero--animation-heading p-r hero--black-bg hero--accent slide-rotate animated" 
                        >teams</h2>
                      <h2
                        v-if="second >= 9.8 && second <= 16"
                        class="hero--animation-heading hero--black-bg hero--data-accent slide-rotate animated"
                      >data</h2>
                      <h2
                        v-if="second >= 9.9 && second <= 16"
                        class="hero--animation-heading p-r hero--black-bg hero--data-accent slide-rotate animated"
                      >analytics</h2>
                      <h2
                        v-if="second >= 16.1 && second <= 22.1"
                        class="hero--animation-heading hero--black-bg hero--ia-accent slide-rotate animated" 
                        >AI</h2>
                      <h2
                        v-if="second >= 16.2 && second <= 22.1"
                        class="hero--animation-heading p-r hero--black-bg hero--ia-accent slide-rotate animated" 
                        >strategies</h2>
                      <h2
                        v-if="second >= 22.2"
                        class="hero--animation-heading p-r hero--black-bg hero--purple-accent slide-rotate animated" 
                        >the modern</h2>
                      <h2
                        v-if="second >= 22.3"
                        class="hero--animation-heading p-f hero--black-bg hero--purple-accent slide-rotate"
                        >data stack</h2>
                    </div>
                  </div>
                  <div  v-if="false" class="hero--ctas">
                    <div 
                      v-if="second >= 4.3 || isVideoEnd"
                      class="hero--btn slide-top"
                      :class="{
                        'scale-down-center': second >= 28.7
                      }"
                    >
                      <a class="btn-md btn-dark " href="https://hakkoda.io/services/" target="_self">Learn More</a>
                    </div>
                  </div>

                </div>
              </div>

              <div class="hero--section centered">
                <div v-show="!isVideoPlaying || !isDesktop" class="hero--section-logo">
                  <div
                    class="hero--video-logo-container"
                    @click="playVideo"
                  >
                    <video 
                      autoplay muted loop playsinline
                      class="hero--video-logo"
                    >
                      <source src="https://hakkoda.io/wp-content/uploads/2024/11/Just_Hakkoda_logo_V004.mp4" type="video/mp4">
                    </video>
                    <img
                      loading="lazy" 
                      decoding="async" 
                      class="hero--video-logo-play-icon"
                      src="https://hakkoda.io/wp-content/uploads/2024/10/play-icon-black.svg" 
                      alt=""
                    >
                  </div>
                </div>

                <div 
                  v-show="isVideoPlaying" 
                  class="hero--inline-video-container"
                  :class="{
                    'is-mobile': !isDesktop
                  }"
                >
                  <div v-show="!isDesktop"  class="hero--inline-video-overlay" @click="onCloseVideo"></div>
                  <div class="hero--inline-video shadow-drop-center">
                    <img
                      v-show="isVideoPlaying"
                      loading="lazy" 
                      decoding="async" 
                      class="hero--inline-video-close-icon"
                      src="https://hakkoda.io/wp-content/uploads/2024/11/close-square.svg" 
                      alt=""
                      @click="onCloseVideo"
                    >
                    <WistiaVideoPlayer
                      v-show="isVideoPlaying"
                      :video-id="'ka80rutrfd'" 
                      :auto-play="true" 
                      :replay="true" 
                      :play-video="isVideoPlaying" 
                      :pause-video="!isVideoPlaying" 
                      @paused="onCloseVideo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Migrate">
            <img src="https://hakkoda.io/wp-content/uploads/2024/01/Migrate_V004.webp" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Migrate">
            <div class="hero--caption">
              <h3>Seamless Data Migration:</h3>
              <h2>Elevate Your Business to New Heights</h2>
              <p>Hakkoda’s Data Migration service is more than just a one-time transfer to the cloud; it's a strategic intervention that allows you to rethink your business from the ground up by accelerating integration, streamlining operations, and transforming your data into your most powerful asset.</p>
              <div class="hero--ctas">
                <div class="hero--btn hero--btn-default" @click="onCustomerLogoClick('US Foods')">
                  <div class="hero--logo">
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/usfoods_logo.png" class="us-foods-logo" alt="">
                  </div>
                  <span class="btn-md">See the Story</span>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Modernize">
            <img src="https://hakkoda.io/wp-content/uploads/2024/01/Modernize_V004.webp" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Modernize">
            <div class="hero--caption">
              <h3>Empower Your Business with</h3>
              <h2>Data Modernization Excellence</h2>
              <p>Hakkoda’s Data Modernization solutions are crafted to empower your business in the digital age. We specialize in building and implementing future-proofed data strategies that cater to the dynamic needs of your business, freeing up your resources to focus on the next big thing.</p>
              <div class="hero--ctas">
                <div class="hero--btn hero--btn-default" @click="onCustomerLogoClick('Century')">
                  <div class="hero--logo">
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/century-1.png" class="century-logo" alt="">
                  </div>
                  <span class="btn-md">See the story</span>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-mobile-bg.jpg" class="carousel-item--bg d-block w-100 d-sm-block d-md-none" alt="Monetize">
            <img src="https://hakkoda.io/wp-content/uploads/2024/01/Monetize_V004.webp" class="carousel-item--bg d-block w-100 d-sm-none d-md-block" alt="Monetize">
            <div class="hero--caption">
              <h3>Unleash the Potential:</h3>
              <h2>Data Monetization for Intelligent Growth</h2>
              <p>In today's data-driven world, your information is more than just a collection of numbers - it's a goldmine of opportunities waiting to be explored. Hakkoda's Data Monetization services empower you to securely create, share, and monetize feature rich applications on the Snowflake Data Cloud.</p>
              <div class="hero--ctas">
                <div class="hero--btn hero--btn-default" @click="onCustomerLogoClick('Blue Yonder')">
                  <div class="hero--logo">
                    <img loading="lazy" decoding="async" src="https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-1.png" class="blueyonder-logo" alt="">
                  </div>
                  <span class="btn-md">See the Story</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import { useMediaQuery } from '@vueuse/core'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  const props = defineProps<Props>()
  const customerStore = useCustomerStore()
  const isTablet = useMediaQuery('(min-width: 768px)')
  const isDesktop = useMediaQuery('(min-width: 992px)')
  const activeStory = ref<Story>(null)

  const second = ref(0);
  const isMute = ref(false);
  const isVideoEnd = ref(false);
  const isVideoPlaying = ref(false);
  const isBgVideo = ref(false);

  function onSecondChange(s) {
    second.value = s;
  }

  function onEnd() {
    window.jQuery('#homePagehero').carousel('next');
    isVideoEnd.value = true;
    isVideoPlaying.value = false;
  }

  function muteVideo(val: boolean) {
    isMute.value = val;
  }

  function onCustomerLogoClick(val) {
    customerStore.$patch({
      'activeStory': { 'clientName' : val }
    })
  }

  function playVideo() {
    isVideoPlaying.value = true;
  }

  function onCloseVideo() {
    isVideoPlaying.value = false;
  }

  onMounted( ()  => {
    const myCarousel = document.getElementById('homePagehero')
    myCarousel?.addEventListener('slide.bs.carousel', event => {

    })
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  $component-height--mobile: calc(100vh - 120px);
  $component-height--desktop: calc(100vh - 80px);

  .hero {

    &--section {
      display: flex;
      width: 100%;
      padding: 40px 30px;
      justify-content: center;

      @media (min-width: 992px) {
        width: 50%;
        min-height: $component-height--desktop;
        justify-content: left;

        &.centered {
          justify-content: center;
        }
      }

      @media (min-width: 1600px) {
        padding-left: 60px;
      }

      &-container {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        @media (min-width: 992px) {
          flex-direction: row;
        }
      }

      &-logo {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        @media (min-width: 992px) {
          padding: 40px;
        }
      }
    }

    &--container {
      background: #f1f0f8;
      min-height: $component-height--mobile;

      @media (min-width: 768px) {
        min-height: $component-height--desktop;
      }
    }

    &--background-container {
      position: absolute;
      width: 100%;
      top: 0;
    }

    &--image-moving-overlay {

      &-left {
        position: absolute;
        top: 0;
        left: 0;
      }

      &-rigth {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &--video-logo {
      width: 210px;
      height: 210px;
      z-index: 2;

      &-play-icon {
        position: absolute;
        transform: translate(-50%,-50%);
        width: 95px;
        height: 95px;
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        backdrop-filter: blur(3px);
        z-index: 3;
        margin-top: 105px;
        margin-left: 105px;

        &:hover {
          background-color: #000;
          content: url("https://hakkoda.io/wp-content/uploads/2024/11/play-icon-white.svg");
        }
      }

      &-container {
        display: flex;
      }

      @media (min-width: 992px) {
        width: 415px;
        height: 415px;

        &-play-icon {
          margin-top: 205px;
          margin-left: 205px;
        }
      }
    }

    &--inline-video {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 40px 4px rgba(0, 0, 0, 0.25);

      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.is-mobile {
          position: absolute;
          z-index: 4;
        }
      }

      &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.60);
        backdrop-filter: blur(5px);
      }

      &-close-icon {
        display: flex;
        width: 30px;
        z-index: 4;
        align-self: flex-end;
      }
    }

    &--wistia {

      &-intro-image {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: max($component-height--mobile, 660px);
        object-fit: cover;
        object-position: center bottom;

        @media (min-width: 768px) {
          min-height: $component-height--desktop;
        }
      }

      &-ctrl {
        display: block;
        position: absolute;
        top: 50px;
        right: 20px;
        
        button {
          padding: 0;
          border: 0;
          background-color: transparent;
          
          :hover,
          :focus
           {
            background-color: transparent;
            border: 0;
          }
        }

        i {
          width: 45px;
          width: 45px;
          padding: 13px 10px;
          font-size: 20px;
          background: hsla(0, 0%, 100%, .6);
          border-radius: 50%;
          backdrop-filter: blur(3px);
          box-shadow: 3px 3px 30px 10px rgba(0, 0, 0, .05);
          color: #555357;
        }
      }
    }

    &--video-go-back {

      &-container {
        display: flex;
        align-items: center;
        z-index: 1;
        position: absolute;
        top: 50px;
        right: 80px;
      }

      &-text {
        padding: 0 10px;
        line-height: 54px;
        height: 36px;
        color: #17191C;
      }

      @media (min-width: 992px) {

        &-text {
        }
      }
    }

    &--caption {
      color: #fff;
      max-width: 552px;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      margin-left: 40px;
      width: 100%;
      position: relative;
      justify-content: flex-start;

      h2 {
        color: #00D5FF;
        font-size: 28px;
        line-height: 36px;
        font-style: normal;
        font-weight: 500;
        margin: 14px 0 24px;
      }

      h3 {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      @media (min-width: 768px) {
        h2 {
          font-size: 48px;
          line-height: 50px;
        }

        h3 {
          font-size: 20px;
          line-height: 36px;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          min-height: 120px;
        }
      }

      @media (min-width: 992px) {
      }

      @media (min-width: 1600px) {
        margin-left: 80px;
      }

    }

    &--animation {
      color: #fff;
      z-index: 3;
      display: flex;
      align-items: center;

      &-wrapper {
        min-height: 200px;
      }

      &-heading {
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: 35px;
        color: #000;
        padding: 8px 12px;
        width: fit-content;
        margin: 0;
        transition: all 0.2s ease-in allow-discrete;
        text-align: center;
        
        &-container {
          display: flex;
          justify-content: center;
        }

        b {
          font-weight: 700;
        }

        &-container {
          max-width: 400px;
          flex-wrap: wrap;
          
          h2 {
            padding: 8px 4px;
          }
        }

        &.animated {
          margin-top: 10px;
        }

        &.p-f {
          padding: 0 12px;
        }

        &.p-l {
          padding: 8px 4px 8px 12px;
        }

        &.p-r {
          padding: 8px  12px 8px 4px;
        }

        @media (min-width: 992px) {
          text-align: left;
          font-size: 48px;
          line-height: 50px;

          &-container {
            max-width: 600px;
            justify-content: left;
          }
        }
      }

      @media (min-width: 992px) {
        &-wrapper {
          min-height: 300px;
        }
      }
    }

    &--title {

      &-container {
        display: flex;
        justify-content: center;

        @media (min-width: 992px) {
          justify-content: left;
        }
      }
    }

    &--heading {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      color: #000;
      padding: 8px 12px;
      width: fit-content;
      margin: 0;
      transition: all 0.2s ease-in allow-discrete;
      text-align: center;
      
      &-container {
        display: flex;
        justify-content: center;
      }

      b {
        font-weight: 700;
      }

      &-container {
        max-width: 400px;
        flex-wrap: wrap;
        
        h2 {
          padding: 8px 4px;
        }
      }

      @media (min-width: 992px) {
        text-align: left;
        font-size: 96px;
        line-height: 83px;
        font-weight: 300;

        &-container {
          max-width: 600px;
          justify-content: left;
        }
      }
    }

    &--paragraph {
      color: #424957;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 20px 12px;
      max-width: 460px;
      text-align: center;

      @media (min-width: 992px) {
        font-size: 20px;
        text-align: left;
      }
    }

    &--white-bg {
      color: #000;
      background-color: hsla(0, 0%, 100%, .9);
    }

    &--black-bg {
      color: #fff;
      background-color: rgb(0 0 0 / 90%);

      &.static-word {
        height: 66px;
      }
    }

    &--accent {
      color: #00D5FF;
    }

    &--data-accent {
      color: #00e2da;
    }

    &--ia-accent {
      color: #ff774d;
    }

    &--purple-accent {
      color: #dbc3ff;
    }

    &--ctas {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
      min-height: 50px;
    }

    &--btn {
      margin-top: 20px;
      display: flex;
      align-items: center;
      background: #807C82;
      border-radius: 50px;

      .btn-md {
        border-radius: 50px;
        padding: 0 20px;
      }
    }

    &--btn-default {
      .btn-md {
        background: #00d5ff;
        color: #000;

        &:hover {
          background: #807C82;
          color: #fff;
        }
      }
    }

    &--logo {
      padding: 0 10px;

      .btn-md {
        display: block;
        text-align: left;
        height: auto;
      }

      img {
        width: auto;
        height: 15px;

        &.us-foods-logo {
          height: 25px;
          padding: 0 15px;
        }

        &.century-logo {
          //height: 30px;
          padding: 0 10px;
        }

        &.blueyonder-logo { 
          //height: 30px;
        }
      } 
    } 
  }

  .carousel-indicators {
    left: 0;
    bottom: 0;
    margin: 40px 0;
    justify-content: center;

    &--ctrl {
      display: flex;
      padding: 4px;
      background: #FFFFFF99;
      border-radius: 45px;
      gap: 8px;
      backdrop-filter: blur(3px);
      box-shadow: 3px 3px 30px 10px rgba(0,0,0,0.05);
    }

    &--btn {
      padding: 0 12px;
      cursor: pointer;
      width: auto;
      min-width: 25px;
      height: 35px;
      text-indent: 0;
      color: #000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      border-radius: 45px;
      background: transparent;
      border: 0;
      text-transform: capitalize;
      margin: 0;
      letter-spacing: 0;
      opacity: 1;

      &.active {
        background: #fff;
        transition: all 0.2s ease-in;
      }

      &:hover {
        transition: all 0.2s ease-in;
      }

    }

    &--icon {
      font-size: 18px;
    }

    @media (min-width: 768px) {
      left: 40px;
      justify-content: left;

      &--btn {
        font-size: 16px;
        padding: 4px 25px;
      }
    }

    @media (min-width: 1600px) {
      left: 80px;
    }
  }

  .carousel-item {
    min-height: $component-height--mobile;
    background: #000;

    &:first-child {
      background: #f1f0f8;
    }

    &--bg {
      min-height: $component-height--mobile;
      object-fit: contain;
      object-position: right bottom;
      position: absolute;
    }

    @media (min-width: 768px) {
      min-height: $component-height--desktop;

      &--bg {
        min-height: $component-height--desktop;
      }
    }
  }

  /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      filter: opacity(1);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      filter: opacity(1);
    }
  }

  .slide-top {
    -webkit-animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }


    /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */

  @-webkit-keyframes slide-rotate {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      filter: opacity(1);
    }
  }

  @keyframes slide-rotate {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      filter: opacity(0.1);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
      filter: opacity(1);
    }
  }

  .slide-rotate {
    -webkit-animation: slide-rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
  * Generated by Animista on 2024-10-29 10:57:41
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation scale-down-center
  * ----------------------------------------
  */
  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(1);
    }
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0.5);
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(1);
    }
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0.5);
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      filter: opacity(0);
    }
  }

  .scale-down-center {
    -webkit-animation: scale-down-center 0.4s ease-out both;
    animation: scale-down-center 0.4s ease-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2024-11-22 0:2:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  10% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.slide-left {
  -webkit-animation: slide-left 3s infinite ;
  animation: slide-left 3s infinite ;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-22 0:11:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.slide-right {
	-webkit-animation: slide-right 3s infinite ;
  animation: slide-right 3s infinite ;
}
</style>