import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "hero--component" }
const _hoisted_2 = { class: "hero--container" }
const _hoisted_3 = {
  id: "homePagehero",
  class: "carousel slide carousel-fade"
}
const _hoisted_4 = { class: "carousel-indicators" }
const _hoisted_5 = { class: "carousel-inner" }
const _hoisted_6 = { class: "carousel-item active" }
const _hoisted_7 = { class: "hero--background-container" }
const _hoisted_8 = {
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/11/hero-v5-bg.jpg",
  class: "hero--wistia-intro-image",
  alt: ""
}
const _hoisted_9 = {
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/11/hero-v3-bg-overlay-1-1.png",
  class: "hero--image-moving-overlay-left slide-left",
  alt: ""
}
const _hoisted_10 = {
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/11/hero-v3-bg-overlay-2.png",
  class: "hero--image-moving-overlay-rigth slide-right",
  alt: ""
}
const _hoisted_11 = {
  key: 0,
  class: "hero--mobile-video"
}
const _hoisted_12 = {
  key: 0,
  loading: "lazy",
  decoding: "async",
  src: "https://hakkoda.io/wp-content/uploads/2024/10/video_backup_mobile.jpg",
  class: "hero--video-intro-image",
  alt: ""
}
const _hoisted_13 = {
  key: 1,
  class: "hero--bg-video"
}
const _hoisted_14 = {
  key: 3,
  class: "hero--wistia-ctrl"
}
const _hoisted_15 = { class: "hero--section-container" }
const _hoisted_16 = { class: "hero--section" }
const _hoisted_17 = { class: "hero--animation hero--title" }
const _hoisted_18 = { class: "hero--animation-wrapper" }
const _hoisted_19 = { class: "hero--title-container" }
const _hoisted_20 = { class: "hero--title-container" }
const _hoisted_21 = {
  key: 0,
  class: "hero--animation hero--video-description"
}
const _hoisted_22 = { class: "hero--animation-wrapper" }
const _hoisted_23 = { class: "d-flex" }
const _hoisted_24 = { class: "d-flex" }
const _hoisted_25 = { class: "flex-column" }
const _hoisted_26 = {
  key: 0,
  class: "hero--animation-heading p-f hero--black-bg slide-top"
}
const _hoisted_27 = {
  key: 1,
  class: "hero--animation-heading p-f hero--black-bg hero--accent slide-top"
}
const _hoisted_28 = {
  key: 0,
  class: "hero--animation-heading hero--black-bg hero--accent slide-rotate animated"
}
const _hoisted_29 = {
  key: 1,
  class: "hero--animation-heading p-r hero--black-bg hero--accent slide-rotate animated"
}
const _hoisted_30 = {
  key: 2,
  class: "hero--animation-heading hero--black-bg hero--data-accent slide-rotate animated"
}
const _hoisted_31 = {
  key: 3,
  class: "hero--animation-heading p-r hero--black-bg hero--data-accent slide-rotate animated"
}
const _hoisted_32 = {
  key: 4,
  class: "hero--animation-heading hero--black-bg hero--ia-accent slide-rotate animated"
}
const _hoisted_33 = {
  key: 5,
  class: "hero--animation-heading p-r hero--black-bg hero--ia-accent slide-rotate animated"
}
const _hoisted_34 = {
  key: 6,
  class: "hero--animation-heading p-r hero--black-bg hero--purple-accent slide-rotate animated"
}
const _hoisted_35 = {
  key: 7,
  class: "hero--animation-heading p-f hero--black-bg hero--purple-accent slide-rotate"
}
const _hoisted_36 = {
  key: 0,
  class: "hero--ctas"
}
const _hoisted_37 = { class: "hero--section centered" }
const _hoisted_38 = { class: "hero--section-logo" }
const _hoisted_39 = { class: "hero--inline-video shadow-drop-center" }
const _hoisted_40 = { class: "carousel-item" }
const _hoisted_41 = { class: "hero--caption" }
const _hoisted_42 = { class: "hero--ctas" }
const _hoisted_43 = { class: "carousel-item" }
const _hoisted_44 = { class: "hero--caption" }
const _hoisted_45 = { class: "hero--ctas" }
const _hoisted_46 = { class: "carousel-item" }
const _hoisted_47 = { class: "hero--caption" }
const _hoisted_48 = { class: "hero--ctas" }

import { ref, onMounted, onUnmounted } from 'vue'
  import { useMediaQuery } from '@vueuse/core'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'
  import { useCustomerStore } from '../../../store/consumers'

  interface Props {
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'hero_v3',
  setup(__props: any) {

  const props = __props
  const customerStore = useCustomerStore()
  const isTablet = useMediaQuery('(min-width: 768px)')
  const isDesktop = useMediaQuery('(min-width: 992px)')
  const activeStory = ref<Story>(null)

  const second = ref(0);
  const isMute = ref(false);
  const isVideoEnd = ref(false);
  const isVideoPlaying = ref(false);
  const isBgVideo = ref(false);

  function onSecondChange(s) {
    second.value = s;
  }

  function onEnd() {
    window.jQuery('#homePagehero').carousel('next');
    isVideoEnd.value = true;
    isVideoPlaying.value = false;
  }

  function muteVideo(val: boolean) {
    isMute.value = val;
  }

  function onCustomerLogoClick(val) {
    customerStore.$patch({
      'activeStory': { 'clientName' : val }
    })
  }

  function playVideo() {
    isVideoPlaying.value = true;
  }

  function onCloseVideo() {
    isVideoPlaying.value = false;
  }

  onMounted( ()  => {
    const myCarousel = document.getElementById('homePagehero')
    myCarousel?.addEventListener('slide.bs.carousel', event => {

    })
  })

  onUnmounted( () => {
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", _hoisted_4, _cache[5] || (_cache[5] = [
          _createStaticVNode("<div class=\"carousel-indicators--ctrl\" data-v-20892030><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"0\" class=\"carousel-indicators--btn active\" aria-current=\"true\" aria-label=\"Slide 0\" data-v-20892030><img loading=\"lazy\" decoding=\"async\" src=\"https://hakkoda.io/wp-content/uploads/2024/09/House.svg\" class=\"carousel-indicators--btn-icon\" alt=\"\" data-v-20892030></button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"1\" class=\"carousel-indicators--btn\" aria-current=\"true\" aria-label=\"Migrate\" data-v-20892030>Migrate</button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"2\" class=\"carousel-indicators--btn\" aria-label=\"Modernize\" data-v-20892030>Modernize</button><button type=\"button\" data-bs-target=\"#homePagehero\" data-bs-slide-to=\"3\" class=\"carousel-indicators--btn\" aria-label=\"Monetize\" data-v-20892030>Monetize</button></div>", 1)
        ]), 512), [
          [_vShow, _unref(isTablet)]
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
                [_vShow, second.value <= 0]
              ]),
              _withDirectives(_createElementVNode("img", _hoisted_9, null, 512), [
                [_vShow, second.value <= 0.1]
              ]),
              _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
                [_vShow, second.value <= 0.1]
              ]),
              (!_unref(isDesktop) && isBgVideo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (isVideoEnd.value && false)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                      : _createCommentVNode("", true),
                    (isVideoPlaying.value)
                      ? (_openBlock(), _createBlock(WistiaVideoPlayer, {
                          key: 1,
                          "video-id": 'ka80rutrfd',
                          "auto-play": true,
                          mute: isMute.value,
                          replay: false,
                          "hide-controls": true,
                          "full-screen": true,
                          "play-video": isVideoPlaying.value,
                          onSecondchange: onSecondChange,
                          onEnd: onCloseVideo,
                          onCancelfullscreen: onCloseVideo
                        }, null, 8, ["mute", "play-video"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_unref(isDesktop) && isBgVideo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (isVideoPlaying.value)
                      ? (_openBlock(), _createBlock(WistiaVideoPlayer, {
                          key: 0,
                          "video-id": 'ka80rutrfd',
                          "is-bg-video": true,
                          "auto-play": true,
                          mute: isMute.value,
                          replay: true,
                          "hide-controls": true,
                          "play-video": isVideoPlaying.value,
                          onSecondchange: onSecondChange,
                          onPause: onCloseVideo,
                          onEnd: onEnd
                        }, null, 8, ["mute", "play-video"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (isVideoPlaying.value && _unref(isDesktop) && isBgVideo.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "hero--video-go-back-container",
                    onClick: onCloseVideo
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      class: "hero--video-go-back",
                      src: "https://hakkoda.io/wp-content/uploads/2022/04/Directional-Left.svg",
                      alt: ""
                    }, null, -1),
                    _createElementVNode("p", { class: "hero--video-go-back-text" }, "Go Back", -1)
                  ])))
                : _createCommentVNode("", true),
              (second.value > 0 && isBgVideo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    (!isMute.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (muteVideo(true)))
                        }, _cache[7] || (_cache[7] = [
                          _createElementVNode("i", { class: "fa-solid fa-volume-high" }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (isMute.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (muteVideo(false)))
                        }, _cache[8] || (_cache[8] = [
                          _createElementVNode("i", { class: "fa-solid fa-volume-xmark" }, null, -1)
                        ])))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("h2", {
                        class: _normalizeClass(["hero--heading p-f slide-top", {
                          'scale-down-center': false
                        }])
                      }, _cache[9] || (_cache[9] = [
                        _createTextVNode("Unleash "),
                        _createElementVNode("br", null, null, -1),
                        _createTextVNode(),
                        _createElementVNode("b", null, "Innovation.", -1)
                      ]))
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["hero--paragraph p-f slide-top", {
                          'scale-down-center': false
                        }])
                      }, "Helping companies harness cloud platforms and AI capabilities.")
                    ])
                  ])
                ], 512), [
                  [_vShow, second.value <= 0]
                ]),
                (second.value > 0 && _unref(isDesktop))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          (second.value >= 0.1 || isVideoEnd.value)
                            ? (_openBlock(), _createElementBlock("h2", {
                                key: 0,
                                class: _normalizeClass(["hero--animation-heading hero--white-bg p-l slide-top", {
                          'scale-down-center': second.value >= 28.7
                        }])
                              }, "We", 2))
                            : _createCommentVNode("", true),
                          (second.value >= 0.1 || isVideoEnd.value)
                            ? (_openBlock(), _createElementBlock("h2", {
                                key: 1,
                                class: _normalizeClass(["hero--animation-heading hero--white-bg p-r slide-top", {
                          'scale-down-center': second.value >= 28.7
                        }])
                              }, "Power", 2))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          (second.value >= 0.6 || isVideoEnd.value)
                            ? (_openBlock(), _createElementBlock("h2", {
                                key: 0,
                                class: _normalizeClass(["hero--animation-heading hero--white-bg p-l slide-top", {
                          'scale-down-center': second.value >= 28.7
                        }])
                              }, "Data", 2))
                            : _createCommentVNode("", true),
                          (second.value >= 0.9 || isVideoEnd.value)
                            ? (_openBlock(), _createElementBlock("h2", {
                                key: 1,
                                class: _normalizeClass(["hero--animation-heading hero--white-bg p-r slide-top", {
                          'scale-down-center': second.value >= 28.7
                        }])
                              }, "Innovation", 2))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          ((second.value >= 2.3 && second.value <= 4.1) || isVideoEnd.value)
                            ? (_openBlock(), _createElementBlock("h2", _hoisted_26, "So You Can Build The"))
                            : _createCommentVNode("", true),
                          ((second.value >= 2.4 && second.value <= 4.1) || isVideoEnd.value)
                            ? (_openBlock(), _createElementBlock("h2", _hoisted_27, "Next Big Thing"))
                            : _createCommentVNode("", true)
                        ]),
                        (second.value >= 4.2)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["hero--animation-heading-container d-flex slide-top", {
                        'scale-down-center': second.value >= 28.7
                      }])
                            }, [
                              _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "hero--animation-heading p-l hero--black-bg static-word" }, "With", -1)),
                              (second.value >= 4.3 && second.value <= 9.7)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_28, "scalable"))
                                : _createCommentVNode("", true),
                              (second.value >= 4.4 && second.value <= 9.7)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_29, "teams"))
                                : _createCommentVNode("", true),
                              (second.value >= 9.8 && second.value <= 16)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_30, "data"))
                                : _createCommentVNode("", true),
                              (second.value >= 9.9 && second.value <= 16)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_31, "analytics"))
                                : _createCommentVNode("", true),
                              (second.value >= 16.1 && second.value <= 22.1)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_32, "AI"))
                                : _createCommentVNode("", true),
                              (second.value >= 16.2 && second.value <= 22.1)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_33, "strategies"))
                                : _createCommentVNode("", true),
                              (second.value >= 22.2)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_34, "the modern"))
                                : _createCommentVNode("", true),
                              (second.value >= 22.3)
                                ? (_openBlock(), _createElementBlock("h2", _hoisted_35, "data stack"))
                                : _createCommentVNode("", true)
                            ], 2))
                          : _createCommentVNode("", true)
                      ]),
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                            (second.value >= 4.3 || isVideoEnd.value)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: _normalizeClass(["hero--btn slide-top", {
                        'scale-down-center': second.value >= 28.7
                      }])
                                }, _cache[11] || (_cache[11] = [
                                  _createElementVNode("a", {
                                    class: "btn-md btn-dark",
                                    href: "https://hakkoda.io/services/",
                                    target: "_self"
                                  }, "Learn More", -1)
                                ]), 2))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_37, [
                _withDirectives(_createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", {
                    class: "hero--video-logo-container",
                    onClick: playVideo
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("video", {
                      autoplay: "",
                      muted: "",
                      loop: "",
                      playsinline: "",
                      class: "hero--video-logo"
                    }, [
                      _createElementVNode("source", {
                        src: "https://hakkoda.io/wp-content/uploads/2024/11/Just_Hakkoda_logo_V004.mp4",
                        type: "video/mp4"
                      })
                    ], -1),
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      class: "hero--video-logo-play-icon",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/play-icon-black.svg",
                      alt: ""
                    }, null, -1)
                  ]))
                ], 512), [
                  [_vShow, !isVideoPlaying.value || !_unref(isDesktop)]
                ]),
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(["hero--inline-video-container", {
                    'is-mobile': !_unref(isDesktop)
                  }])
                }, [
                  _withDirectives(_createElementVNode("div", {
                    class: "hero--inline-video-overlay",
                    onClick: onCloseVideo
                  }, null, 512), [
                    [_vShow, !_unref(isDesktop)]
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _withDirectives(_createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      class: "hero--inline-video-close-icon",
                      src: "https://hakkoda.io/wp-content/uploads/2024/11/close-square.svg",
                      alt: "",
                      onClick: onCloseVideo
                    }, null, 512), [
                      [_vShow, isVideoPlaying.value]
                    ]),
                    _withDirectives(_createVNode(WistiaVideoPlayer, {
                      "video-id": 'ka80rutrfd',
                      "auto-play": true,
                      replay: true,
                      "play-video": isVideoPlaying.value,
                      "pause-video": !isVideoPlaying.value,
                      onPaused: onCloseVideo
                    }, null, 8, ["play-video", "pause-video"]), [
                      [_vShow, isVideoPlaying.value]
                    ])
                  ])
                ], 2), [
                  [_vShow, isVideoPlaying.value]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_40, [
            _cache[17] || (_cache[17] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-migrate-mobile-bg.jpg",
              class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
              alt: "Migrate"
            }, null, -1)),
            _cache[18] || (_cache[18] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/01/Migrate_V004.webp",
              class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
              alt: "Migrate"
            }, null, -1)),
            _createElementVNode("div", _hoisted_41, [
              _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Seamless Data Migration:", -1)),
              _cache[15] || (_cache[15] = _createElementVNode("h2", null, "Elevate Your Business to New Heights", -1)),
              _cache[16] || (_cache[16] = _createElementVNode("p", null, "Hakkoda’s Data Migration service is more than just a one-time transfer to the cloud; it's a strategic intervention that allows you to rethink your business from the ground up by accelerating integration, streamlining operations, and transforming your data into your most powerful asset.", -1)),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", {
                  class: "hero--btn hero--btn-default",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (onCustomerLogoClick('US Foods')))
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("div", { class: "hero--logo" }, [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/usfoods_logo.png",
                      class: "us-foods-logo",
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("span", { class: "btn-md" }, "See the Story", -1)
                ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _cache[23] || (_cache[23] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-modernize-mobile-bg.jpg",
              class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
              alt: "Modernize"
            }, null, -1)),
            _cache[24] || (_cache[24] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/01/Modernize_V004.webp",
              class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
              alt: "Modernize"
            }, null, -1)),
            _createElementVNode("div", _hoisted_44, [
              _cache[20] || (_cache[20] = _createElementVNode("h3", null, "Empower Your Business with", -1)),
              _cache[21] || (_cache[21] = _createElementVNode("h2", null, "Data Modernization Excellence", -1)),
              _cache[22] || (_cache[22] = _createElementVNode("p", null, "Hakkoda’s Data Modernization solutions are crafted to empower your business in the digital age. We specialize in building and implementing future-proofed data strategies that cater to the dynamic needs of your business, freeing up your resources to focus on the next big thing.", -1)),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", {
                  class: "hero--btn hero--btn-default",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (onCustomerLogoClick('Century')))
                }, _cache[19] || (_cache[19] = [
                  _createElementVNode("div", { class: "hero--logo" }, [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/century-1.png",
                      class: "century-logo",
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("span", { class: "btn-md" }, "See the story", -1)
                ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_46, [
            _cache[29] || (_cache[29] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/09/home-hero-monetize-mobile-bg.jpg",
              class: "carousel-item--bg d-block w-100 d-sm-block d-md-none",
              alt: "Monetize"
            }, null, -1)),
            _cache[30] || (_cache[30] = _createElementVNode("img", {
              src: "https://hakkoda.io/wp-content/uploads/2024/01/Monetize_V004.webp",
              class: "carousel-item--bg d-block w-100 d-sm-none d-md-block",
              alt: "Monetize"
            }, null, -1)),
            _createElementVNode("div", _hoisted_47, [
              _cache[26] || (_cache[26] = _createElementVNode("h3", null, "Unleash the Potential:", -1)),
              _cache[27] || (_cache[27] = _createElementVNode("h2", null, "Data Monetization for Intelligent Growth", -1)),
              _cache[28] || (_cache[28] = _createElementVNode("p", null, "In today's data-driven world, your information is more than just a collection of numbers - it's a goldmine of opportunities waiting to be explored. Hakkoda's Data Monetization services empower you to securely create, share, and monetize feature rich applications on the Snowflake Data Cloud.", -1)),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", {
                  class: "hero--btn hero--btn-default",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (onCustomerLogoClick('Blue Yonder')))
                }, _cache[25] || (_cache[25] = [
                  _createElementVNode("div", { class: "hero--logo" }, [
                    _createElementVNode("img", {
                      loading: "lazy",
                      decoding: "async",
                      src: "https://hakkoda.io/wp-content/uploads/2024/10/blueyonder-1.png",
                      class: "blueyonder-logo",
                      alt: ""
                    })
                  ], -1),
                  _createElementVNode("span", { class: "btn-md" }, "See the Story", -1)
                ]))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})