<template>
  <section class="image-slider--component" v-if="renderComponent">
    <!-- egjs-flicking -->
    <Flicking
      v-if="false"
      :plugins="plugins" 
      :options="{ 
        changeOnHold: true, moveType: 'strict', circular: true, horizontal: true, bound: true, align: 'prev', autoInit: true, panelsPerView: -1 }"
    >
      <div
        v-for="(src, index) in images" 
        :key="index" 
        @click="onClick(src)"
      >
        <img
          :class="{
              'active': !active?.logo || active?.logo == src
          }"
          :src="`${ src }`"
        ></div>
    </Flicking>
    <!-- swiperjs -->
    <div class="image-slider--container slider slick-slider">
      <div
        v-for="(src, index) in images" 
        :key="index" 
        class="slider-item"
      >
        <div class="image-slider--item">
            <div class="image-slider--logo"  >
              <img
                :class="{
                    'active': !active?.logo || active?.logo == src
                }"
                @click="onClick(src)"
                :src="`${ src }`"
              />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, watch, useTemplateRef, nextTick } from 'vue'
  import Story from '../../models/story.model'
  import Flicking from "@egjs/vue3-flicking";
  import { AutoPlay } from "@egjs/flicking-plugins";

  interface Props {
    stories?: Story[]
    active?: Story
  }

  const props = defineProps<Props>()
    const emit = defineEmits<{
    (e: 'onClick', value: Story): void
  }>()

  const active = ref<Story>(null)
  const images = ref(null)
  const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
  const renderComponent = ref(true);

  watch( () => props.stories,  (data: Story[], prev) => {
    if( data.length ) {
      images.value = data.map( e => e?.logo )
      forceRender()
    }
  })

  watch( () => props.active,  (data , prev) => {
    if (data != active.value) {
      active.value = data
    }
  })

  onMounted( ()  => {

    if( props.stories.length ) {
      images.value = props.stories.map( (e) => e?.logo || null)
      forceRender()
    }
  })

  const forceRender = async () => {
    renderComponent.value = false;
    await nextTick();
    renderComponent.value = true;
    await nextTick();

    window.jQuery('.image-slider--container').slick({
      mobileFirst: true,
      dots: false,
      infinite: true,
      variableWidth: true,
      autoplay: true,
      arrows: false,
      swipeToSlide: false,
      touchMove: true,
      useTransform: false,
      autoplaySpeed: 1,
      speed: 5000
    });
  }

  function onClick(src) {
    emit('onClick', src)
  }

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .image-slider {
    &--component {
      display: block;

      img {
        height: 45px;
        width: auto;
        margin: 0 30px;
        opacity: .3;

        &.active {
          opacity: 1;
        }
      }
    }

    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00D5FF;
      padding: 35px 0;
    }

    &--logo {
      margin: 0 15px;
    }


  }
</style>