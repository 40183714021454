import { version, createApp, defineCustomElement } from 'vue'
import { createPinia, setActivePinia } from 'pinia'

//Components

import CustomerStories from './libs/components/customer-stories/customer-stories.vue'
import WistiaVideoPlayer from './libs/components/wistia-video-player/wistia-video-player.vue'
import GatedVideoPlayer from './libs/components/wistia-video-player/gated-video-player.vue'
import ResourceCard from './libs/components/resource-card/resource-card.vue'
import HubspotForm from './libs/components/hubspot-form/hubspot-form.vue'

//Pages
import WebinarForm from './libs/pages/webinar-form.vue'

// Home
import HomePage from './libs/pages/home/home-page.vue'
import HomePageHero from './libs/pages/home/components/hero.vue'
import HomePageHeroV2 from './libs/pages/home/components/home-hero.vue'
import HeroV3 from './libs/pages/home/components/hero_v3.vue'
import HomePageStories from './libs/pages/home/components/stories.vue'
import BrandLogosSlider from './libs/pages/home/components/brands-logo-slider.vue'
import HomePageServices from './libs/pages/home/components/services.vue'
import HomePageIndustriesV1 from './libs/pages/home/components/industries_v1.vue'
import HomePageIndustriesV2 from './libs/pages/home/components/industries_v2.vue'
import HomePageIndustriesV3 from './libs/pages/home/components/industries_v3.vue'
import HomePageSolutions from './libs/pages/home/components/solutions.vue'
import HomeTimeTo from './libs/pages/home/components/time-to.vue'

// ai-accelerated-migrations/
import IaDifferentiatorsTabs from './libs/pages/ai-accelerated-migrations/differentiators-tabs.vue'

// STORE
const pinia = createPinia()
setActivePinia(pinia)
// console.log(version)

/* 
* Components 
*/

export const wistiaVideoPlayer = defineCustomElement(WistiaVideoPlayer, { shadowRoot: false })
customElements.define('wistia-video-player', wistiaVideoPlayer)

//live-webinar-the-data-governance-playbook-for-ai-in-healthcare/
export const gatedVideoPlayer = defineCustomElement(GatedVideoPlayer, { shadowRoot: false })
customElements.define('gated-video-player', gatedVideoPlayer)

export const hubspotForm = defineCustomElement(HubspotForm, { shadowRoot: false })
customElements.define('hubspot-form', hubspotForm)

export const webinarForm = defineCustomElement(WebinarForm, { shadowRoot: false })
customElements.define('webinar-form', webinarForm)

export const customerStories = defineCustomElement(CustomerStories, { shadowRoot: false })
customElements.define('customer-stories', customerStories)

export const resourceCard = defineCustomElement(ResourceCard, { shadowRoot: false })
customElements.define('resource-card', resourceCard)

//Home
export const homePage = defineCustomElement(HomePage, { shadowRoot: false })
customElements.define('home-page', homePage)

export const homePageHero = defineCustomElement(HomePageHero, { shadowRoot: false })
customElements.define('home-page-hero', homePageHero)

export const homePageHeroV2 = defineCustomElement(HomePageHeroV2, { shadowRoot: false })
customElements.define('home-page-hero-v2', homePageHeroV2)

export const heroV3 = defineCustomElement(HeroV3, { shadowRoot: false })
customElements.define('hero-v3', heroV3)

export const brandLogosSlider = defineCustomElement(BrandLogosSlider, { shadowRoot: false })
customElements.define('brands-logo-slider', brandLogosSlider)

export const homePageStories = defineCustomElement(HomePageStories, { shadowRoot: false })
customElements.define('home-page-stories', homePageStories)

export const homePageServices = defineCustomElement(HomePageServices, { shadowRoot: false })
customElements.define('home-page-services', homePageServices)

export const homePageIndustriesV1 = defineCustomElement(HomePageIndustriesV1, { shadowRoot: false })
customElements.define('home-page-industries-v1', homePageIndustriesV1)

export const homePageIndustriesV2 = defineCustomElement(HomePageIndustriesV2, { shadowRoot: false })
customElements.define('home-page-industries-v2', homePageIndustriesV2)

export const homePageIndustriesV3 = defineCustomElement(HomePageIndustriesV3, { shadowRoot: false })
customElements.define('home-page-industries-v3', homePageIndustriesV3)

export const homePageAccelarators = defineCustomElement(HomePageSolutions, { shadowRoot: false })
customElements.define('home-page-solutions', homePageAccelarators)

export const homeTimeTo = defineCustomElement(HomeTimeTo, { shadowRoot: false })
customElements.define('home-time-to', homeTimeTo)
// end Home

export const differentiatorsTabs = defineCustomElement(IaDifferentiatorsTabs, { shadowRoot: false })
customElements.define('ia-differentiators-tabs', differentiatorsTabs)
