import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hubspot--form" }

import { ref, onMounted, onUnmounted, reactive, watch } from 'vue'
  import { useElementBounding} from '@vueuse/core'
  import _ from 'lodash';
  import { FormInfo, useFormStore } from '../../store/forms'
  import { loadScript } from  '../../tools/index'

  enum themes {
    IA = 'theme-ia'
  }

  interface Props {
    portalId: String,
    formId: String,
    title?: String,
    disclaimer?: String,
    isFixedAfterView?: Boolean,
    theme?: themes
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'hubspot-form',
  props: {
    portalId: {},
    formId: {},
    title: {},
    disclaimer: {},
    isFixedAfterView: {},
    theme: {}
  },
  setup(__props: any) {

  const props = __props
  const store = useFormStore()
  const inicialRef = ref(null)
 
  const inicialRect = reactive(useElementBounding(inicialRef))
  const isFixed = ref(false)

  const loadHubspotForm = ({ portalId, formId}: Props) => {
    loadScript('https://js.hsforms.net/forms/embed/v2.js', () => {
      window.hbspt.forms.create({
            target: '.hubspot--form-container',
            region: 'na1',
            portalId: portalId,
            formId: formId,
            css: ''
        })
    })
  }

  const handleScroll = ( event ) => {
    console.log('rect', inicialRect.top )

    if (inicialRect.top < 95 && !isFixed.value ) {
      isFixed.value = true
    }
    if (inicialRect.top > 95 && isFixed.value ) {
      isFixed.value = false
    }
  }

  onMounted( ()  => {
    loadHubspotForm(props)
    if ( props.isFixedAfterView ) {
      window?.addEventListener('scroll', _.throttle(handleScroll, 200))
    }

    window?.addEventListener('message',  ( event ) => {
      if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        const submitedData = event.data?.data?.submissionValues || null
        const formData: FormInfo = {
          formId: event.data?.id,
          submitedEmail: submitedData?.email || ''
        }
        store.setSubmited(formData)
        store.$patch({
          submitedForm: formData
        })
      }
    })
  })

  onUnmounted( () => {
    if ( props.isFixedAfterView ) {
      window.removeEventListener('scroll', handleScroll);
    }
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "inicialRef",
      ref: inicialRef
    }, null, 512),
    _createElementVNode("div", {
      ref: "component",
      class: _normalizeClass(["hubspot--component", {
      'fixed-md': isFixed.value
    }])
    }, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["hubspot--title", {
        'theme-ai': _ctx.theme == themes.IA
      }])
          }, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hubspot--form-container" }, null, -1)),
        (_ctx.disclaimer)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["hubspot--disclaimer", {
          'theme-ai': _ctx.theme == themes.IA
        }])
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.disclaimer), 1)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 64))
}
}

})