import { inject, ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import Story from '../models/story.model'

export const useCustomerStore = defineStore('customerStore', () => {
  const customers = ref<Story[] | []>([])  
  const activeStory = ref<Story>(null)

  const getActiveStory = computed(() => activeStory )

  function setActiveStory( active: Story ) {
    activeStory.value = active
    console.log('setActiveStory', activeStory.value)
  }

  return { getActiveStory, setActiveStory }
})

