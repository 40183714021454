<template>
  <div ref="component" class="home-industries--component-container">
    <div class="home-industries--component">
      <div class="home-industries--sector-container">
      <div class="home-industries--subtitle"><p>Industries</p></div>
      <div class="home-industries--title">
        <h2>We’ve Been in Your Chair.</h2>
        <h2>Now, We’re in Your Corner.</h2>
      </div>
      <div class="home-industries--description">
        <p class="home-industries--description-paragraph">Modern data solutions aren’t built on technical expertise alone. Our industry teams understand your business and come to the table with decades of experience driving practical results in complex and highly regulated industries.</p>
      </div>
      <div class="home-industries--list">

        <div class="home-industries--panel">
          <div class="home-industries--sector"
            v-for="(sectorVal, index) in conf"
            :class="{
              'active': activeIndustry?.name == sectorVal?.name
            }"
            :key="index"
            @mouseover="setActiveSector(sectorVal)"
          >
            <div class="home-industries--sector-headline">
              <a :href="`${ sectorVal.target }`">
                <img
                  class="svg-white"
                  v-show="activeIndustry?.name != sectorVal?.name"
                  :src="`${ sectorVal.logo }`"
                  :key="`${ index }`"
                >
                <img
                  class="svg-white"
                  v-show="activeIndustry?.name == sectorVal?.name"
                  src="https://hakkoda.io/wp-content/uploads/2024/11/solution-rigth-arrow.svg"
                  :key="`${ index }`"
                >
                <h3
                  class="home-industries--sector-name"
                  :class="{
                    'active': activeIndustry?.name == sectorVal?.name
                  }"
                >{{ sectorVal?.name }}</h3>
              </a>
            </div>
          </div>
        </div>

      </div>
      </div>
      <div v-if="isLargeScreen" class="home-industries--video-container">
        <WistiaVideoPlayer
          :video-id="'6wffy792pl'" 
          :auto-play="true" 
          :mute="true" 
          :replay="true" 
          :hide-controls="true"
          preload:="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted, onUnmounted, computed } from 'vue'
  import { useMediaQuery } from '@vueuse/core'
  import Industry from '../../../models/industry.model'
  import WistiaVideoPlayer from '../../../components/wistia-video-player/wistia-video-player.vue'

  interface Props {
  }

  const props = defineProps<Props>()
    const isLargeScreen = useMediaQuery('(min-width: 992px)')
  const conf = ref<Industry[]>([
    {
      name: 'Healthcare & Life Sciences',
      description: 'Quality healthcare requires quality data, serviced by expert teams of data scientists, engineers, and technologists with hands-on experience at the world’s largest healthcare organizations.',
      target: 'https://hakkoda.io/healthcare/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/healthcare.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/HLS_image.webp'
    },
    {
      name: 'Financial Services',
      description: 'Our experienced financial services technologists can help you maximize your data to streamline operations, improve your customer experience, and tap into new revenue sources.',
      target: 'https://hakkoda.io/financial-services/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/financial.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/FSI_image.webp'
    },
    {
      name: 'Public Sector',
      description: 'Securely deploy and assetize your organization’s data with tools & capabilities embraced by the commercial sector, backed by our teams of public sector data leaders.',
      target: 'https://hakkoda.io/public-sector/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/public-sector.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/01/PS_image_v3.webp'
    },
    {
      name: 'Supply Chain & Logistics',
      description: 'Effective supply chain management relies on high-quality data to streamline operations, reduce costs, and improve responsiveness to supply and demand volatility. The right blend of data tooling and talent is crucial for ensuring data accuracy, managing risk, and delivering actionable insights that drive efficiency and agility in complex logistics networks.',
      target: 'https://hakkoda.io/supply-chain-logistics/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/supply-chain.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/09/logistics.jpg'
    },
    {
      name: 'Retail & CPG',
      description: 'Hone your sales and marketing strategies with powerful CDP platforms that unify data across the customer lifecycle with ERP analytics and other critical sources. Empower end-to-end enterprise analytics to enrich demand forecasting, optimize inventory management, deliver bespoke ad campaigns, and respond to market changes faster than the competition.',
      target: 'https://hakkoda.io/retail-cpg/',
      logo: 'https://hakkoda.io/wp-content/uploads/2024/11/retail.svg',
      poster: 'https://hakkoda.io/wp-content/uploads/2024/10/Retail-CPG-2-1024x1024.jpg'
    }
  ])
  const activeIndustry = ref<Industry>(conf.value[0])

  function setActiveSector(val: Industry ){
    activeIndustry.value = val
  }

  function onSecondChange(s) {
    if(s >= 0 && s <= 2.5 && activeIndustry.value != conf.value[0]) {
      activeIndustry.value = conf.value[0]
    }
    if(s >= 2.6 && s <= 5.5 && activeIndustry.value != conf.value[1]) {
      activeIndustry.value = conf.value[1]
    }
    if(s >= 5.6 && s <= 7.5 && activeIndustry.value != conf.value[2]) {
      activeIndustry.value = conf.value[2]
    }
    if(s >= 7.6 && s <= 10.5 && activeIndustry.value != conf.value[3]) {
      activeIndustry.value = conf.value[3]
    }
    if(s >= 10.6 && s <= 14.5 && activeIndustry.value != conf.value[4]) {
      activeIndustry.value = conf.value[4]
    }
  }

  function onLogoClick(src) {
    const story: Story = stories.value.find((e) => e.logo == src)
    onActive(story)
  }

  onMounted( ()  => {
  })

  onUnmounted( () => {
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .home-industries {

    &--component {
      display: flex;
      width: 100%;
      flex-direction: column;
      max-width: 1440px;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        padding: 20px;
      }

      @media (min-width: 992px) {
        flex-direction: row;

        &-container {
          padding: 20px 40px;
        }
      }
    }

    &--title {
      padding: 10px 0 20px;

      h2 {
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
      }
    }

    &--subtitle {
      p {
        color: #21B0E2;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }
    }

    &--description {
      &-paragraph {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
      }
    }

    &--panel {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &--list {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0;
    }

    &--sector {
      display: flex;
      min-width: 300px;

      @media (min-width: 991px) {
        
      }

      &-name {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; 
        margin: 0;

        &.active {
          text-decoration: underline;
          text-underline-offset: 8px;
        }
      }

      &-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @media (min-width: 991px) {
          width: 50%;
        }
      }

      &-headline {
        display: flex;

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }

        img {
          width: 24px;
        }
      }
    }

    &--video-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 20px;

      @media (min-width: 991px) {
        width: 50%;
      }
    }

    &--picture {
      display: flex;
      flex-grow: 1;
      object-fit: cover;

      &-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100vw;
        height: auto;

        @media (min-width: 991px) {
          width: 20vw;
          height: 400px;
        }
      }
    }

    &--animation {

      &-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100vw;

        @media (min-width: 991px) {
          width: 30vw;
        }

        .adobe-animation--component {
          display: none;
         
          @media (min-width: 991px) {
            display: block;
            width: 30vw !important;
            height: 400px !important;
          }
        }
      }
    }
  }
</style>